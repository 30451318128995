const likeBulbClassName = "fa fa-lightbulb fa-1x column";

export const canVoteForComponent = (arrBulbs, author, currentPaymail) => {
    let returnVal = true;
    alert(arrBulbs.length);
    for(let i = 0; i < arrBulbs.length; i++){
        if (arrBulbs[i].className === likeBulbClassName){
            returnVal = false;
        }
    }

    if(author === currentPaymail){
        returnVal = false;
    }
    
    return returnVal;
}