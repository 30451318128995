import axios from 'axios';
const config = require('../../config/config');

const getVendorWalletPaymails = async(userId) => {
    let snapshot = await axios.get(`${config.url.userService}/vendor/wallet/paymail/${userId}`);
    
    return snapshot.data;    
}

const getVendorWalletUserId = async(paymail) => {
    let snapshot = await axios.get(`${config.url.userService}/vendor/wallet/userId/${paymail}`);
    
    return snapshot.data;    
}

const getsubmitterPaymailAndWalletAddress = async(paymail) => {
    let snapshot = await axios.get(`${config.url.userService}/${paymail}/paymail`);
    
    return snapshot.data;    
}

const getTopWordBarons = async() => {
    let snapshot = await axios.get(`${config.url.userService}/wordbarons/top/10`);
    
    return snapshot.data;    
}


const getEmailValidation = async(email) => {
    let snapshot = await axios.get(`https://emailverification.whoisxmlapi.com/api/v2?apiKey=at_g817QLROC3haNnvEghc6w7JdTcUw5&emailAddress=${email}`);

    return snapshot.data;
}

const createMuggle = async(email) => {
    let snapshot = await axios.post(`${config.url.userService}/muggle/${email}`,{});

    return snapshot.data;
}

const postDiscountCode = (discountCode) => {
    let snapshot = axios.post(`${config.url.userService}/muggle/promotion/discountCode/${discountCode}`,{})
        .then( (resp) => {
            return snapshot.data;
        })
        .catch(err => {
            return err.message;
        })
}

const postDiscountCodeEmail = async(discountCode, email) => {
    try{
        let snapshot = await axios.post(`${config.url.userService}/muggle/promotion/discountCode`,
            {
                discountCode: discountCode,
                email: email
            });
        
        return snapshot.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

const putDiscountCodeEmailExpire = (discountCode, email) => {
    let snapshot = axios.put(`${config.url.userService}/muggle/promotion/discountCode/expire `,
        {
            discountCode: discountCode,
            email: email
        })
        .then( (resp) => {
            return true;
        })
        .catch(err => {
            return false;
        })

    return snapshot.data;
}

const postSupportTicket = async(issueType, paymail, message, email) => {
    let snapshot = await axios.post(`${config.url.userService}/support/ticket`,
        new URLSearchParams({    
            issueType: issueType,
            paymail: paymail,
            message: message,
            email: email
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
        }
    ));

    return snapshot.data.ticketId
}

const postAnalyticsPage = (paymail, page, userAgent, userAgentData) => {
    let snapshot = axios.post(`${config.url.userService}/analytics/page `,
        {
            page: page,
            paymail: paymail,
            userAgent: userAgent,
            userAgentData: userAgentData
        })
        .then( (resp) => {
            return true;
        })
        .catch(err => {
            return false;
        })

    return snapshot.data;
}

export 
    {
        getVendorWalletPaymails, getVendorWalletUserId, 
        getsubmitterPaymailAndWalletAddress, getEmailValidation, 
        createMuggle, getTopWordBarons, postDiscountCode,
        postDiscountCodeEmail, putDiscountCodeEmailExpire,
        postSupportTicket, postAnalyticsPage
    }