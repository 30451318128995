class HandcashInsufficientFunds extends Error {
    constructor() {
        let message = `
            Insufficient funds. Either you don't have enough bsv in your wallet
            or your Handcash daily spend limit is too low.`
        super(message); 
        this.name = "HandcashInsufficientFunds"; 
    }
}

class HandcashInvalidMaxBid extends Error {
    constructor() {
        let message = `
            You have entered an invalid max bid. A new max bid amount must be
            greater than the current max bid.`
        super(message); 
        this.name = "HandcashInvalidMaxBid"; 
    }
}

export {HandcashInsufficientFunds, HandcashInvalidMaxBid}