const axios = require("axios");
const {getBSVexchangeRate} = require('../../mods/slictionary-bsv-mod');
const config = require("../../config/config");

const reimburseBidder = async(toPaymail, usdAmount, word) => {
    try{
        toPaymail = toPaymail.length === 0 ? "SLictionary@handcash.io" : toPaymail;
        let tokenServiceData = await axios.get(`${config.url.tokenService}/stas/contract/issue/fundingPrivateKey`);
        let privateKey = tokenServiceData.data.privateKey;
        let bsvExchangeRate = await getBSVexchangeRate();
        let satAmount = usdAmount / bsvExchangeRate * config.bsv.satoshisPerBsv; 
        
        satAmount = Math.round(satAmount);
        
        let jsonAddress = await axios.get(`https://api.polynym.io/getAddress/${toPaymail}`);
        let toAddress = jsonAddress["data"]["address"];

        let opReturn = `${toPaymail} - Reimburse SLebrity Bid for ${word.toUpperCase()} - utf8 SLic`;

        let jsonPayment = {
            "fundingPrivateKey" : privateKey,
            "toAddress": toAddress,
            "toPaymail": toPaymail,
            "paymentType": "bid-reimbursement",
            "satAmount": satAmount,
            "opReturn": opReturn,
            "word": word
        }

        let utxo = axios.post(`${config.url.bsvService}/payment/send`,jsonPayment)
            .then(doc => {
                return true;
            })
            .catch(err => {
                console.log(JSON.stringify(err));
                return false;
            });

    } catch (error) {
      console.log(JSON.stringify(error));  
    }
}

export {reimburseBidder}