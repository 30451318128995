/* eslint-disable no-unused-vars */
import React from 'react';
import BaseComponent from './Common/BaseComponent';
import '../components/mySLictionary.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TokenSlider from './Common/TokenSlider';
import Slideshow from './Common/StatsSlideshow';
//import {Spinner} from 'spin.js';
import 'spin.js/spin.css';
//import {getVoteEarnings, getVoteCount} from '../services/vote/VoteService';
import {getWordsByUser} from '../services/word/WordService';
import Wallet from "../components/Common/Wallet";

const config = require("../config/config");
const axios = require("axios");

//var voteCount;
//var voteEarnings;
var wordAnnualEarnings;

var opts = {
    lines: 13,                              // The number of lines to draw
    length: 38,                             // The length of each line
    width: 17,                              // The line thickness
    radius: 45,                             // The radius of the inner circle
    scale: 1,                               // Scales overall size of the spinner
    corners: 1,                             // Corner roundness (0..1)
    speed: 1,                               // Rounds per second
    rotate: 0,                              // The rotation offset
    animation: 'spinner-line-fade-quick',   // The CSS animation name for the lines
    direction: 1,                           // 1: clockwise, -1: counterclockwise
    color: '#ffffff',                       // CSS color or array of colors
    fadeColor: 'transparent',               // CSS color or array of colors
    top: '50%',                             // Top position relative to parent
    left: '50%',                            // Left position relative to parent
    shadow: '0 0 1px transparent',          // Box-shadow for the lines
    zIndex: 2000000000,                     // The z-index (defaults to 2e9)
    className: 'spinner',                   // The CSS class to assign to the spinner
    position: 'absolute',                   // Element positioning
  };
  

class mySLictionary extends BaseComponent {
    

    constructor(props) {
        super(props);
        
        this.state = {
            words: [],
            searchResults: [],
            wordSmiths: [],
            show: false,
            tokens: [],
            wordsByUser: []
        }

        this.cool = this.cool.bind(this);
        this.definitionOnClick = this.definitionOnClick.bind(this);

    }

    cool = async() => {
        let user = JSON.parse(localStorage.getItem("user"));

        const queryGetWordsByUser = getWordsByUser(user.vendorWalletsPaymailString.split(","))
            .then(resp => {
                //text = resp;
                let wordsByUser;
                if (resp.length === 0) {
                    wordsByUser = wordsByUser.concat("You have not defined any words.")
                } else {
                    wordsByUser = resp;
                }
        
                this.setState({words:wordsByUser});
            });

        }


/* #region Page Lifecycle */
    async componentWillMount(){
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
        document.body.style.backgroundAttachment = "fixed";
    
        wordAnnualEarnings = await axios.get(`${config.url.voteService}/token/word-annual-earnings-by-token/${localStorage.getItem("walletAddress")}`); 

        this.cool();
    }

    async componentDidMount(word) {
        
    } 
/* #endregion Page Lifecycle */
    

/* #region Event Handlers */

  definitionOnClick = (e) =>{
    this.props.history.push({"pathname": e.target.getAttribute("href")});
    e.preventDefault();
 
}

  handleShow = () => {
    this.setState({show:!this.state.show});
    return false;
  }

  handleClose = () => {
    this.setState({show:!this.state.show});
  }


   
    receiveClick(event){
        let walletAddress = localStorage.getItem("walletAddress");
        navigator.clipboard.writeText(walletAddress);

        alert(`Your wallet address ${walletAddress} has been copied to the clipboard`);
    }

/* #endregion Event Handlers */


/* #region Callback Functions */

callbackFunction = (childData) => {
  
}

/* #endregion */


/* #region Markup */

    render() { 
      
      return (
        <div className="content" style={{color:"white", textAlign:"left"}}>
        <Container style={{"width":"100%", backgroundColor: "transparent"}}>
            <Row>
                <Col style={{border:"2px solid white",borderRadius: "10px",height:"200px", margin:"auto"}}>
                    <div style={{fontFamily:"Cambria", fontSize: "20pt"}}>Stats</div>
                    <div   id="smithedWords">
                        <Slideshow />
                    </div>
                </Col>
                <Col style={{border:"2px solid white",borderRadius: "10px",height:"200px",margin:"10px"}}>

                {<Wallet address={localStorage.getItem("walletAddress")} spinnerOpts={opts}/>}

                </Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row>
                                                                
            <Col>                                                    
        
            <Tabs>
                <TabList>
                    <Tab> MY SMITHED DEFINITIONS </Tab>
                    <Tab> MY OWNED <b>SLICDEF</b> TOKENS </Tab>
                    {/* <Tab> Profile </Tab> */}
                </TabList>
                <TabPanel>
                <h4 style={{color: "white"}}>Words you have defined:</h4>
                <Container style={{width:1000}}>

                        <p  style={{marginLeft:"60px"}}>
                        {/* <ul> */}
                            {this.state.words.map((word,i) =>
                                <li key={i}><a href={"/definitions/" + word + "/@"} onClick={this.definitionOnClick}>{word}</a></li>
                            )}
                        {/* </ul> */}
                        
                    </p>
                    </Container>
                </TabPanel>
                <TabPanel>
                    <h3 style={{color: "#fafad2"}}>NFTs you own:</h3>
                    <Container style={{width:1000}} id="container">
                        <Row>
                            <Col>
                                <div id="demo" className="carousel slide" data-ride="carousel"
                                    style={{marginTop: "-100px"}}>
                                    {/* <div style={{marginTop:"0px"}}> */}
                                    {<TokenSlider parentCallback = {this.callbackFunction} word="bitcoin" wordAnnualEarnings={wordAnnualEarnings}/>}
                                    {/* </div> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </TabPanel>
            </Tabs>
        </Col>
        </Row>
        </Container>
          <Container style={{width:1000}}>
            <Row>
                <Col className="col col-12">
                    
                </Col>
            </Row>
          </Container>  
          <Modal show={this.state.show} onHide={this.handleClose} >
                <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                    <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Send BSV</Modal.Title>
                </Modal.Header> 
                <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modalTwetch" style={{color:"white",backgroundColor:"#202020"}}>
                    
                        <div>
                            
                            <div>
                            <form>
                                <div><span><input type="text" id="txtToAddress" required maxlength="100" style={{borderRadius: "0px",width:"100%", height:"50px", padding:"5px"}} placeholder="Enter recipient address"></input></span></div>
                                <div><span>&nbsp;</span></div>
                                <div><span><input type="text" id="txtUSDamount" required pattern="[0-9\.]+" maxlength="100" style={{borderRadius: "0px",width:"100%",height:"50px", padding:"5px"}} placeholder="Enter amount to send in USD"></input></span></div>
                                <div><span>&nbsp;</span></div>
                                <div><span>
                                    <button id="btnSend" type="button" onClick={this.sendClick} className="btn btn-primary" 
                                        style={{width:"100%",height:"50px", backgroundColor:"black", 
                                        borderColor:"white"}}>
                                        <i hidden id="iSendSpinner" className="fa fa-spinner fa-spin"></i>
                                        &nbsp;Send
                                    </button>
                                    </span>
                                </div>
                            </form>
                            </div>

                        </div>

                    
                    </div>

                </Modal.Body>
        </Modal>

        </div>
      );
    }

    /* #endregion Markup */
  
}

export default mySLictionary;