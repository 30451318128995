import React from 'react';
import{useState, useEffect, useRef} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import audioImage from '../assets/audio-blue.png';
// import LikeBulb from "./LikeBulb";
import "../components/Common/SimpleSlider.css"
import { decodeURL } from '../mods/slictionary-util-mod';
import DefinitionPanel from './DefinitionPanel';

const config = require("../config/config");

const audioId = "audio_play_0";

const Definitions2 = () => {
    const [items, setItems] = useState([]);
    const sliderRef = useRef();
    let arr = window.location.pathname.split("/");
    const word = decodeURL(arr[2]); 

    const getSliderSettings = () => {
        return {
            arrows: true,
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "300px",
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            responsive: [
                {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    dots: true
                }
                },
                {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
                },
                {
                breakpoint: 300,
                settings: {
                    slidesToShow: 1
                }
                }
            ]
        }};
    

    // const createAudioPlayer = () => {
    //     try{
    //         var audioPlayer = document.createElement("AUDIO");
    //         let parentContainer = document.getElementById("parentContainer")
    //         audioPlayer.id = "audio_play_0";
    //         audioPlayer.setAttribute("controls", "controls");
    //         audioPlayer.setAttribute("preload","none");
    //         audioPlayer.style.display="none";
    //         let source= document.createElement('source');
    //         if(audioPlayer.canPlayType('audio/mpeg;')){
    //         source.type = "audio/mp3";
    //         } else {
    //         source.type = "audio/mp4";
    //         }
    //         source.src= "";
    //         audioPlayer.appendChild(source);
    //         parentContainer.appendChild(audioPlayer);      
    //     } catch (error) {
    //         alert(error.toString());
    //         console.log(error.toString());
    //     }
    // }

    useEffect(() => {
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";

        fetch(`${config.url.wordService}/definitions/${word}`)
        .then(data => data.json())
        .then(results => {
            setItems(results)
        })
    }, []);

    // const onAudioClick = (e) => {
    //     if(!document.getElementById("audio_play_0")){
    //         createAudioPlayer();
    //     }
    //     let audioPlayer = document.getElementById("audio_play_0");
    //     audioPlayer.src = e.target.id;
    //     audioPlayer.play();
    // }

    const definitions = items.map(item => (
        <DefinitionPanel item={item} word={word}/>
        // <Container id="parentContainer" className="text-center" key={item.definitionId}>
        //     <Row>
        //         <Col>
        //         <div className="text-center">
        //             <div className="audioPanel">
                        
        //                 <img 
        //                     id={item.audioUrl}  
        //                     alt=""
        //                     className="audioImage liveimage"
        //                     src={audioImage}
        //                     onClick={onAudioClick}
        //                     style={{width:"30px",display:"none", marginTop:"-12px", display: item.audioUrl.length === 0 ? "none" : ""}} 
        //                 />
        //             </div>
        //         </div>
        //             <div>
        //                 <img    
        //                     className="imagePanel"
        //                     src={item.imageUrl}
        //                     // onDoubleClick=""
        //                     alt=""
        //                 />
        //                 <LikeBulb 
        //                     componentId={item.imageId}
        //                     author={item.author}
        //                     className="fa fa-lightbulb fa-1x column"
        //                     title="Vote"  
        //                     word={word}
        //                     arrBulbs={document.getElementsByClassName('fa fa-lightbulb fa-1x column')}
        //                     visible={item.imageUrl.length === 0 ? false : true}
        //                 />
        //             </div>
        //             <div>&nbsp;</div>
        //             <div className="definitionPanel">
        //                 <div>
        //                     <span className="" id="sp0" >
        //                         <span id="partsOfSpeech"><em>{item.prevArrow}</em></span>
        //                         <p>
        //                             <span style={{fontStyle: "italic"}}>[noun]</span><br/>
        //                             {item.definitionText}
        //                         </p>
        //                         <LikeBulb 
        //                             componentId={item.definitionTextId}
        //                             author={item.author}
        //                             className="fa fa-lightbulb fa-1x column"
        //                             title="Vote"  
        //                             word={word}
        //                             arrBulbs={document.getElementsByClassName('fa fa-lightbulb fa-1x column')}
        //                             visible={true}
        //                         />
        //                         <div>&nbsp;</div>
        //                         <div style={{fontSize:"12pt", fontStyle: "italic"}}>
        //                             Used in a sentence:
        //                         </div>
        //                         <p>
        //                             {item.useage}
        //                             {/* {isUsageLikeBulbVisible ? UsageLikeBulb.getJSX(this.bulbClick) : (<></>)} */}
        //                         </p>
        //                         <div>
        //                             <div>&nbsp;</div>
        //                             <i>Etymology:</i>
        //                         </div>
        //                         <div 
        //                             style={{fontSize:"12pt",color:"black"}}>
        //                                 <i>{item.etymology === undefined ? "" : item.etymology}</i>
        //                                 {/* {isEtymologyLikeBulbVisible ? EtymologyLikeBulb.getJSX(this.bulbClick) : (<></>)} */}
        //                         </div>
        //                         {<div>&nbsp;</div>}
        //                         <div>
        //                             <span id={"ws-" + item.definitionId} className="author" style={{position:"relative",left:"100px"}}>-{item.authorPaymail}</span>
        //                         </div>
        //                         <div>
        //                             <span hidden id={"wbId-" + item.definitionId}>{item.wordBountyId !== undefined ? item.wordBountyId : "None"}</span>
        //                         </div>
        //                         <div>
        //                             <span hidden id={"key-" + item.definitionId}>{item.definitionId}</span>
        //                         </div>
        //                         <div>
        //                             <span hidden id={"hidWordSmith-" + item.definitionId}>{item.authorPaymail}</span>
        //                         </div>
        //                     </span>
        //                 </div>
        //         </div>
        //     </Col>
        //     </Row>
        // </Container>
    ))

    return(
            <div className="text-center">
                <div style={{fontSize: "36pt"}}>{word}</div>
                <div>&nbsp;</div>
                <Slider id="definitionsSlider" ref={sliderRef} {...getSliderSettings()}  
                    className="carousel slide" data-ride="carousel"
                >
                    {definitions}
                </Slider>
            </div>
    )
}

export default Definitions2;
