import * as firebase from 'firebase';
import firestore from 'firebase/firestore'
const config = {
    apiKey: "AIzaSyCbwZ6aHO1X0mJPpic4zFRqamvRhE1-ruQ",
    authDomain: "slictionary-fc2a0.firebaseapp.com",
    databaseURL: "https://slictionary-fc2a0.firebaseio.com",
    projectId: "slictionary-fc2a0",
    storageBucket: "slictionary-fc2a0.appspot.com",
    messagingSenderId: "165587557123",
    appId: "1:165587557123:web:4c5e9964ca57a35899a736",
    measurementId: "G-L987811QKB"
};
firebase.initializeApp(config);

const auth = firebase.auth();
auth.signInWithEmailAndPassword("johndifelice@protonmail.com", "google2940")
.then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });

export default firebase; 