/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { withRouter} from 'react-router-dom';
import {getDefinitions, getImages, getPronunciations, getPartsOfSpeech} from './Version';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./SimpleSlider.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {getVersionVoteCounts} from './Vote';
import {imageBrightness,textColors} from '../../constants/word-constants';
import {setDisplay} from "../../mods/slictionary-control-mod";
import arrowLeft from '../../assets/arrow-left-gray-solid.png';
import arrowRight from '../../assets/arrow-right-gray-solid.png';
import Image from 'react-bootstrap/Image';
import infoIcon from '../../assets/information-icon.png';
import {getWordDoc, updateSearchTransaction} from '../../services/word/WordService';
import {propertyValueExists} from '../../mods/slictionary-util-mod';
import { getWordBaron } from "../../services/word-bounty/WordBountyService";
import { ObjectFlags } from "typescript";

const {addVote} = require('../../services/vote/VoteService'); 
const config = require("../../config/config");
const axios = require("axios");

var moment = require('moment');
var _currentSlideIndex = 0;
var _pronunciations = [];
var _partsOfSpeech = [];
var _arrVoteCountsById = [];
var _wordBarons = [];
var _imageExists = false;
var transactionData;
var s ;
var rows = [];
var isFromWordBountyPage;
var definitions, doc, wordBaronData, wordLower;
var callbackCount = 0;
var transactionHasExpired = false;
var isMuggleMode = 
    (localStorage.getItem("muggleEmail") !== undefined || localStorage.getItem("muggleEmail") !== null) &&
    (localStorage.getItem("senderPaymail") === undefined || localStorage.getItem("senderPaymail") === null);

const beTheFirstAudioUrl = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/audio%2FBe%20the%20First.m4a?alt=media&token=611721e1-3ac6-45cb-ae09-0526976068c1";
const beTheFirstAudioUrl2 = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2FNew%20Recording%2011.m4a?alt=media&token=fdef7d6f-6c03-470e-9012-e78bb8545c59";


export class SimpleSlider extends Component {
    constructor(props) {
        super(props);

        this.postChange = this.postChange.bind(this);
        this.bulbClick = this.bulbClick.bind(this);
        this.bulbProcess = this.bulbClick.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getFormattedPaymail = this.getFormattedPaymail.bind(this);
        this.prepareSliderData = this.prepareSliderData.bind(this);
        this.callBackCounter = this.callBackCounter.bind(this);
        this.hasWordBaron = this.hasWordBaron.bind(this);
        this.getWordBaronJSX = this.getWordBaronJSX.bind(this);

        this.state = {
            order:0,
            imgUrl:"",
            pronunciation:"",
            definition:"",
            rows: [],
            audioSrc: "",
            pronunciations: [],
            isOpen: false,
            show: false,
            setShow: false,
            imgSrc:"",
            currentSlide:"",
            wordBarons: [],
            refresh: false
          };
          
          this.handleClose = this.handleClose.bind(this);
          this.handleShow = this.handleShow.bind(this);
          this.prevClick = this.prevClick.bind(this);
          this.nextClick = this.nextClick.bind(this);
          this.noNavLink = this.noNavLink.bind(this);
          this.afterChange = this.afterChange.bind(this);
          this.infoOnClick = this.infoOnClick.bind(this);
          this.infoWindowOnClose = this.infoWindowOnClose.bind(this);
          this.findPos = this.findPos.bind(this);
          this.getTransactionExpirationData = this.getTransactionExpirationData.bind(this);
          this.getOffset = this.getOffset.bind(this);
          this.imgOnClick = this.imgOnClick.bind(this);

          s = "";
          rows.length = 0;
          
          let arr = window.location.search.replace("?","").split("=");
          if(arr.length !== 2){
            isFromWordBountyPage = false;
          } else {
            isFromWordBountyPage = Number(arr[1]) === 1;
          }

          callbackCount = 0;
    }

    async componentWillMount(){
        await this.cool();
    }

    infoWindowOnClose = (e) => {
        this.closeInfoWindow();
        e.preventDefault();

    }

    closeInfoWindow = () => {
        document.getElementById("infoWindow").style.display = "none";
    }

    setModalState(showModal) {
        this.setState({
            showModal: showModal
        });
    }

    bulbClick = e => {
        var componentIdAndAuthor = e.target.id.split("|");
        var author = componentIdAndAuthor[1]
        
        if(this.canVoteForComponent(author, localStorage.getItem("senderPaymail"))){
            e.target.style.color = "yellow";
            e.target.className = "fa fa-lightbulb fa-1x column";

            let id = componentIdAndAuthor[0];
            let author = componentIdAndAuthor[1];
            let voter = localStorage.getItem("senderPaymail");
            addVote(id, this.props.word, author, voter);
        }
    }

    canVoteForComponent = (author, currentPaymail) => {
        let arrBulbs = document.getElementsByTagName('i');
        let returnVal = true;
        for(let i = 0; i < arrBulbs.length; i++){
            if (arrBulbs[i].className === "fa fa-lightbulb fa-1x column"){
                returnVal = false;
            }
        }

        if(author === currentPaymail){
            returnVal = false;
        }
        
        return returnVal;
    }

    imgOnClick = async(e) => {
        e.preventDefault();
        let result = {};
        result = this.getOffset(document.getElementById(e.currentTarget.id));
        console.log(result);
        console.log(`e.clientX: ${e.clientX}`)
        console.log(`e.offsetX: ${e.offsetX}`)
        let ele = document.getElementById("infoWindow");

        let eleInfoWb = document.getElementById("infoWordBaron");
        let eleInfoWs = document.getElementById("infoWordSmith");
        let key = e.currentTarget.id.split("-")[1];

        let eleWordBaron = document.getElementById(`wb-${key}`);
        let eleWordSmith = document.getElementById(`hidWordSmith-${key}`);
        eleInfoWb.innerText = eleWordBaron.innerText;
        eleInfoWs.innerText = eleWordSmith.innerText;

        //get Sponsor Data
        let wordBountyId = document.getElementById(`wbId-${key}`).innerText;
        const results = await axios.get(`${config.url.wordBountyService}/sponsor/${wordBountyId}`)
        .then (resp => {
            console.log('in then');
            return resp.data;
        })
        .catch(err => {
            console.log('in err');
        });
        console.log(results);
        var eleSponsors = document.getElementById("sponsors");
        eleSponsors.innerHTML = "";
        results.forEach(item => {
            let node = document.createElement("p");
            node.style.color = "#0097a7"
            let textnode = document.createTextNode(`${item.sponsorPaymail}`);
            node.appendChild(textnode)
            eleSponsors.appendChild(node)
        })

        ele.style.left = (result.left-760).toString() + "px";
        ele.style.left = (114).toString() + "px";
        ele.style.top = (result.top-550).toString() + "px";
        ele.style.top = (40).toString() + "px";

        if(ele.style.display === "inline") {
            ele.style.display = "none";
        } else {
            ele.style.display = "inline";
        }

    }

    beforeChange = event =>{
      this.slider.slickGoTo(0);
    }

    getCurrentSlideIndex(){
      return _currentSlideIndex;
    }

    postChange = async(eventCurrentSlide) => {
        if(eventCurrentSlide === -1){eventCurrentSlide = 0;}
        let key = _arrVoteCountsById[eventCurrentSlide].key;
        let audioUrl = "";

        if(_pronunciations[key].audioUrl === beTheFirstAudioUrl
            || _pronunciations[key].audioUrl === beTheFirstAudioUrl2 
        ){
            audioUrl = "";
        } else {
            audioUrl = _pronunciations[key].audioUrl;
        }
        
        let jsonReturn = {
          "audioUrl":audioUrl,
          "audioId":_pronunciations[key].id,
          "audioAuthor":_pronunciations[key].author,
          "transactionIsExpired": transactionData.expired
        } 
        this.props.parentCallback(jsonReturn);

    }

    handleShow = event => {
      this.setState({imgSrc:event.target.src});
      this.setState({show:!this.state.show});

      return false;
    }

    handleClose = () => {
        this.setState({show:!this.state.show});
    }

    infoOnClick = async(e) => {
        e.preventDefault();
        let ele = document.getElementById("infoWindow");
        let eleInfoWb = document.getElementById("infoWordBaron");
        let eleInfoWs = document.getElementById("infoWordSmith");
        let eleKey = document.getElementById(`key-${_currentSlideIndex}`);
        let key = Number(eleKey.innerText);

        let eleWordBaron = document.getElementById(`wb-${key}`);
        let eleWordSmith = document.getElementById(`ws-${key}`);
        eleInfoWb.innerText = eleWordBaron.innerText;
        eleInfoWs.innerText = eleWordSmith.innerText;

        //get Sponsor Data
        let wordBountyId = document.getElementById(`wbId-${key}`).innerText;
        const results = await axios.get(`${config.url.wordBountyService}/sponsor/${wordBountyId}`)
        .then (resp => {
            console.log('in then');
            return resp.data;
        })
        .catch(err => {
            console.log('in err');
        });
        var eleSponsors = document.getElementById("sponsors");
        eleSponsors.innerHTML = "";
        results.forEach(item => {
            let node = document.createElement("p");
            node.style.color = "#0097a7"
            let textnode = document.createTextNode(`${item.sponsorPaymail}`);
            node.appendChild(textnode)
            eleSponsors.appendChild(node)
        })

        if(ele.style.display === "inline") {
            ele.style.display = "none";
        } else {
            ele.style.display = "inline";
        }
        
    }

    getTransactionExpirationData = (txId, definitions) => {
        var obj = {
            expired: false, 
            invalidWordTxIdCombo: false,
            authorExists: false
        };

        try{
            let user = JSON.parse(localStorage.getItem("user"));
            var authorExists = false;

            user.vendorWallets.forEach(item =>{
                if(authorExists === false){
                    authorExists = propertyValueExists(definitions, "author", item.paymail);
                }
            });

            obj.authorExists = authorExists;
            
            if(localStorage.getItem("senderPaymail") === undefined || localStorage.getItem("senderPaymail") === null) {
                obj.expired = true;
                return obj;
            } else {
                let axiosInstance = axios.create({
                    headers:{
                        "Accept-Encoding": ""
                    }
                })

                var queryTxId = axiosInstance.get(`${config.url.voteService}/transaction/isvalid/${this.props.word}/${txId}`, config)
                    .then(response => {
                        obj.expired = response.data.expired;
                        obj.invalidWordTxIdCombo = response.data.invalidWordTxIdCombo;
                        return obj;
                    })
                    .catch(err => {
                        obj.expired = true ;
                        obj.invalidWordTxIdCombo = false;
                        authorExists = false;
                        return obj;

                    })


                return queryTxId;
            }



            //obj.authorExists = authorExists;
        } catch (error) {
            obj.expired = true ;
            obj.invalidWordTxIdCombo = false;
            authorExists = false;

        }
        //return obj;
    }

    callBackCounter = async() => {
        callbackCount += 1;
        if(!isMuggleMode && callbackCount === 3){
            this.prepareSliderData();
        } else if(isMuggleMode && callbackCount === 2){
            this.prepareSliderData();
        } 
    }

    cool = async () => {
        wordLower = this.props.word;      
        doc = await getWordDoc(wordLower);
        
        definitions = await getDefinitions(wordLower,null,doc);
        
       
        var pathname = (new URL(document.location)).pathname;
        var _txId = pathname.split("/")[3];
 
        if(isMuggleMode){
            transactionHasExpired = true;

            transactionData = {
                expired: true, 
                invalidWordTxIdCombo: false,
                authorExists: false
            };
    
        } else {
            const result = this.getTransactionExpirationData(_txId, definitions)
                .then(response => {
                    if(response.invalidWordTxIdCombo && !response.authorExists){
                        this.props.history.push({pathname:"/error-pages/error-invalid-transaction"});
                    }
            
                    if(response.expired === false){
                        let success = updateSearchTransaction(_txId);
                        if(!success){
                            throw new Error("Revenue search record update failed.")
                        }
                    }

                    transactionHasExpired = response.expired;

                
                transactionData = response;
                this.callBackCounter();
                return response;
            })
            .catch(err => {
                console.log(err.toString());
            });
        }
        //find out if word is part of an active bounty, then filter out (older) definitions that are not part of the word bounty 
        const queryWordBaron = getWordBaron(wordLower, "pending")
            .then(doc => {
                wordBaronData = doc;
                this.props.wordBaronCallback(wordBaronData);
                this.callBackCounter();
                return true;
            })
            .catch(err => {
                console.log(JSON.stringify(err));
                return false;
            })
        
        const results = axios.get(`${config.url.wordBountyService}/wordbaron/${wordLower}`)
            .then(doc => {
                _wordBarons = doc.data;
                this.callBackCounter();
            })
    } 

    prepareSliderData = async() => {
        // part of hot fix, Christmas Eve
        let images = await getImages(wordLower,null,doc);
        _pronunciations = await getPronunciations(wordLower,null,doc);
        _partsOfSpeech = await getPartsOfSpeech(wordLower, null, doc);

        _arrVoteCountsById = await getVersionVoteCounts(wordLower, doc);
        
        let arrCompIds = [];
        for(let i = 0; i < _arrVoteCountsById.length; i++){
            arrCompIds.push({compId: definitions[i].id});

        }

        let loopIndex = 0;

        for (var  index in _arrVoteCountsById) {
            let _imageBrightness = imageBrightness;

            let key = _arrVoteCountsById[index].key.toString();
                
            //This code is to hide the default 'no image' image. The hidden class is defined
            //on the definitions.css page
            var hiddenClass = "";
            if( images[key].imgUrl === "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2Fno%20image.jpg?alt=media&token=f4c3f74a-30c0-4a5e-8f52-67fea1cf13f1"){
                images[key].imgUrl = "";
            }
            
            if(images[key].imgUrl === ""){
                hiddenClass = "text-center hidden";
                _imageExists = false;
                }else {
                hiddenClass = "text-center";
                _imageExists = true;
            }

            var hiddenExampleClass = "";
            if(definitions[key].usage === ""){
                hiddenExampleClass="hidden";
            } else {
                hiddenExampleClass = "";
            }
            
            var partsOfSpeechDisplay = "";
            if(_partsOfSpeech[key].value.length > 0){
                partsOfSpeechDisplay = "[" + _partsOfSpeech[key].value + "]";
            } 
            
            let className = "column imagePanel brightness-" + imageBrightness[loopIndex].toString();
            let fontColor = textColors[loopIndex];
            var wordBaronDisplay = _wordBarons[key] !== undefined &&_wordBarons[key].wordBountyId !== undefined ? "inline" : "none";
            var wordSmithDisplay = "inline";
            var showDefinition = false;
            var definitionColor;

            let definitionCreateDate = new Date(definitions[key].createDate);
            let wordBountyVoteStartDate = new Date(wordBaronData.voteStart);
            //check error here with dtVoteStart conversion. Looks like it's a day off
            wordBountyVoteStartDate.setHours(wordBountyVoteStartDate.getHours() - 2)

            let isOlderDefinition = wordBountyVoteStartDate - definitionCreateDate > 0; 

            //determine if definition should be shown
            showDefinition = isFromWordBountyPage ? definitionCreateDate - wordBountyVoteStartDate >= 0 : true;

            //determine the color of the definition border
            definitionColor = isOlderDefinition || !wordBaronData.isActiveBounty ? "gray" : "#38CB7C";
            let definitionBorder = isOlderDefinition || !wordBaronData.isActiveBounty ? `2px solid ${definitionColor}` : `2px solid ${definitionColor}`;
            
            let wordSmithWidth = "";
            if(this.getFormattedPaymail(definitions[key].author).length < 20){
                wordSmithWidth = ((this.getFormattedPaymail(definitions[key].author).length + 1) * -8) + 320 -20; // 8 pixels per characters. 320px is the width of the box. -20 is to add padding.
            } else {
                wordSmithWidth = ((this.getFormattedPaymail(definitions[key].author).length + 1) * -8) + 320;
            }
            let wordBaronWidth = _wordBarons[key] !== undefined ? ((this.getFormattedPaymail(_wordBarons[key].wordBaron).length + 1) * -8 + 2*20 + 320) / 2 : 0;
            //the 1 pixel is for the dash before the name and the 20 pixels is for the icon. The icon width is doubled to make the word baron text look more centered.
            
          if(showDefinition){ 

            let divStyle = {border:definitionBorder, padding: "10px", paddingTop: "20px", paddingBottom: "20px",borderRadius: "25px", backgroundColor: "black"};
            let etymologyStyle = {fontSize:"12pt",display: (definitions[key].etymology === undefined || definitions[key].etymology.length === 0) ? "none" : "inline"};

            let likeBulbFactory = new LikeBulbFactory();

            let ImageLikeBulb = likeBulbFactory.createLikeBulb("image", images[key].id, images[key].author);
            let likeBulbVisibilityJsonForImage = ImageLikeBulb.getVisibilityJson(transactionData.expired, definitions[key].author === localStorage.getItem("senderPaymail"), this.isExternalLink(), _imageExists);
            let isImageLikeBulbVisible = this.getLikeBulbDisplaySetting(likeBulbVisibilityJsonForImage);

            let DefLikeBulb = likeBulbFactory.createLikeBulb("", definitions[key].id, definitions[key].author);
            let likeBulbVisibilityJsonForDef = DefLikeBulb.getVisibilityJson(transactionData.expired, definitions[key].author === localStorage.getItem("senderPaymail"), this.isExternalLink(), true);
            let isDefLikeBulbVisible = this.getLikeBulbDisplaySetting(likeBulbVisibilityJsonForDef);

            let UsageLikeBulb = likeBulbFactory.createLikeBulb("", definitions[key].usageId, definitions[key].author);
            let likeBulbVisibilityJsonForUsage = UsageLikeBulb.getVisibilityJson(transactionData.expired, definitions[key].author === localStorage.getItem("senderPaymail"), this.isExternalLink(), true);
            let isUsageLikeBulbVisible = this.getLikeBulbDisplaySetting(likeBulbVisibilityJsonForUsage);

            let EtymologyLikeBulb = likeBulbFactory.createLikeBulb("", definitions[key].etymologyId, definitions[key].author);
            let likeBulbVisibilityJsonForEtymology = EtymologyLikeBulb.getVisibilityJson(transactionData.expired, definitions[key].author === localStorage.getItem("senderPaymail"), this.isExternalLink(), definitions[key].etymology !== undefined && definitions[key].etymology.length > 0);
            let isEtymologyLikeBulbVisible = this.getLikeBulbDisplaySetting(likeBulbVisibilityJsonForEtymology);

            rows.push(
            <div key={loopIndex}>
                {this.getDefImageContainerJSX(
                    hiddenClass, 
                    this.getDefImageJSX(this.getDefImageAttributeJson(images[key].imgUrl, className),this.handleShow), 
                    isImageLikeBulbVisible ? ImageLikeBulb.getJSX(this.bulbClick) : (<></>)
                    )
                }
                <Container>
                    <div style={divStyle}>
                        <Row className="" dir={config.app.lang === "msa" ? "rtl" : "ltr"}>
                            <Col>
                                <span className="" id="sp0" style={{color:fontColor}}>
                                    <span id="partsOfSpeech"><em>{partsOfSpeechDisplay}</em></span>
                                    <p dir={config.app.lang === "msa" ? "rtl" : "ltr"} style={{color: "white"}}>
                                        {definitions[key].definition}
                                    </p>
                                    {isDefLikeBulbVisible ? DefLikeBulb.getJSX(this.bulbClick) : (<></>)}
                                    <div>&nbsp;</div>
                                    <div style={{fontSize:"12pt"}} className={hiddenExampleClass}>
                                        <i>Used in a sentence:</i>
                                    </div>
                                    <div 
                                        style={{fontSize:"12pt",color:fontColor}} 
                                        className={hiddenExampleClass} dir={config.app.lang === "msa" ? "rtl" : "ltr"}>
                                        <i>{definitions[key].usage}</i>
                                        {isUsageLikeBulbVisible ? UsageLikeBulb.getJSX(this.bulbClick) : (<></>)}
                                    </div>
                                    <div style={etymologyStyle} className={hiddenExampleClass}>
                                        <div>&nbsp;</div>
                                        <i>Etymology:</i>
                                    </div>
                                    <div 
                                        style={{fontSize:"12pt",color:fontColor}} 
                                        className={hiddenExampleClass} dir={config.app.lang === "msa" ? "rtl" : "ltr"}>
                                            <i>{definitions[key].etymology === undefined ? "" : definitions[key].etymology}</i>
                                            {isEtymologyLikeBulbVisible ? EtymologyLikeBulb.getJSX(this.bulbClick) : (<></>)}
                                    </div>
                                    {<div>&nbsp;</div>}
                                    <div style={{fontSize: "9pt", display: wordSmithDisplay}}>
                                        <span id={"ws-" + key} style={{position:"relative",left:wordSmithWidth}}>-{this.getFormattedPaymail(definitions[key].author)}</span>
                                    </div>
                                    <div>
                                        <span hidden id={"wbId-" + key}>{_wordBarons[key] !== undefined ? _wordBarons[key].wordBountyId : "None"}</span>
                                    </div>
                                    <div>
                                        <span hidden id={"key-" + index}>{key}</span>
                                    </div>
                                    <div>
                                        <span hidden id={"hidWordSmith-" + key}>{definitions[key].author}</span>
                                    </div>
                                </span>
                            </Col>
                        </Row>
                    </div>
                    {this.getWordBaronJSX(_wordBarons,definitions[key].id,wordBaronDisplay,wordBaronWidth,key,index)}
                </Container>
            </div>

            )
            }

            if(loopIndex < _imageBrightness.length-3){
              loopIndex += 1;
            }
            
      }
        this.setState({refresh: true});  
        let json = {arrVoteCountsById: _arrVoteCountsById, doc: doc.data}; 
        this.props.voteArrayCallback(json);     
      }

    hasWordBaron = (arrWordBarons, definitionCompId) => {
        let findIndex = arrWordBarons.findIndex(obj => obj.compId === definitionCompId);
        let returnVal = false;
        if(findIndex !== -1){
            returnVal = arrWordBarons[findIndex].wordBaron !== "None";
        }

        return returnVal;
    }

    getDefImageContainerJSX = (hiddenClass, defImageJSX, imageLikeBulbJSX) => {
        return(
            <>
            <Container>
                <Row className={hiddenClass}  >
                    <Col>
                        {defImageJSX}
                        {imageLikeBulbJSX}
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
            </Container>
            </>
        )
    }

    getDefImageAttributeJson = (imgUrl, className) => {
        return {
            className: className,
            src: imgUrl,
            alt: ""
        }
    }

    getDefImageJSX = (attributeJson, onDoubleClickCallback) => {
        return (
            <>
            <img 
                style={{
                    display: "inline",
                    whiteSpace: "nowrap",
                    position: "relative",
                    cursor: "pointer"
                }}
                className={attributeJson.className}
                src={attributeJson.src}
                onDoubleClick={onDoubleClickCallback}
                alt=""
            />
        </>
        )
    }

    getLikeBulbJSX = (attributeJson, onClickEventCallback) => {
        return(
           <>
            <i 
                id= {attributeJson.id}
                onClick={onClickEventCallback}
                className={attributeJson.className}
                title={attributeJson.title}
            ></i>
            </>
        )
    }

    getLikeBulbDisplaySetting = (likeBulbVisibilityJson) => {
        let likeBulbDisplaySetting = false;
        if(
            likeBulbVisibilityJson.isTransactionIdExpired || 
            likeBulbVisibilityJson.isAuthor || 
            likeBulbVisibilityJson.isExternalLink ||
            !likeBulbVisibilityJson.componentExists
        ){
            likeBulbDisplaySetting = false;
        } else {
            likeBulbDisplaySetting = true; 
        }

        return likeBulbDisplaySetting;
    }

    isExternalLink = () => {
        let url = document.referrer;
        const tmp = document.createElement('a');
        tmp.href = url;
        return tmp.host !== window.location.host;
    };

    getWordBaronJSX = (arrWordBarons, definitionCompId, wordBaronDisplay, wordBaronWidth, key, index) => {
        let haswordbaron = this.hasWordBaron(arrWordBarons, definitionCompId);
        let returnVal;
        
        if(haswordbaron){
            returnVal = (
                <div style={{fontSize: "9pt", top: "0px", left: "0px", color:"white", display: wordBaronDisplay}} id={`lbl-${index}`}>
                    <span id={"wb-" + key} style={{display: "inline-block", position: "relative", left: wordBaronWidth }}>
                        {
                            this.getFormattedPaymail(_wordBarons[key].wordBaron)
                        }
                        <a href="." id={"a-" + key} onClick={this.imgOnClick}>
                            <img src={infoIcon} alt="" style={{width: "20px",height:"auto",display:"inline-block"}}/>
                        </a>
                    </span>
                </div>
            )
        } else {
            returnVal = (<><div></div></>)
        }

        return returnVal;
    }

    onAudioClick = (event) => {
        let audioId = event.target.id.split("|")[1];
        if(document.getElementById(audioId).paused){ // checks if element is playing right now
            document.getElementById(audioId).play(); return false;
        } else {

            document.getElementById(audioId).pause(); return false;
        }
    }

    getFormattedPaymail = (paymail) => {
        let arr = paymail.split("@");
        let returnVal = "";
        if(arr[1] === "handcash.io"){
            returnVal = "$" + arr[0];
        } else {
            returnVal = paymail;
        }

        return returnVal;
    }

    toggleModal = () => {
      alert(this.state.isOpen);
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    prevClick = (e) => {
        this.slider.slickPrev();
        this.closeInfoWindow();
        e.preventDefault();
    }

    afterChange = async(currentSlide) => {
        _currentSlideIndex = currentSlide;        
    }

    nextClick = (e) => {
        this.slider.slickNext();
        this.closeInfoWindow();
        e.preventDefault();
        
    }

    findPos = (obj) => {
        var curleft = 0;
        var curtop = 0;
    
        if (obj.offsetParent) {
            do {
                    curleft += obj.offsetLeft;
                    curtop += obj.offsetTop;
                } while (obj = obj.offsetParent);
        
        return [curleft,curtop];
        }
    
    }
    
    noNavLink = (e) => {
        e.preventDefault();
        return false;
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        }
    }
    
    render() {
        if(document.getElementsByName("audioBulb")[0] !== undefined){
            document.getElementsByName("audioBulb")[0].style.display = transactionHasExpired ? "none" : "inline";
        }
        const ArrowLeft = () => (
            <a href="">
            <Image
                id="prevArrow"
                title="Scroll Left"
                src={arrowLeft}
                onClick={this.prevClick}
            />
            </a>
          );
          const ArrowRight = () => (
            <a href="" >
            <Image
                id="nextArrow"
                title="Scroll Right"
                src={arrowRight}
                onClick={this.nextClick}
            />
            </a>
          );

        const settings = {
            arrows: true,
            prevArrow: <ArrowLeft />,
            nextArrow: <ArrowRight />,
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "300px",
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            beforeChange:(current,next) => {
                if(this.slider){
                  this.slider.slickGoTo(0);
                }
              },
            afterChange: this.postChange,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1
                  }
                },
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]
        };

    return (
        
      <div className="container">
      <div id="infoWindow"
      
            style={{display: "none", color: "#efefef", position: "absolute", width:"272px",
                                    border: "5px solid white", backgroundColor: "#434343", opacity:"1.0", padding: "10px", zIndex:"999"
                                }}>
            <p style={{paddingBottom: "10px"}}>
             Word$mith, the creator of the definition:  <span id="infoWordSmith" style={{color:"#0097a7"}}></span>
             </p>                                
            <p style={{paddingBottom: "10px"}}>
            Patron, a charitable supplier of funds for Word$miths to create & own definitions:
                <span id="sponsors"></span>
            </p>
            <p style={{paddingBottom: "10px"}}>
            Word-Baron, the owner of the definition:  <span id="infoWordBaron" style={{color:"#0097a7"}}></span>
            </p>
            <p style={{alignText: "center"}}>
            <Button variant="secondary" onClick={this.infoWindowOnClose}>
                Close
                </Button>
            </p>
      </div>
      <div>
      </div>


        <Modal width="90%" height="90%" show={this.state.show} onHide={this.handleClose} style={{border:"1px"}} size="lg">
            <Modal.Body className="center" style={{backgroundColor:"black"}}>
                <div style={{backgroundColor:"black"}}>
                <img id="modalImage" alt="" src={this.state.imgSrc}/>
                </div>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor:"black"}}>
                <Button variant="secondary" onClick={this.handleClose}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Slider ref={slider => (this.slider = slider)} {...settings}>{rows}</Slider>
      </div>     
    );
  }
}

export default withRouter(SimpleSlider)

class LikeBulbFactory {
    constructor(){
        this.likeBulb = null
    }

    createLikeBulb = (type, componentId, author) => {
        if (type === "image") {
            this.likeBulb = new ImageLikeBulb(componentId, author);
        } else if (type === "etymology") {
            this.likeBulb = new DefaultLikeBulb(componentId, author);
        } else {
            this.likeBulb = new DefaultLikeBulb(componentId, author);
        }

        return this.likeBulb;
    }
}

class DefaultLikeBulb{
    constructor(componentId, author){
        this.componentId = componentId;
        this.author = author;
        this.onLikeBulbClick = null;
        this.className = "far fa-lightbulb fa-1x column";
        this.title = "Vote";
    }

    getJSX = (onLikeBulbClick) => {
        this.onLikeBulbClick = onLikeBulbClick
        return(
            <>
            <i
                style={{
                    display: "inline-flex",
                    color: "white",
                    verticalAlign: "top",
                    position: "relative",
                    left: "0px",
                    top: "0px",
                    backgroundColor: "transparent",
                    width: "20px"
                }}
                id= {this.componentId + "|" + this.author} 
                onClick={this.onLikeBulbClick}
                className={this.className}
                title={this.title}
            ></i>
            </>
        )
    }

    getVisibilityJson = (isTransactionIdExpired, isAuthor, isExternalLink, componentExists) => {
        return {
            isTransactionIdExpired: isTransactionIdExpired, 
            isAuthor: isAuthor, 
            isExternalLink: isExternalLink, 
            componentExists: componentExists
        }

    }
}

class ImageLikeBulb extends DefaultLikeBulb {

}

