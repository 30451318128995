const {HandCashConnect} = require('@handcash/handcash-connect');
const config = require("../config/config");

const getHCRedirctionLoginUrl = () => {
    const handCashConnect = new HandCashConnect({
        appId: config.handcash.appId,
        appSecret: config.handcash.appSecret,
    });
    return handCashConnect.getRedirectionUrl();
}

export {getHCRedirctionLoginUrl};