/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import firebase from '../firebase/Firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

//import {getValidationModal} from './CreateWord.modals.js'

import generatePushID from './Common/PushId.js';
import Button from 'react-bootstrap/Button';
import MicRecorder from 'mic-recorder-to-mp3';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import recorderIcon from '../assets/mic-record-audio.png';
import "./CreateWord.css";
import "./Blink.css";
import redX from '../assets/red-x.png';
import warningIcon from '../assets/warning-icon.png';
import {Mode} from '../enums/AddWordEnum.ts';
import Modal from 'react-bootstrap/Modal';
import { addErrorToLog } from '../mods/slictionary-error-log-mod';
import HcButton from "../components/handcash/HcButton";
import {getWordDoc, getWordId, addDefinition} from '../services/word/WordService';
import {getEmailValidation, createMuggle,
        postDiscountCodeEmail, putDiscountCodeEmailExpire} from '../services/user/UserService';
import uploadImage from '../assets/upload-image.png';
import { getUUID, capitalizeEachWord, encodeURL, decodeURL } from '../mods/slictionary-util-mod';
import { postAnalyticsPage } from '../services/user/UserService';
const config = require("../config/config");

var moment = require('moment');
var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;
var imgDownloadUrl = "";
var audioDownloadUrl = "";
var isFromWordBountyPage = false;
var isSmithingDiscount = false;
var isMuggleMode = false;
var isLoggedIn = false;
var muggleEmail = "";

let bWordExists = true;
const Mp3Recorder = new MicRecorder({ bitRate: 256 });

const transfer = {
    to: null,
    amount: null,
    currency: 'USD'
  }

class CreateWord extends Component {
    
    constructor(props) {

        super(props);
        postAnalyticsPage(localStorage.getItem("senderPaymail"),
        window.location.pathname,
        window.navigator.userAgent,
        window.navigator.userAgentData);

        let arr = this.props.location.pathname.split("/");

        let arr2 = window.location.search.replace("?","").split("=");
        if(arr2.length !== 2){
            isFromWordBountyPage = false;
            isSmithingDiscount = false;
        } else {
            isFromWordBountyPage = Number(arr2[1]) === 1 && arr2[0] === "wb";
            isSmithingDiscount = arr2[0] === "sd";
        }

        isMuggleMode = (isFromWordBountyPage || isSmithingDiscount)
            && (localStorage.getItem("senderPaymail") === undefined || localStorage.getItem("senderPaymail") === null); 

        isLoggedIn = !(localStorage.getItem("senderPaymail") === undefined || localStorage.getItem("senderPaymail") === null);

        this.state = {
            word: decodeURL(arr[2]) === "@" ? " " : decodeURL(arr[2]),
            defId: arr[3],
            txid: arr[4],
            senderPaymail: localStorage.getItem("senderPaymail"),
            selectedImageFile: null,
            selectedAudioFile: null,
            definition:"",
            usage:"",
            etymology: "",
            pronunciation:"",
            docId:"",
            imgDownloadUrl: "",
            partOfSpeech: "",
            childproof: "",
            buttonJSX:null,
            isRecording: false,
            blobURL: '',
            isBlocked: false,
            profileImg: uploadImage,
            mode: "",
            docCurrent:null,
            show: false,
            setShow: false,
            amount: "0.0096",
            toPaymail:"selflearningdictionary@MoneyButton.com",
            currency: "USD",
            showValidationModal: false,
            showEmailValidationModal: isMuggleMode && !isSmithingDiscount,
            showDiscountCodeModal: false,
            discountCode: arr2[1]
        };
        this.handleChange = this.handleChange.bind(this);
        this.selectedImageHandler = this.selectedImageHandler.bind(this);
        this.selectedAudioHandler = this.selectedAudioHandler.bind(this);
        this.onfocusout = this.onfocusout.bind(this);
        this.onAddWordClick = this.onAddWordClick.bind(this);
        //this.getAddWordJson = this.getAddWordJson.bind(this);
        this.onKeyDownDescription = this.onKeyDownDescription.bind(this);
        this.addWord = this.addWord.bind(this);
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.onRecorderClick = this.onRecorderClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseValidationModal = this.handleCloseValidationModal.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.setPaymail = this.setPaymail.bind(this);
        this.setAmount = this.setAmount.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.hcPostProcess = this.hcPostProcess.bind(this);
        this.hcPreProcess = this.hcPreProcess.bind(this);
        this.handleSpecialCharacters = this.handleSpecialCharacters.bind(this);
        this.imgUploadClick = this.imgUploadClick.bind(this);
        this.handleCloseEmailValidation = this.handleCloseEmailValidation.bind(this);
        this.submitEmail = this.submitEmail.bind(this);
        this.addMuggleOnClick = this.addMuggleOnClick.bind(this);
        this.showDiscountCodeModalOnClick = this.showDiscountCodeModalOnClick.bind(this);
        this.supportOnClick = this.supportOnClick.bind(this);
    }

    setAmount = (value) => {
        this.setState({ amount: value })
      }

    setSelected = (value) => {
        this.setState({ twetchChecked: value });
        if(value === true){
            this.setAmount("0.015");
        } else {
            this.setAmount("0.0005");
        }
    }
    
    async componentWillMount(){ 
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
        document.body.style.backgroundAttachment = "fixed";
    }
    
    async componentDidMount(word) {
        document.getElementById("word").focus();

        if(isSmithingDiscount){
            document.getElementById("txtDiscountCode").value = this.state.discountCode;
        }

        const element = document.getElementById("input"); 
        element.addEventListener('change', async(e) => {
                      
        });

        const doc = await getWordDoc(this.state.word);
        bWordExists = doc === {} ? false : true;
        if(bWordExists){
            this.setState({mode:Mode.AddVersion});
        } else {
            this.setState({mode:Mode.AddWord});
        }     
        this.setState({docCurrent: doc});

        if (this.state.word === " "){
            this.setState({word:""});
        }    

        if(this.state.txid !== "@" && this.state.mode === Mode.AddWord){
            this.setState({buttonJSX: <Button 
                
                className="btn btn-primary btn-block"
                onClick={this.onAddWordClick} style={{width:"150px"}}>
                    FREE Add Word
                </Button>
            });
        } else {
        }

        navigator.mediaDevices.getUserMedia({ audio: true },
            () => {
              this.setState({ isBlocked: false });
            },
            () => {
              this.setState({ isBlocked: true })
            },
          );
    }

    showDiscountCodeModalOnClick = (e) => {
        this.setState({showDiscountCodeModal: true});
    }

    handleCloseDiscountCode = (e) => {
        this.setState({showDiscountCodeModal: false});
    }

    imgUploadClick = async(event) => {
        document.getElementById("input").click();
    }

    onAddWordClick = async(event) =>{
        this.addWord(this.state.senderPaymail, this.state.txid, "0.00");
    }

    submitEmail = async(e) => {
        try {
            document.getElementById("btnSend").innerHTML = "VERIFYING...";
            var email = document.getElementById("txtEmail").value;
            muggleEmail = email;

            if(email.length === 0){
                throw new Error("You must enter an email address");
            }

            var eleErrorMessage = document.getElementById("validateEmail");
            var errorMessage = "";
            const emailValidation = await getEmailValidation(email);

            if(emailValidation.formatCheck === "false"){
                errorMessage = "Check your email address for typos and resubmit."
                throw new Error(errorMessage);
            }
            if(emailValidation.disposableCheck === "true"){
                errorMessage = "You have entered a disposable email address. Please enter a different one to proceed.";
                throw new Error(errorMessage);
            }
            // if(emailValidation.dnsCheck === "false"){
            //     errorMessage = "The email address you have entered has an unknown domain. Please enter a different one to proceed.";
            //     throw new Error(errorMessage);
            // }  
            if(emailValidation.smtpCheck === "false"){
                errorMessage = "We could not confirm that the email address you entered is active. Please enter a different one to proceed.";
                throw new Error(errorMessage);
            }

            // Passed all validations. Store it.
            const snapshot = await createMuggle(email);
            if(snapshot.status === "success"){
                document.getElementById("divEmail").style.display = "none";
                document.getElementById("divThankYou").style.display = "inline-block";
                localStorage.setItem("muggleEmail", email);
                this.setState({senderPaymail: email});
            } else {
                throw new Error("There was an error creating the email record. Check your email address and try again.");
            } 
        } catch (error) {
            document.getElementById("btnSend").innerHTML = "SUBMIT";
            eleErrorMessage.innerHTML = error.message;
            eleErrorMessage.style.display = "inline-block";
        }
    }

    submitEmailDiscountCode = async(e) => {
        var eleErrorMessage;
        var errorMessage;
        try {
            document.getElementById("btnSendDiscountCode").innerHTML = "VERIFYING...";
            var email = document.getElementById("txtEmailDiscountCode").value;
            var discountCode = document.getElementById("txtDiscountCode").value;
            muggleEmail = email;
            eleErrorMessage = document.getElementById("validateEmailDiscountCode");
            errorMessage = "";

            if(email.length === 0){
                throw new Error("You must enter an email address");
            }
            
            const emailValidation = await getEmailValidation(email);

            if(emailValidation.formatCheck === "false"){
                errorMessage = "Check your email address for typos and resubmit."
                throw new Error(errorMessage);
            }
            if(emailValidation.disposableCheck === "true"){
                errorMessage = "You have entered a disposable email address. Please enter a different one to proceed.";
                throw new Error(errorMessage);
            }
            if(emailValidation.dnsCheck === "false"){
                errorMessage = "The email address you have entered has an unknown domain. Please enter a different one to proceed.";
                throw new Error(errorMessage);
            }  
            if(emailValidation.smtpCheck === "false"){
                errorMessage = "We could not confirm that the email address you entered is active. Please enter a different one to proceed.";
                throw new Error(errorMessage);
            }

            eleErrorMessage.style.display = "inline-block";

            // Passed all validations. Store it.
            const snapshot = await createMuggle(email);
            if(snapshot.status === "success"){
                localStorage.setItem("muggleEmail", email);
                this.setState({senderPaymail: email});
            } else {
                throw new Error("There was an error creating the email record. Check your email address and try again.");
            } 

            //Add the discountCode/Email
            let result = await postDiscountCodeEmail(discountCode, email);
            if(result.status === "success"){
                this.addWord(muggleEmail, getUUID(), 0);
                this.setState({showDiscountCodeModal: false});
            }
            //Add Word
        } catch (error) {
            document.getElementById("btnSendDiscountCode").innerHTML = "SUBMIT";
            eleErrorMessage.innerHTML = error.message;
            eleErrorMessage.style.color = "red";
            eleErrorMessage.style.display = "inline-block";
        }
    }

    getAddWordURLSearchParams = (
        word,
        senderPaymail,
        kidFriendly,
        slang,
        defId,
        partOfSpeechId,
        partOfSpeech,
        definition,
        usage,
        etymology,
        etymologyId,
        imageId,
        imgDownloadUrl,
        pronunciationId,
        pronunciation,
        audioDownloadUrl,
        txId,
        amount,
        subType,
        videoId

    ) => {
        var urlSearchParams = new URLSearchParams(
            [ 
                ["word", word],
                ["firstThree", word.substring(0,3)],
                ["first", word.substring(0,1)],
                ["kidFriendly", kidFriendly],
                ["isSlang", slang],
                ["isSAT", false],
                ["createDate", moment().format()],
                ["id", defId],
                ["txId", txId],
                ["amount", amount],
                ["subType", subType],
                ["partOfSpeech_createDate", moment().format()],
                ["partOfSpeech_id",partOfSpeechId],
                ["partOfSpeech_author", senderPaymail],
                ["partOfSpeech_value", partOfSpeech],
                ["definitionText_createDate", moment().format()],
                ["definitionText_id", defId],
                ["definitionText_author", senderPaymail],
                ["definitionText_definition", definition],
                ["definitionText_usage", usage],
                ["image_createDate", moment().format()],
                ["image_id", imageId],
                ["image_author",senderPaymail],
                ["image_imgUrl", imgDownloadUrl],
                ["pronunciations_createDate",moment().format()],
                ["pronunciations_id", pronunciationId],
                ["pronunciations_author",senderPaymail],
                ["pronunciations_IPA",""],
                ["pronunciations_phonetic",pronunciation],
                ["pronunciations_audioUrl",audioDownloadUrl],
                ["videos_createDate",moment().format()],
                ["videos_id",videoId],
                ["videos_author",senderPaymail],
                ["videos_videoUrl",""],
                ["etymologyText_createDate",moment().format()],
                ["etymologyText_id",etymologyId],
                ["etymologyText_author", senderPaymail],
                ["etymologyText_etymology", etymology]
            ]);

        return urlSearchParams;
    }

    handleSpecialCharacters = (text) => {
        /*
            special characters: &+=[]{}

            The replacement strings use the ASCII codes for the characters to be replaced.
            AMP#38 = &
            AMP#43 = +
            AMP#61 = =
            AMP#91 = [
            AMP#93 = ]
            AMP#123 = {
            AMP#125 = }
        */
        text = text.replaceAll("&","AMP#38").replaceAll("+","AMP#43").replaceAll("=","AMP#61").replaceAll("[","AMP#91").replaceAll("]","AMP#93").replaceAll("{","AMP#123").replaceAll("}","AMP#125")
        
        return text;
    }

    addWord = async(senderPaymail, txId, amount) =>{
        if(
            this.state.word.trim().length === 0 ||
            this.state.definition.trim().length === 0 ||
            this.state.usage.trim().length === 0 ||
            this.state.partOfSpeech.length === 0
        ) {
            this.setState({showValidationModal: true});
        } else {
            var word = this.state.partOfSpeech === "person" || this.state.partOfSpeech === "parts of speech"
                ? capitalizeEachWord(this.state.word) : this.state.word;
            var defId = "def" + generatePushID();
            var imageId = "img" + generatePushID();
            var pronunciationId = "aud" + generatePushID();
            var partOfSpeechId = "prt" + generatePushID();
            var etymologyId = "ety" + generatePushID();
            var videoId = "vid" + generatePushID();
           
            var addWordURLSearchParams = this.getAddWordURLSearchParams(
                word,
                senderPaymail,
                !document.getElementById('childproof').checked,
                document.getElementById('slang').checked,
                defId,
                partOfSpeechId,
                this.state.partOfSpeech,
                this.state.definition,
                this.state.usage,
                this.state.etymology,
                etymologyId,
                imageId,
                imgDownloadUrl.replaceAll("=","").replaceAll("&",""),
                pronunciationId,
                this.state.pronunciation,
                audioDownloadUrl.replaceAll("=","").replaceAll("&",""),
                txId,
                amount,
                this.state.mode,
                videoId
            )
            
            try {
                let result = await addDefinition(addWordURLSearchParams);
                audioDownloadUrl = "";
                imgDownloadUrl = "";
                let encodedWord = encodeURL(word);
                this.props.history.push({
                    pathname:`/definitions/${encodedWord}/${txId}`
                }); 
            } catch (error) {
                this.setState({show: true});
            }
        }
    }

    onfocusout = async(event) =>{
        let word = document.getElementById('word').value;
        if(word.length > 0){
            let ID = await getWordId(word);
           
            if (ID.length > 0){
                //word exists. Switch to AddVersion mode.
                this.setState({mode: Mode.AddVersion});
                this.setState({defId: ID});
                this.setState({word: word});
                const doc = await this.getDoc();
                this.setState({docCurrent: doc});
            } else {
                //TODO: Log that the word does not exist.
            }
        }
    }

    onKeyDownDescription = async(event) => {

    }

    handleChange(event){

        switch(event.target.id){
            case "word" :
                let word = event.target.value;
                this.setState({word: word});
                 break;
            case "definition" :
                this.setState({definition: event.target.value});
                break;
            case "usage" :
                this.setState({usage: event.target.value});
                break;
            case "pronunciation" :
                this.setState({pronunciation: event.target.value});
                break;
            case "selectedImageFile" :
                break;
            case "selectedAudioFile" :
                break;
            case "partOfSpeech":
                this.setState({partOfSpeech: event.target.value});
                break;
            case "etymology":
                this.setState({etymology: event.target.value});
            default:
        }
    }

    uploadToStorage = async(imageURL) => {
        this.getFileBlob(imageURL, blob =>{
            firebase.storage().ref().put(blob).then(function(snapshot) {
            })
        });
    }
   
    getFileBlob = function (url, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function(e) {
          if (this.status === 200) {
            cb = this.response;
            // myBlob is now the blob that the object URL pointed to.
          }
        };
        xhr.send();
    }

    uploadFile = async (file,fileType) => {
        let storageRef = firebase.storage().ref();
        let lastIndexOf = file.name.lastIndexOf(".");
        let fileName = file.name.substring(0,lastIndexOf) + "_" + Math.random().toString().substring(2,8) + file.name.substring(lastIndexOf);
        var uploadTask = storageRef.child('words/' + fileName).put(file);

        uploadTask.on('state_changed', function(snapshot){

        }, function(error) {
            // Handle unsuccessful uploads
        }, function() {
            
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                if(fileType === "image"){
                    imgDownloadUrl = downloadURL;
                }else{
                    audioDownloadUrl = downloadURL;
                }
            });
        });

    }

    selectedImageHandler = event =>{
        this.setState({selectedImageFile: event.target.files[0]});
        this.uploadFile(event.target.files[0],"image");
        
    }
    
    selectedAudioHandler = event =>{
        this.setState({selectedAudioFile: event.target.files[0]});
        this.uploadFile(event.target.files[0],"audio");
    }

    hcPreProcess() {

    }

    hcPostProcess (e) {
        e.persist();    
        let wordElement = document.getElementById("word");
        this.setState({word:wordElement.value.trim()});
        
        this.addWord(localStorage.getItem("senderPaymail"), e.paymentResult.transactionId, 0.01);
    }

    myOnPaymentCallback = async(payment) => {
        this.setState({word:this.state.word.trim()});

        localStorage.setItem('senderPaymail',payment.senderPaymail);
 
        this.addWord(payment.senderPaymail, payment.txid, payment.amount);

        
    }

    addMuggleOnClick = async(e) => {
        localStorage.setItem("muggleEmail",muggleEmail);
        let wordElement = document.getElementById("word");
        this.setState({word:wordElement.value.trim()});
        
        this.addWord(muggleEmail, getUUID(), 0);
    }
 
    start = () => {
        if (this.state.isBlocked) {
          
        } else {
            document.querySelector('#playlist').innerHTML = "";

            Mp3Recorder
                .start()
                .then(() => {
                    this.setState({ isRecording: true });
                    }).catch(function(e){ 
                        
                        let d = document.getElementById("imgRecorder");
                        let txt = document.getElementById("txtRecorder");
                        d.className = "pointer";
                        txt.style.display = "none";

                        alert("Microphone not detected");
                    });
        }
    }

    stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                let storageRef = firebase.storage().ref();
                let filename = this.state.word.replaceAll(" ","-") + "-" + localStorage.getItem("senderPaymail") + "-" + getUUID().substring(1,6) + ".mp3";
                var uploadTask = storageRef.child("audio/" + filename).put(blob);
                uploadTask.on('state_changed', function(snapshot){
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'

                        break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'

                        break;

                        default:
                    }
                }, function(error) {
                    // Handle unsuccessful uploads
                    addErrorToLog(JSON.stringify(error),"audio-stop");
                }, function() {
                    // Handle successful uploads on complete
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        audioDownloadUrl = downloadURL;
                        
                        const file = new File(buffer, 'music.mp3', {
                            type: blob.type,
                            lastModified: Date.now()
                        });
                        const player = new Audio(URL.createObjectURL(file));

                        player.controls = true;
                        
                        document.querySelector('#playlist').appendChild(player);
                    
                    });
                });
          
              }).catch((error) => {
                addErrorToLog(JSON.stringify(error),"audio-stop");
            });
    }

    imageHandler = async (e) => {
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2){
                this.setState({profileImg: reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0]);

        this.uploadFile(e.target.files[0],"image");

                
    }

    onRecorderClick = (e) =>{
        let d = document.getElementById("imgRecorder");
        let txt = document.getElementById("txtRecorder");
        if(d.className === "blink-image pointer"){
            //Stop recording-
            this.stop();

            this.setBlink(d, txt, false);
        } else {
            //Start recording
            this.start();   
            this.setBlink(d, txt, true);
        }
    }

    setBlink = (doc, txt, doesBlink) => {
        if(doesBlink){
            doc.className = "blink-image pointer";
            txt.style.display = "block";
        } else {
            doc.className = "pointer";
            txt.style.display = "none";
        }

        return false;
    }
  
    handleShow = () => {
        this.setState({show:!this.state.show});
        return false;
    }

    handleClose = () => {
        document.getElementById("connectButton").innerHTML = "&lt; 1&cent; to Add Word";
        
        this.setState({show:!this.state.show});
    }

    handleCloseValidationModal = () => {
        if(document.getElementById("connectButton") !== undefined && document.getElementById("connectButton") !== null) {
            document.getElementById("connectButton").innerHTML = "&lt; 1&cent; to Add Word";
        }
        
        this.setState({showValidationModal:!this.state.showValidationModal});
    }

    handleCloseEmailValidation = () => {
        document.getElementById("btnSend").innerHTML = "SUBMIT";
        this.setState({showEmailValidationModal: false});
    }
    setPaymail = (paymail) => {
        transfer.to = paymail;
      }
  
    supportOnClick = (e) => {
        this.props.history.push({pathname: "/Support"});
        e.preventDefault();
    }

    render() {
        const amount = this.state.amount;
        const currency = this.state.currency;
        const toPaymail = this.state.toPaymail;

        if(!isMuggleMode){
            var mbElement = localStorage.getItem("hcAuthToken") === null ? (
                <div id="moneybutton">
                    <MoneyButton
                        to={toPaymail}
                        amount={amount}
                        currency={currency}
                        onPayment={this.myOnPaymentCallback.bind(payment)}
                        label="to Add a Word"
                        buttonId="SLictionary"
                        buttonData="{app: SLictionary}addWord"
                        //opReturn="SLic"
                    />
                </div>
            ) : 
            (
                <>
                <div>&nbsp;</div>
                <HcButton 
                    caption={"ADDWORD_PAGE"}
                    captionProcessing={"PROCESSING..."}
                    captionCompleted={"PROCESSING..."} 
                    paymentDescription={"SLictionary Addword"}
                    checkBalance={true}
                    amount={0.0096}
                    currency="USD"
                    height="50px"
                    left= "-13px"
                    top= "-10px"
                    borderRadius="30px"
                    width="220px"
                    paddingLeft="10px"
                    postProcess={this.hcPostProcess}
                    preProcess={this.hcPreProcess}
                />
                </>    
            );
        } else {
            if(isSmithingDiscount){
                var mbElement = (
                    <>
                    <div>&nbsp;</div>
                    <table><tbody>
                        <tr>
                    <td><label style={{marginRight: "20px"}}>Discount Code:</label></td>
                    <td>
                        <input id="txtDiscountCode" type="text" 
                            style={{borderRadius: "10px", paddingTop: "5px", paddingLeft: "10px",
                                    paddingBottom: "5px"}}
                        />
                    </td>
                    </tr>
                    </tbody></table>
                    <div>&nbsp;</div>
                    <Button id="showDiscountCodeModal" variant="primary" onClick={this.showDiscountCodeModalOnClick}
                        style={{borderRadius: "25px", width: "150px"}}
                    >
                        Add Word
                    </Button>

                    </>
                );
            } else {
                var mbElement = (
                    <>
                    <Button id="addMuggle" variant="primary" onClick={this.addMuggleOnClick}>
                        Add Word
                    </Button>
                    </>
                );
            }
        }

        if(!isMuggleMode && !isLoggedIn ){
            var mbElement = (
                <>
                <div>&nbsp;</div>
                <HcButton 
                    caption={"ADDWORD_PAGE"}
                    captionProcessing={"PROCESSING..."}
                    captionCompleted={"PROCESSING..."} 
                    paymentDescription={"SLictionary Addword"}
                    checkBalance={true}
                    amount={0.0096}
                    currency="USD"
                    height="50px"
                    left= "-13px"
                    top= "-10px"
                    borderRadius="30px"
                    width="220px"
                    paddingLeft="10px"
                    postProcess={this.hcPostProcess}
                    preProcess={this.hcPreProcess}
                />
                </>    
            );

        }

    return (

      <div id="parentContainer" className="content" style={{color:"white", backgroundColor: "transparent"}}>
            <form style={{width:"75%"}}>
                
                <h3>
                    <span style={{color:"yellow"}}>{config.app.verbiage.smithingPage.addAword}.&nbsp;</span>
                    <span style={{color:"lightgreen"}}>{config.app.verbiage.smithingPage.beAwordsmith}.</span>
                     </h3>

                <div className="form-group" style={{width:"50%"}}>
                    <label>{config.app.verbiage.smithingPage.word}</label><label style={{color:"red"}}>*</label>
                    
                    <table style={{width:"200%",border:"0px", tableLayout: "fixed"}}>
                        <tbody>
                        <tr style={{height:"50px", width:"25%"}}>
                            <td style={{width:"40%", verticalAlign:"top"}}>
                                <input type="text" id="word" value={this.state.word}  
                                dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                                onBlur={this.onfocusout}
                                onChange={this.handleChange} 
                                className="form-control"
                                required/>
                                <div className="valid-feedback">Valid.</div>
                                <div className="invalid-feedback">Please fill out this field.</div>
                            </td>

                            <td style={{paddingLeft:"20px", verticalAlign:"top", width: "15%"}}>
                                <span style={{width:"100px"}}>
                                    <img id="imgRecorder" className="pointer" alt=""
                                        style={{width:"60px",display:"inline", marginTop:"-12px"}} 
                                        src={recorderIcon}
                                        title="Record Your Pronunciation"
                                        onClick={this.onRecorderClick} />
                                    
                                </span>

                                <div id="txtRecorder" style={{display:"none",fontSize:"10pt"}}>Click to stop recording</div>
                            </td>
                            <td style={{verticalAlign:"top", width: "50%"}}>
                                <div id="playlist" style={{display:"inline"}}></div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="form-check" style={{fontSize:"12pt"}}>     
                <label className="chklabel">
                    <input type="checkbox" id="childproof" 
                        onClick={this.handleChange} value="false"/>{config.app.verbiage.smithingPage.hideFromchildren}                    
                </label>
                </div>
                <div className="form-check" style={{fontSize:"12pt"}}>      
                <label className="chklabel">
                    <input type="checkbox" id="slang"  
                        onClick={this.handleChange} value="false"/>{config.app.verbiage.smithingPage.slang}
                </label>
                </div>

                <div className="form-group">

                </div> 

				<div className="container">
                    <span>
					<label>{config.app.verbiage.smithingPage.uploadImage}</label>
                        <label className="image-upload" htmlFor="input" 
                            style={{alignContent:"left",fontSize:"12pt",color:"turquoise"}}
                            >
                            <i className="material-icons" style={{color:"turquoise"}}>add_photo_alternate</i>
                           {config.app.verbiage.smithingPage.fromComputer}
					    </label>
                    </span>
					<div className="img-holder">
                        <a href="#" onClick={this.imgUploadClick}>
                        <img
                            style={{maxWidth:"100%", maxHeight:"100%", borderRadius:"5px"}} 
                            src={this.state.profileImg} alt="" id="img" className="" 
                        />
                        </a>
					</div>
					<input type="file" accept="image/*" name="image-upload" id="input" onChange={this.imageHandler}/>
				</div>
               <div>&nbsp;</div>
                <div className="form-group" style={{width:"50%"}}>    
                <label id="lblPartOfSpeech">{config.app.verbiage.smithingPage.partOfspeech}</label><label id="lblRedPartOfSpeech" style={{color:"red"}}>*</label>
                <select className="form-control" id="partOfSpeech" onChange={this.handleChange} required>
                    <option></option>
                    <option>abbreviation</option>
                    <option>adjective</option>
                    <option>adverb</option>
                    <option>conjunction</option>
                    <option>interjection</option>
                    <option>noun</option>
                    <option>person</option>
                    <option>phrase</option>
                    <option>preposition</option>
                    <option>pronoun</option>
                    <option>verb</option>
                </select>
                </div>

                <div className="form-group"  style={{width:"50%"}}>
                    <label>{config.app.verbiage.smithingPage.pronunciation}</label>
                    <input type="text" id="pronunciation" value={this.state.pronunciation} onChange={this.handleChange} className="form-control" placeholder="Type a phonetic pronunciation" />
                </div>

                <div className="form-group">
                    <label id="lblDefinition">{config.app.verbiage.smithingPage.definition}</label><label id="lblRedDefinition" style={{color:"red"}}>*</label>
                    <textarea id="definition" className="form-control" 
                        dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                        cols="20" rows="7"
                        value={this.state.defintion} onChange={this.handleChange} 
                        placeholder="Type your definition here" required 
                        onKeyDown={this.onKeyDownDescription}
                    />
                </div>

                <div className="form-group">
                    <label id="lblUsage">{config.app.verbiage.smithingPage.exampleSentence}</label><label id="lblRedUsage" style={{color:"red"}}>*</label>
                    <textarea id="usage" className="form-control" 
                        dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                        cols="20" rows="4"
                        value={this.state.usage} onChange={this.handleChange} 
                        placeholder="Type an example sentence" required />
                        
                </div>

                <div className="form-group">
                    <label id="lblEtymology">{config.app.verbiage.smithingPage.etymology}</label><label id="lblRedUsage" style={{color:"red"}}></label>
                    <textarea id="etymology" className="form-control" 
                        dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                        cols="20" rows="4"
                        value={this.state.etymology} onChange={this.handleChange} 
                        placeholder="Enter the etymology of the word." />
                        
                </div>
                <div>

                </div>

                <div className="form-group">
                    </div>

            </form>
            {mbElement}
            <div>
            <Modal show={this.state.show} backdrop="static" onHide={this.handleClose} >
                <Modal.Header closeButton style={{color:"white",backgroundColor:"red"}}>
                    <Modal.Title style={{color:"white",backgroundColor:"red"}}>Submission Error</Modal.Title>
                </Modal.Header> 
                <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modalFormError" style={{color:"white",backgroundColor:"#202020"}}>
                        <div>
                            <div>
                                <table>
                                    <tr>
                                        <td>
                                            <img class="center" src={redX} style={{width:"100px"}} alt=""/>
                                        </td>
                                        <td style={{fontSize: "14pt", padding: "2px"}}>
                                        An error was found during the processing of your definition.
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div><span>&nbsp;</span></div>
                            <div><span>Please check your form entries and try again.</span></div>
                            <div><span>&nbsp;</span></div>
                            <div><span>If problems persist, enter a support request by clicking the button.</span></div>
                            <div><span>&nbsp;</span></div>
                            <div className="text-center"><Button variant="warning" 
                                    style={{borderRadius: "25px", fontWeight: "bold"}}
                                    onClick={this.supportOnClick}>Enter Support Ticket</Button></div>
                            <div><span>&nbsp;</span></div>
                            <div className="text-center">
                                <Button 
                                    id="txtClose"
                                    variant="outline-light" 
                                    onClick={this.handleClose}>
                                        CLOSE
                                    </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            </div>
            <div>
                {/* {getValidationModal(this.state.showValidationModal, this.handleCloseValidationModal)} */}
            <Modal show={this.state.showValidationModal} backdrop="static" onHide={this.handleCloseValidationModal} >
                <Modal.Header closeButton style={{color:"black",backgroundColor:"yellow"}}>
                    <Modal.Title style={{color:"black",backgroundColor:"yellow"}}>Missing Fields</Modal.Title>
                </Modal.Header> 
                <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modalValidation" style={{color:"white",backgroundColor:"#202020"}}>
                        <div>
                            <div>
                                <table>
                                    <tr>
                                        <td>
                                            <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                        </td>
                                        <td style={{fontSize: "14pt", padding: "2px"}}>
                                        You are missing one or more required fields.<br/>
                                        Required fields are:<br/>
                                        <ul>
                                            <li>Part of Speech</li>
                                            <li>Definition</li>
                                            <li>Example Sentence</li>
                                        </ul> 
                                        
                                        Enter values for the required fields and resubmit the form.
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <br/>
                            <div className="text-center">
                                <Button 
                                    id="txtClose"
                                    variant="outline-light" 
                                    onClick={this.handleCloseValidationModal}>
                                        OK
                                    </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            </div>

            <div>
                <Modal show={this.state.showEmailValidationModal} backdrop="static" 
                    onHide={this.handleCloseEmailValidation}>
                <Modal.Header closeButton style={{color:"white",backgroundColor:"black"}}>
                    <Modal.Title style={{color:"white",backgroundColor:"black"}}>No-Wallet Login</Modal.Title>
                </Modal.Header> 
                <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modalEmailValidation" style={{color:"white",backgroundColor:"#202020"}}>
                        <div>
                            <div>
                                <table>
                                    <tr>
                                        <td colSpan="2" style={{fontSize: "11pt", padding: "2px"}}>
                                        <div id="divEmail">
                                        <span style={{fontSize: "14pt"}}>You are not logged into a wallet provider</span><br/><br/>
                                        If you have a <span style={{color: "#38CB7C"}}>Handcash</span> or 
                                        <span style={{color: "rgb(71, 114, 246)"}}> Moneybutton</span> wallet, log in
                                        using the link in the upper right part of the page.<br/><br/>
                                        If you do not have a wallet, enter your email
                                        below. We will use this email address to contact you if you win
                                        the Word Bounty.
                                        <span id="validateEmail" style={{marginLeft: "8px", color: "yellow", display: "none"}}></span>
                                        <span><input type="text" id="txtEmail" maxLength="34" 
                                        style={{borderRadius: "5px",width:"67%", padding:"5px", marginLeft: "5px"}} 
                                        placeholder="Email address"></input>
                                            <Button 
                                                id="btnSend" 
                                                variant="outline-light" 
                                                className="text-center"
                                                onClick={this.submitEmail}
                                                style={{width: "125px"}}>
                                                    <i hidden id="iSendSpinner" className="fa fa-spinner fa-spin"></i>
                                                    &nbsp;SUBMIT
                                            </Button>
                                        
                                        </span>
                                        </div>
                                        <div id="divThankYou" className="text-center" style={{display: "none"}}>
                                            <span style={{fontSize: "16pt", color: "white"}}>
                                                Thank you. You may enter your definition<br/></span>
                                        </div>

                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div>&nbsp;</div>
                            <div className="text-center">
                                <Button 
                                    id="txtClose"
                                    variant="outline-light" 
                                    onClick={this.handleCloseEmailValidation}>
                                        CLOSE
                                    </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                </Modal>
            </div>
            <div>
                <Modal show={this.state.showDiscountCodeModal} backdrop="static" 
                    onHide={this.handleCloseDiscountCode}>
                <Modal.Header closeButton style={{color:"white",backgroundColor:"black"}}>
                    <Modal.Title style={{color:"white",backgroundColor:"black"}}>Email Address</Modal.Title>
                </Modal.Header> 
                <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modalDiscountCodeValidation" style={{color:"white",backgroundColor:"#202020"}}>
                        <div>
                            <div>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td colSpan="2" style={{fontSize: "11pt", padding: "2px"}}>
                                        <div id="divEmailDiscountCode">
                                            Enter your email address so we may contact you when you earn 
                                            money for your definition.<br/><br/>
                                        <span id="validateEmailDiscountCode" style={{marginLeft: "8px", color: "yellow", display: "none"}}></span>
                                        <br/>
                                        <span>
                                            <input type="text" id="txtEmailDiscountCode" maxLength="34" 
                                                style={{borderRadius: "5px",width:"67%", padding:"5px", 
                                                marginLeft: "5px"}} 
                                                placeholder="Email address" 
                                                readonly/>
                                            <Button 
                                                id="btnSendDiscountCode" 
                                                variant="outline-light" 
                                                className="text-center"
                                                onClick={this.submitEmailDiscountCode}
                                                style={{width: "125px"}}>
                                                    <i hidden id="iSendSpinner" className="fa fa-spinner fa-spin"></i>
                                                    &nbsp;SUBMIT
                                            </Button>
                                        
                                        </span>
                                        </div>
                                        <div id="divThankYouDiscountCode" className="text-center" style={{display: "none"}}>
                                            <span style={{fontSize: "16pt", color: "white"}}>
                                                Thank you. You may enter your definition<br/></span>
                                        </div>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>&nbsp;</div>
                            <div className="text-center">
                                <Button 
                                    id="txtClose"
                                    variant="outline-light" 
                                    onClick={this.handleCloseDiscountCode}>
                                        CLOSE
                                    </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                </Modal>
            </div>
    </div>
    )};
  }

export default CreateWord;