/* eslint-disable no-unused-vars */
import axios from 'axios';
import {encodeURL, decodeURL} from '../../mods/slictionary-util-mod';
const config = require('../../config/config');

const getWordBaronPaymail = async(componentId) => {
    let wordBaronPaymail = await axios.get(`${config.url.wordBountyService}/wordBaronPayMail/${componentId}`);
    
    return wordBaronPaymail;    
}

const getWordBountyCount = async() => {
    let bountyCount = await axios.get(`${config.url.wordBountyService}/count`);
    
    return bountyCount.data.count;    

}

const addBounty = async(
    bounty,
    exchangeRate,
    txId,
    moneyButtonId,
    submitterPaymail,
    word
) => {
    word = encodeURL(word);
    let addBountyJson = {
        bountyUSD: bounty,
        exchangeRate: exchangeRate,
        txId: txId,
        type: "word-bounty",
        moneyButtonId: moneyButtonId,
        submitterPaymail: submitterPaymail,
        word: word
    }
    let newBountyId = await axios.post(`${config.url.wordBountyService}`,addBountyJson)

    return newBountyId;
}

const getWordBaron = async(word, status) => {
    let wordBaron = await axios.get(`${config.url.wordBountyService}/wordbaron/${word}/${status}`);
    
    return wordBaron.data;    
}

const getLastestAuction = async() => {
    let auction = await axios.get(`${config.url.wordBountyService}/auction/latest`);
    
    return auction.data;  
}

const getAuctionNominations = async() => {
    let nominations = await axios.get(`${config.url.wordBountyService}/auction/nominations`);

    return nominations.data;
}

const putSLebrityAmount = async(slebrityDocId, slebrityAmount, slebrityPaymail, slebrityWord, slebrityName) => {
    try{
        let snapshot = await axios.put(`${config.url.wordBountyService}/auction/nomination/amount`,
            {
                "slebrityDocId": slebrityDocId, 
                "slebrityAmount": slebrityAmount,
                "slebrityPaymail": slebrityPaymail,
            });

        return true;
    } catch (error) {
        return false;
    }
}

const postSLebrity = async(slebrityName, slebrityWord, slebrityAmount, paymail) => {
    try{
        let snapshot = await axios.post(`${config.url.wordBountyService}/auction/nominations`,
            {
                "slebrityName": slebrityName, 
                "slebrityWord": slebrityWord, 
                "slebrityAmount": slebrityAmount,
                "slebrityPaymail": paymail
            });

        return true;
    } catch (error) {
        return false;
    }
}

const putSlebrityMaxBid = async(slebrityDocId, maxBid) => {
    try{
        let snapshot = await axios.put(`${config.url.wordBountyService}/auction/maxBid`,
            {"slebrityDocId": slebrityDocId,"maxBid": maxBid});

        return true;
    } catch (error) {
        return false;
    }
}

const addBid = async(
        newBid,
        newPaymail,
        maxB,
        enteredBid,
        word,
        wordBountyAuctionId
    ) => {

        let json = {
                bid: newBid,
                bidderPaymail: newPaymail,
                maxBid: maxB,
                enteredBid: enteredBid,
                word: word,
                wordBountyAuctionId: wordBountyAuctionId 
            }

        let addBid = await axios.post(`${config.url.wordBountyService}/auction/bid`, json);
        
        return addBid.data;
}

export { getWordBaronPaymail, getWordBountyCount, addBounty, getWordBaron, getLastestAuction, addBid, 
    getAuctionNominations, putSLebrityAmount, postSLebrity, putSlebrityMaxBid};