import React from 'react';
import BaseComponent from '../components/Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import pittsPic from '../assets/Pitts-photo.PNG'
import difelicePic from '../assets/DiFelice-photo.PNG'

class Mission extends BaseComponent {

//   constructor(props) {
//     super(props);
//   }

  render() {

    return (

      <div id="parentContainer" className="content" style={{backgroundColor: "transparent"}}>
          <h2 className="howItWorksTitle">About</h2>
          <div className="col-md-8 col-md-offset-3 text-center" style={{ 
                padding:'5px',
                borderRadius:'15px',
                width:'100%',
                color:'white',
                backgroundColor: "transparent"}}>
            <h3 style={{alignContent:"center", color: "lightgoldenrodyellow"}}>SLictionary is a product of SLCorp.</h3>
            <p>
            <table style={{width:"100%", padding: "10px"}}>
              <tr>
              <td style={{verticalAlign:"top",paddingRight:"15px"}}>
                <img src={pittsPic} style={{width: "170px", height: "306px"}}
                  alt=""/>
                <h3 style={{color: "lightgoldenrodyellow"}}>John Pitts</h3>
                <p className="p-about" style={{paddingBottom: "10px"}}>John Pitts started having fun with words in his 20s living in an apartment with 2 surfer-dude roomates and an unabridged dictionary.  
                </p>
                <p  className="p-about" style={{paddingBottom: "10px"}}>On Friday nights this group would page thru the dictionary looking for the ideal "word of the weekend."  
                </p>
                <p  className="p-about" style={{paddingBottom: "10px"}}>The game from thence-on was to use that word as much as possible during social situations about town.  
                Breaking out the word-of-the-weekend in a crowded room within earshot of the other participants always brought a knowing wink from the other side of the room.  
                He noticed the dictionary could be a lot simpler and more efficient using web technology but never quite saw any new online dictionaries use his ideas.  
                Furthermore, He couldn't see how his ideas would make any money since his last name wasn't Merriam or Webster.  
                </p>
                <p  className="p-about" style={{paddingBottom: "10px"}}>When BSV arrived he knew he had enough differentiating factors to make a go at making the best dictionary in the world-- one sourced by community and improved using money.  After watching the Wikipedia founder on stage in London, he knew what his mission was:  Attack from the flank-- words first-- proof of concept-- encyclopedia next.  John lives on an all-natural horse, chicken and hay farm in Furlong, Pennsylvania, codes iOS/Swift, and is devoting the rest of his life to BitCoin (SV) community in any way he can help.
                </p>
              </td>
              <td style={{width:"50%",verticalAlign:"top"}}>
                <img style={{width:"273px", height:"306px"}} src={difelicePic} alt=""/>
                <h3 style={{color: "lightgoldenrodyellow"}}>John DiFelice</h3> 
                <p className="p-about" >
                John DiFelice taught himself how to write programs when he was eleven years-old. His first 
                application was about words and languages, but not English. It was an app that he used to 
                quiz himself in Latin conjugation and vocabulary. Majoris Geekus.
                </p>
                <p className='p-about'>
                At Villanova University, John studied Chemical Engineering, eventually persuing a Ph.D. at
                the University of Illinois at Chicago. In 1998 he left his Ph.D. to work with internet
                technology. He never thought he'd live to see such an exciting time in IT until he learned
                about the BSV blockchain.
                </p>
            </td>
            </tr></table>
          </p>
          </div>
      </div>
    );
  }
}

export default Mission;