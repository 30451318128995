/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./TokenSlider.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import {getImgAndAudioUrls, getTokenDetails, transferToken, redeemToken} from '../../services/token/TokenService';
import {getsubmitterPaymailAndWalletAddress} from '../../services/user/UserService';
import { Table } from "react-bootstrap";
import {Spinner} from 'spin.js';
import 'spin.js/spin.css';
import redeemProcessing from './../../assets/RedeemProcessing.jpg';
import greenCheck from '../../assets/greenCheckmark.png';
import redX from '../../assets/red-x.png';
import arrowDown from '../../assets/arrow-right-gray-solid.png';
import arrowUp from '../../assets/arrow-left-gray-solid.png';

const {getTotalDefinitionEarnings} = require('../../services/vote/VoteService');
const { getWalletInfo } = require("../../mods/slictionary-user-mod");
const config = require("../../config/config");

var wordAnnualEarnings;

var ticker;
var tokenId;
var bsvRate;
var feeAmountUSD;
var substrateAmountUSD;
var totalDefinitionEarnings;
var tokenWord;
var definitionId;
var walletAddress;
var transferFromPrivateKey;
var transferToPrivateKey; 
var hasZeroTokens;
var modalTitle = "Redeem your SLicDef NFT";

const axios = require("axios");

var opts = {
  lines: 13, // The number of lines to draw
  length: 38, // The length of each line
  width: 17, // The line thickness
  radius: 45, // The radius of the inner circle
  scale: 1, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  speed: 1, // Rounds per second
  rotate: 0, // The rotation offset
  animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: '#ffffff', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  top: '50%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: '0 0 1px transparent', // Box-shadow for the lines
  zIndex: 2000000000, // The z-index (defaults to 2e9)
  className: 'spinner', // The CSS class to assign to the spinner
  position: 'absolute', // Element positioning
};

var spinner = new Spinner(opts);

export default class TokenSlider extends Component {

    constructor(props) {
        super(props);
        this.postChange = this.postChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.transfer = this.transfer.bind(this);
        this.redeem = this.redeem.bind(this);

        this.state = {
            order:0,
            imgUrl:"",
            pronunciation:"",
            definition:"",
            rows: [],
            audioSrc: "",
            pronunciations: [],
            isOpen: false,
            show: false,
            showTransfer: false,
            showRedeem: false,
            setShow: false,
            imgSrc:"",
            currentSlide:"",
            loaded: false
          };
          
          this.handleClose = this.handleClose.bind(this);
          this.handleShow = this.handleShow.bind(this);
          this.handleTransferClose = this.handleTransferClose.bind(this);
          this.handleTransferShow = this.handleTransferShow.bind(this);
          this.handleRedeemClose = this.handleRedeemClose.bind(this);
          this.handleRedeemShow = this.handleRedeemShow.bind(this);
          this.transferClick = this.transferClick.bind(this);
          this.redeemClick = this.redeemClick.bind(this);
          this.isRedeemed = this.isRedeemed.bind(this);
          this.isTransferred = this.isTransferred.bind(this);
          this.prevClick = this.prevClick.bind(this);
          this.nextClick = this.nextClick.bind(this);

          
          this.cool();
    }

    async componentDidMount(word) {
        if(hasZeroTokens){
            alert(document.getElementById("tokenCount"))
        }
    }

    transferClick = async(event) => {
        try{
            var target = document.getElementById('modalTransfer');
            spinner.spin(target);
            var username = document.getElementById("txtTransferToName").value;

            let walletInfo = await getWalletInfo(username + "@moneybutton.com");
            let recipientWalletInfo = await getsubmitterPaymailAndWalletAddress(username + "@moneybutton.com");
            let senderWalletInfo = await getsubmitterPaymailAndWalletAddress(localStorage["senderPaymail"]);
            let result = await getTokenDetails(tokenId, ticker);
            let transferResults = await transferToken(
                definitionId,
                tokenWord,
                result.issuerPrivateKey, 
                senderWalletInfo.walletPrivateKey,//.ownerPrivateKey, 
                recipientWalletInfo.walletAddress, 
                result.issueTxId
            );          

            alert(`Token transfer successful`);
        } catch (error) {
            console.log(error);
            alert(`Token transfer failed`);
        }

        spinner.stop();
        this.setState({showTransfer:!this.state.showTransfer});

        return false;

    }

    redeemClick = async(event) => {
        try{
            
            var target = document.getElementById('modalRedeem');
            spinner.spin(target);
            let result = await getTokenDetails(tokenId, ticker);
            let walletInfo = await getsubmitterPaymailAndWalletAddress(localStorage["senderPaymail"]);
            let redeemResults = await redeemToken(
                definitionId,
                tokenWord,
                result.issuerPrivateKey,
                walletInfo.slicWallet.privateKey, 
                result.issueTxId
            );
            //check for error.
            
            let sessionRedeem = sessionStorage.getItem("redeemedDefIds");
            sessionRedeem += "," + definitionId;
            sessionStorage.setItem("redeemedDefIds",sessionRedeem);
        
            var target = document.getElementById('modalRedeemSuccess');
            var successSpan = document.getElementById("successSpan");
        
            target.style.display = "inline";
            let txt = `Token REDEEM has been successfully submitted. It should take 10 minutes to mine the transaction.`;
            successSpan.textContent = txt;
            let divTokenDetails = document.getElementById("tblTokenDetails");
            divTokenDetails.style.display = "none";
            document.getElementById("btnSend").style.display = "none";
        } catch (error){
            target.style.display = "none";
            var target = document.getElementById('modalRedeemFailed');
            var failedSpan = document.getElementById("failedSpan");
            target.style.display = "inline";
            target.style.backgroundColor ="red";
            let txt = `Token REDEEM failed. Report the failure to slictionaryllc@gmail.com.\nInclude the definition ID from the previous screen.`;
            failedSpan.textContent = txt;
        }
        spinner.stop();
    }

    setModalState(showModal) {
        this.setState({
            showModal: showModal
        });
    }

    beforeChange = event =>{
      this.slider.slickGoTo(0);
    }

    postChange = eventCurrentSlide => {

    }

    handleShow = event => {
      this.setState({imgSrc:event.target.src});
      this.setState({show:!this.state.show});

      return false;
    }

    handleClose = () => {
        this.setState({show:!this.state.show});
    }

    handleTransferShow = (event) => {
        transferToPrivateKey = "";
                //localStorage.setItem('walletAddress', walletAddress);

        tokenId = event.target.id.split("|")[1];
        ticker = event.target.id.split("|")[2];
        tokenWord = this.getTokenWordName(ticker);//ticker.split("-")[1];
        definitionId = this.getTokenDefinitionId(ticker);//ticker.split("-")[2];
 
        this.setState({showTransfer:!this.state.showTransfer});
 
    }
  
    handleTransferClose = () => {
        this.setState({showTransfer:!this.state.showTransfer});
    }

    handleRedeemShow = async(event) => {
        if(Number(sessionStorage["satoshiBalance"]) === 0){
            alert("You must fund your wallet before performing a Redeem.\nClick the RECEIVE button to get your wallet address.");
            return false;
        }
        
        var eleRedeem = document.getElementById(event.target.id);
        eleRedeem.innerText = "LOADING...";
        tokenId = event.target.id.split("|")[1];
        ticker = event.target.id.split("|")[2];
        tokenWord = this.getTokenWordName(ticker);//ticker.split("-")[1];
        definitionId = this.getTokenDefinitionId(ticker);//ticker.split("-")[2];

        try{
            totalDefinitionEarnings = await getTotalDefinitionEarnings(localStorage["senderPaymail"], definitionId);
            console.log(`totalDefinitionEarnings: ${totalDefinitionEarnings}`);
            let resultTokenDetails = await getTokenDetails(tokenId, ticker);

            const redeemJson = {issueTxId:"",tokenId:"",symbol:""};
            redeemJson.issueTxId = resultTokenDetails.issueTxId;
            redeemJson.tokenId = tokenId;
            redeemJson.symbol = ticker;
            const result = await axios.post(`${config.url.tokenService}/stas/contract/issue/redeem/detail`, redeemJson);
            substrateAmountUSD = result.data.transactions.substrateAmountUSD;
            feeAmountUSD = result.data.transactions.feeAmountUSD;
            bsvRate = result.data.transactions.bsvRate;

            eleRedeem.innerText = "REDEEM";
            this.setState({showRedeem:!this.state.showRedeem});
        } catch (error) {
            navigator.clipboard.writeText(`email:slictionaryllc@gmail.com, definitionId:${definitionId}`);
            alert(`Data could not be found for this token. \nPlease send a description of the problem to slictionaryllc@gmail.com.\n\nThe email address and definitionId will be copied to your clipboard.\nInclude the definitionId in your email`)
        }

    }

    handleRedeemClose = () => {
          this.setState({showRedeem:!this.state.showRedeem});
    }

    isRedeemed = (definitionId) => {
        let bReturn =        
            sessionStorage.getItem("redeemedDefIds") !== null && 
            sessionStorage.getItem("redeemedDefIds").indexOf(definitionId) !== -1;
        return bReturn;

    }
    isTransferred = (definitionId => {
        let bReturn =        
            sessionStorage.getItem("transferDefIds") !== null && 
            sessionStorage.getItem("transferDefIds").indexOf(definitionId) !== -1;
        return bReturn;
    })

    getTokenDefinitionId = (ticker) => {
        const myRe = /(?!.*-)[a-z,0-9]*/
        const myArray = myRe.exec(ticker);
    
        return myArray[0];
    }
    
    getTokenWordName = (ticker) => {
        let start = ticker.indexOf("-") + 1;
        let end = ticker.lastIndexOf("-");
        return ticker.substring(start,end);
    }
    
    getEarningsContent = (wordAnnualEarnings, tokenKey) => {
        let content = [];
        let lifetimeEarnings = 0;
        if(wordAnnualEarnings.data.tokens[tokenKey].earnings !== undefined){
            for(let i=0; i < wordAnnualEarnings.data.tokens[tokenKey].earnings.length; i++){
                lifetimeEarnings += wordAnnualEarnings.data.tokens[tokenKey].earnings[i].satAmount;
            }
            //content.push(<div style={{fontFamily: "Pacifico", color:"cyan", fontSize:"16pt"}}> Lifetime earnings:&nbsp;&nbsp;&nbsp;{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(lifetimeEarnings)} sat</div>)
            content.push(<div key={tokenKey} style={{fontFamily: "Times New Roman", color:"cyan", fontSize:"16pt"}}> Lifetime earnings:&nbsp;&nbsp;&nbsp;{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(lifetimeEarnings)} sat</div>)

            for(let i=0; i < wordAnnualEarnings.data.tokens[tokenKey].earnings.length; i++){
                 content.push(<div>&nbsp;</div>)
                 content.push(<div>{wordAnnualEarnings.data.tokens[tokenKey].earnings[i].year} earnings:&nbsp;&nbsp;&nbsp;
                         {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(wordAnnualEarnings.data.tokens[tokenKey].earnings[i].satAmount)} sat</div>);
            }
        } else {
            content.push(<div key={tokenKey} style={{fontFamily: "Times New Roman", color:"cyan", fontSize:"16pt"}}>No Earnings Data For This Token</div>)
                
        }
    

    return content;
    };

    transfer = async(event) => {
        let arr = event.target.id.split("|");
        let tokenId = arr[1];
        let ticker = arr[2];

        let result = await getTokenDetails(tokenId, ticker);
        alert(`issueTxId: ${result.issueTxId}`);
    }

    redeem = (event) => {
        alert(event.target.id);
    }

    cool = async () => {

        let walletInfo = await getWalletInfo(localStorage.getItem('senderPaymail'));
        walletAddress = walletInfo.walletAddress;
        transferFromPrivateKey = walletInfo.privateKey;

        wordAnnualEarnings = this.props.wordAnnualEarnings;
        var wordAnnualEarningsSorted = require('sort-json-array');
        wordAnnualEarnings.data.tokens = wordAnnualEarningsSorted(wordAnnualEarnings.data.tokens, 'word');
        if(wordAnnualEarnings.data.tokens.length === 0){
            hasZeroTokens = true;
        }
        
        var s;
        let bulbLeft = "";
        
        let loopIndex = 0;
        var earningsData = "";
        var imageUrl = "";
        var audioUrl = "";

        for (let i = 0; i < wordAnnualEarnings.data.tokens.length; i++) {
            var word = wordAnnualEarnings.data.tokens[i].word;
            var definitionId = wordAnnualEarnings.data.tokens[i].definitionId;
            //Find token pic for the definition
            var imgAndAudioJson = await getImgAndAudioUrls(definitionId);
            imageUrl = imgAndAudioJson.imageUrl === undefined ? "" : imgAndAudioJson.imageUrl;  
            audioUrl = imgAndAudioJson.audioUrl === undefined ? "" : imgAndAudioJson.audioUrl;  
            if(imageUrl.length === 0){
                imageUrl = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2Fno%20image.jpg?alt=media&token=f4c3f74a-30c0-4a5e-8f52-67fea1cf13f1";
            }
            
            // Redeem and Transfer button properties
            let redeemButtonStyleProp = {
                width:"150px",
                zIndex:"1",
                color:"#81BEF7",
                display: this.isRedeemed(definitionId) || this.isTransferred(definitionId) ? "none" : "grid",
                border: 0 
            }
            let transferButtonStyleProp = {
                width:"150px",
                zIndex:"1",
                color:"#81BEF7",
                display:"none"//this.isTransferred(definitionId) ? "none" : "inline"
            }
            
            let className = "center";
            s = this.state.rows.concat (
                <div key={i} style={{border:'0.5px solid gray'}} >
                <Container>
                    <Row>
                        <Col>
                            <div style={{border:'0.5px solid gray',borderRadius: '15px'}}>
                            
                            <h2 style={{textAlign: "center"}}>{word}</h2>

                            <img className={className}
                                style={{
                                     whiteSpace:"nowrap"
                                    ,position:"relative"
                                    ,cursor: "pointer"
                                    ,textAlign: "center"
                                }}
                                src={this.isRedeemed(definitionId) ? redeemProcessing : imageUrl}
                                onClick={this.handleShow}
                                alt=""
                            />
                            <button 
                                id={"transfer|" + wordAnnualEarnings.data.tokens[i].tokenId + "|" + wordAnnualEarnings.data.tokens[i].ticker} 
                                className="btn btn-dark center" 
                                style={transferButtonStyleProp} 
                                onClick={this.handleTransferShow}
                                >
                                SELL/SEND
                            </button>
                            <button 
                                id={"redeem|" + wordAnnualEarnings.data.tokens[i].tokenId + "|" + wordAnnualEarnings.data.tokens[i].ticker} 
                                className="btn btn-dark center" 
                                style={redeemButtonStyleProp}
                                onClick={this.handleRedeemShow}
                                >
                                REDEEM
                            </button>

                            </div>
                        </Col>
                        </Row>
                <Row>
                <Col>

                    {this.getEarningsContent(wordAnnualEarnings, i)}
                </Col></Row>
                <Row><Col>&nbsp;</Col></Row>
                </Container>
            </div>
            
            );
            
            this.setState({rows: s});

      }
        this.setState({rows: s});
      }

    toggleModal = () => {
      alert(this.state.isOpen);
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
    
    prevClick = (e) => {
        this.slider.slickPrev();
        
        e.preventDefault();
    }

    nextClick = (e) => {
        this.slider.slickNext();
        e.preventDefault();
        
    }

    render() {
        const ArrowLeft = () => (
            <a href="">
            <Image
                id="leftArrow"
                alt="Scroll Left"
                title="Scroll Left"
                src={arrowUp}
                onClick={this.prevClick}
            />
            </a>
          );
          const ArrowRight = () => (
            <a href="" >
            <Image
                id="rightArrow"
                alt="Scroll Right"
                title="Scroll Right"
                src={arrowDown}
                onClick={this.nextClick}
            />
            </a>
          );

          const settings = {
            arrows: true,
            prevArrow: <ArrowLeft />,
            nextArrow: <ArrowRight />,
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "200px",
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 100,
            beforeChange:(current,next) => {
                if(this.slider){
                  this.slider.slickGoTo(0);
                }
              },
            afterChange: this.postChange
            ,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1
                  }
                },
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]
        };

    return (
        <div style={{"width": "300px"}}>
            {/* TRANSFER Modal  */}
            <Modal width="50%" height="50%" show={this.state.showTransfer} onHide={this.handleTransferClose} style={{border:"1px","borderStyle" : "solid"}} size="lg">
            <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Transfer Token</Modal.Title>
            </Modal.Header> 
            <Modal.Body className="center" style={{backgroundColor:"black"}}>
            <div id="modalTransfer" style={{color:"white",backgroundColor:"#202020"}}>
            <div>
                <div>
                    <div> 
                        <Table className="">
                            <tr>
                                <th>Token Word:</th>
                                <td>{tokenWord}</td>
                            </tr>
                            {/* <tr>
                                <th>TokenId:</th>
                                <td>{tokenId}</td>
                            </tr> */}
                            <tr>
                                <th>DefinitionId:</th>
                                <td>{definitionId}</td>
                            </tr>
                        </Table>
                    </div>
                    <div><span><input type="text" id="txtTransferToName" maxlength="100" style={{borderRadius: "5px",width:"50%", height:"50px", padding:"5px"}} placeholder="Enter recipient username"></input></span></div>
                    <div><span>&nbsp;</span></div>
                    <div><span>
                            <button 
                                id="btnSend" 
                                type="button" 
                                onClick={this.transferClick} className="btn btn-primary" 
                                style={{width:"20%",height:"50px", backgroundColor:"black", 
                                borderRadius: "5px",borderColor:"white"}}>
                                    <i hidden id="iSendSpinner" className="fa fa-spinner fa-spin"></i>
                                    &nbsp;Transfer
                            </button>
                        </span>
                    </div>
                </div>
                </div>
            </div>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:"black"}}>
                <Button variant="secondary" onClick={this.handleTransferClose}>
                Close
                </Button>
                </Modal.Footer>
            </Modal>

            {/* REEDEM Modal */}
            <Modal 
                className="redeemModal"
                width="90%" 
                height="90%" 
                show={this.state.showRedeem} 
                onHide={this.handleClose} 
                style={{border:"1px",borderColor:"white"}} 
                size="lg"
            >
            <Modal.Header 
                closeButton 
                style={{color:"white",backgroundColor:"#202020",border:"1px",borderColor:"white"}}
            >
                <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>{modalTitle}</Modal.Title>
            </Modal.Header> 
            <Modal.Body className="center" style={{backgroundColor:"black"}}>
            <div id="modalRedeemSuccess" style={{color:"white",backgroundColor:"green",display:"none"}}>
                <div>
                    <div><img class="center" src={greenCheck} style={{width:"100px",height:"100px"}}/></div>
                    <div>&nbsp;</div>
                    <div><span id="successSpan"></span></div>
                </div>
            </div> 
            <div id="modalRedeemFailed" style={{color:"white",backgroundColor:"red",display:"none"}}>
                <div>
                <img class="center" src={redX} style={{width:"100px",height:"100px"}}/>
                <div>&nbsp;</div>
                <div><span id="failedSpan"></span></div>
                </div>
            </div> 
            <div id="modalRedeem" style={{color:"white",backgroundColor:"#202020"}}>
            <div>
                <div>
                    <div> 
                        <Table id="tblTokenDetails" className="">
                            <tr>
                                <th>Token Word:</th>
                                <td>{tokenWord}</td>
                            </tr>
                            <tr>
                                <th>Redeemable Token Substrate:</th>
                                <td>${Number(substrateAmountUSD).toFixed(4)}</td>
                            </tr>
                            <tr>
                                <th>Lifetime Definition Cash-Flow:</th>
                                <td>${Number(totalDefinitionEarnings/config.bsv.satoshisPerBsv * bsvRate).toFixed(2)}</td>
                            </tr>

                            <tr>
                                <th>Estimated Transaction Fee:</th>
                                <td>${Number(feeAmountUSD).toFixed(4)}</td>
                            </tr>
                            <tr>
                                <th>BSV Price:</th>
                                <td>${Number(bsvRate).toFixed(2)}/BSV</td>
                            </tr>
                            <tr>
                                <th colSpan={2} style={{color:"red"}}>Note: The definition(s) contained in your SLicDef token will be deleted from SLictionary</th>
                            </tr>
                            <tr>
                                <th>DefinitionId:</th>
                                <td>{definitionId}</td>
                            </tr>
                        </Table>
                    </div>
                    <div><span>
                            <button id="btnSend" type="button" onClick={this.redeemClick} className="btn btn-primary" 
                                style={{width:"20%",height:"50px", backgroundColor:"black", 
                                borderRadius: "5px",borderColor:"white"}}>
                                    <i hidden id="iSendSpinner" className="fa fa-spinner fa-spin"></i>
                                    &nbsp;Redeem
                            </button>
                        </span>
                    </div>
                </div>
                </div>
            </div>
                </Modal.Body>
            <Modal.Footer style={{backgroundColor:"black"}}>
                <Button variant="secondary" onClick={this.handleRedeemClose} 
                    style={{width:"20%",height:"50px", backgroundColor:"black", 
                    borderRadius: "5px",borderColor:"white"}}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Slider ref={slider => (this.slider = slider)} {...settings}>{this.state.rows}</Slider>
      </div>     
    );
  }
}
