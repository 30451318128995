import axios from 'axios';
import {getWordBaronPaymail} from "../word-bounty/WordBountyService";

const {getBSVexchangeRate} = require("../../mods/slictionary-bsv-mod")
const config = require('../../config/config');

const getVoteComponentCounts = async(word) => {
    let snapshot = await axios.get(`${config.url.voteService}/component/counts/${word}`);
    
    return snapshot.data;  
}

const getTotalDefinitionEarnings = async(authorPaymail, definitionId) => {
    let snapshot = await axios.get(`${config.url.voteService}/earnings/definition/${authorPaymail}/${definitionId}`);
    
    return Number(snapshot.data.earnings) ;    
}

const getVoteCount = (user) => {
    let snapshot = axios.get(`${config.url.voteService}/user/count/${user.vendorWalletsPaymailString}`)
        .then(resp => {
            return resp.data.voteCount;
        });

    return snapshot;
}

const getVoteEarnings = (user) => {
    let snapshot = axios.get(`${config.url.voteService}/earnings/${user.vendorWalletsPaymailString}`)
        .then(resp => {
            return Number(resp.data.earnings)
        })
        
    return snapshot;
}

const getVotes = async(word) => {
    let snapshot = await axios.get(`${config.url.voteService}/definition/${word}`);

    return snapshot.data;
}

const addVote = async(id, word, author, voter) => {
    try {
        let exchangeRate = await getBSVexchangeRate();
        let tokenOwner = await getWordBaronPaymail(id);
        let amount = 0.01; //votes cost one cent
        let amountSatoshi = amount / exchangeRate * 100000000; 
        let addVoteJson = {
            Id: id,
            amount: amount,
            amountSatoshi: amountSatoshi,
            exchangeRate: exchangeRate,
            author: author,
            status: "pending",
            txId: "",
            voter: voter,
            word: word,
            tokenOwner: tokenOwner.data,
            expired: false,
            paid: false
        }

        let url = `${config.url.voteService}`;
        const result = await axios({
            method: "post",
            url: url,
            data: addVoteJson
        })
        .then(async function (res) {
            console.log("vote added");
            return res;
        })
        .catch(function (error) {
            console.log(JSON.stringify(error));
            throw error;
        });
        console.log(result);
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export {addVote, getVoteEarnings, getTotalDefinitionEarnings, getVoteCount, getVoteComponentCounts, getVotes};