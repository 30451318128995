import React from 'react';
import {addVote} from "../services/vote/VoteService";
import {canVoteForComponent} from "../mods/slictionary-vote-mod";

const LikeBulb = (props) => {
    let i = 0;

    const likeBulbOnClick = (e) => {
        alert('likebuldonclick');
        var componentIdAndAuthor = e.target.id.split("|");
        var author = componentIdAndAuthor[1]
        
        if(canVoteForComponent(props.arrBulbs, author, props.senderPaymail)){
            e.target.style.color = "yellow";
            e.target.className = "fa fa-lightbulb fa-1x column";

            let id = componentIdAndAuthor[0];
            let author = componentIdAndAuthor[1];
            let voter = props.senderPaymail;
            addVote(id, props.word, author, voter);
        }
    }

    return (
        <>
        <i
            id= {props.componentId + "|" + props.author} 
            onClick={likeBulbOnClick}
            className="fa fa-lightbulb fa-1x column"
            title={props.title}
            style={{display: props.visible ? "" : "none"}}
        ></i>
        </>
    )
}

export default LikeBulb;


// style={{
//     display: "inline-flex",
//     color: "white",
//     verticalAlign: "top",
//     position: "relative",
//     left: "0px",
//     top: "0px",
//     backgroundColor: "transparent",
//     width: "20px"
// }}