import React from 'react';
import BaseComponent from './Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HowItWorks.css';


class HowItWorks extends BaseComponent {

//   constructor(props) {
//     super(props);
    
//   }

  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"white"}}>
          <h2 className="howItWorksTitle">WORD BOUNTY TERMS OF SERVICE</h2>

          <div  style={{ 
                padding:'5px',
                borderRadius:'15px',
                border:'0.5px solid black',
                width:'800px',
                background:'black',
                color:'white'}}>

            <p className="howItWorksText">
We reserve the right to deny or withhold payment for "won" bounties at the end of the 1 week timer for ANY definitons we believe violates the spirit of SLictionary, which is to be a unique, communally-sourced dictionary provider written by creative competitors.  Examples of violating our terms of service would include:  plagiarizing (copy-paste) definitions from other dictionaries, violations of trademarks or copyrights pointed out by the trademark and copyright owners or any other law-breaking activity, pornography, forgetting to press "hide from children" if adult content is utilized, and anything else we deem in harm of our product or company.</p>

            <h3><b>WORD BOUNTY ROLES</b></h3>
            <p className="howItWorksText">A "Seek" is a single lookup from any search bar located on any web page or mobile application, which results in either definition(s) being shown or a visit to our "Lucky You" page.   </p>
            <p className="howItWorksText">A "Seeker" is allowed a single vote for each search swiped of the money button (~ a penny as of Nov 11, 2021) from the home seek page or any other part of the site allowing definitions to be loaded for the knowledge seeker.  This vote accounts for a payment, made on Fridays, of 70% of the SEEK fee.</p>

            <p className="howItWorksText">"WordBaron".  A Word Bounty patron who posts a bounty of $2.00 or more, thus earning the right to own all the definitions created for his bounty after the contest period is over.</p>   
            <p className="howItWorksText">"Word$mith": An artistically and/or intellectually creative person who smiths definitions in SLictionary in order to compete for bounties and long-term fee-rewards.
"(Knowledge) Seeker": A SLictionary participant who both looks up words in the SLictionary to learn or be entertained, but who also votes for favorite definitions (using "LIT" lightbulb clicks) thus paying ONE of the competing Word$miths.</p>

<h3><b>DEFINITION TOKENIZATION (NFTs)</b></h3>
            <p className="howItWorksText">Word Bounty donors (sponsors, WordBarons) who post $2.00 or more for the bounty, will receive a definition STAS nft token in their mySLictionary wallet (accessed by clicking on the Money Button paymail on the upper right of most www.SLictionary.com pages) for EACH definition submitted by a Word$mith who entered the contest.  For example, CoinGeek recieved 24 submissions for the $100 "CONFERENCE" bounty, and thus would expect to see 24 STAS tokens in their wallet soon after the contest is owner, and after the winning Word$mith with the most votes is paid his $100 bounty.</p>
            <h3><b>FEES</b></h3>
            <p className="howItWorksText">SLictionary's Word Bounty fee:
2.99% of the total submitted amount for a bounty will earned by SLictionary the platform and paid instantly upon reciept of the Word Bounty reward.  This fee will be subtracted from the 10% calculation in preps for creating the substrate. </p>
            <p className="howItWorksText">From $2 to $11 a dollar is subtracted from the Word Bounty amount submitted by a WordBaron, in order to "substrate" or "substantiate" the STAS tokens, and SLictionary's fee is subtracted from this amount with the remainder to substantiate or substrate all the STAS tokens.  Think of it like the land price which adds to the cost of erecting a commercial building.  The substrate provides incentive for definition owners to REDEEM definitions which are duds and not earning money.  For any bounty submitted &gt; $11.00, SLictionary will calculate the substrate as 7.00% of the total bounty, and SLictionary's fee will NOT be subtracted from that amount.</p>
            <p className="howItWorksText">Example:  Hussein submits a Word Bounty of $20.00.  SLictionary collects it's fee of 59.8 cents (2.99%) and the substrate for the STAS tokens is totalled at $1.40 which will be distributed equally to each definition written in the form of a token, at the end of the bounty, and issued to the WordBaron's "MySLictionary" STAS-capable wallet.</p>
            <p className="howItWorksText">We'll allow you to burn your bound dictionaries when we're done filling out our community of words-we will be a much-improved SLICKER dictionary.</p>
          
            <h3><b>PAYMENT SCHEDULE</b></h3>
            <p className="howItWorksText">Word Bounties are paid on Fridays, typically, but may be paid later than every Friday should certain distractions or problems occur which prevent our timely payment.  We pay on Fridays as best we can, but we ultimately promise to pay all Word$mith fees and rewards within a reasonable amount of time from when the bounty is won, plus some time for unexpected problems such as site maintenance, affiliate vendor problems, or other delays which may come up from time to time.  We do not envision any non-technical reasons why payments would be delayed longer than a week, and expect in the near term our payments will be FULLY automated on a Friday schedule in the near future; such that only technical problems would result in delayed payments.</p>
            <p className="howItWorksText">A Word Bounty begins upon the submission of the first definition, and the contest period lasts 1 week from that time.</p>
            <p className="howItWorksText">The winning Word Bounty is the submission with the most votes, and ties will divide the Word Bounty into equal shares for payment.</p>

      </div>
      </div>
    );
  }
}

export default HowItWorks;