import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
//import information from "../../assets/information-icon.png";
import information from "../../assets/Information_icon_flat.svg.png";

function SLicTooltip(props) {
  return (
    <>
        <OverlayTrigger 
          key="right"
          placement="right"
          overlay={
            <Tooltip id="tooltip-bio" >
                Nicolas is likely the most enthusiastic entrepreneur in bitcoin, and the founder of Aym.world a market to showcase art and objects made in an artifactory by mages. A graduate of Yale (cognitive science), he's displayed great business intuition regarding tech businesses as Chief Sales Officer at Pillar (a Y Combinator candidate) and also as a part-time analyst at a billion dollar New York City fund AlleyCorp. Since September 2022 he has identified bitcoin as an ecosystem so powerful that he has committed full time as an entrepreneur (much like SLictionary's coFounder) in the sector.
The most unique thing about Ryan is his love of words, and artifactory is just the first of several terms he's coined (figuratively) and will likely coin (literally) on SLictionary's innovative Signatured-INformation-Coins (SINCs-- similar to NFTs). The potential for his definition of "artifactory" will rise with the popularity of his business career, in addition to having the vaunted status as #3 SLebrity auction (in September).
              {/* {props.bio} */}
            </Tooltip>
          }
        >
          <img src={information} style={{width: props.width, verticalAlign:props.verticalAlign}}/>
        </OverlayTrigger>
    </>
  );
}

export default SLicTooltip;
