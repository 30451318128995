import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import React, { useState, useCallback } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import config from '../../config/config';

const LEADERBOARD_DATA_URL = `${config.url.wordBountyService}/auction/leaderboard/data`;
const gridStyle = { 
    marginTop: 10, 
    minHeight: 350,
    border: '1px solid black',
    background: 'black',
    color: 'white'
}
const rowStyle = ({ data }) => {
    return {
        background:'black'
    }
}

const leaderboardColumns = [
    { 
        name: 'winningBid', header: 'Bounty', minWidth: 50, defaultFlex: 2,
            headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }}, 
        render: ({ value }) => `$${Number(value).toFixed(2).toString()}` 
    },
    { name: 'celebrityName', header: 'Legend', minWidth: 50, defaultFlex: 2,headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }}, },
    { name: 'word', header: 'Word', minWidth: 50, defaultFlex: 2, headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }}, },
    // { name: 'charity', header: 'Charity', minWidth: 50, defaultFlex: 2 },
    { 
        name: 'createDatetime', header: 'Auctioned', minWidth: 50, defaultFlex: 2,headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }},
        render: ({ value }) => new Date(value).toDateString() 
    },
    { name: 'wordBaronWinner', header: 'WordBaron Winner', minWidth: 50, defaultFlex: 2,headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }}, }
    // { name: 'wordSmithWinner', header: 'Word$mith Winner', minWidth: 50, defaultFlex: 2 }
];

const loadLeaderboardData = () => {
    return fetch(
      LEADERBOARD_DATA_URL
    ).then(response => response.json());
  };


const SLebrityLeaderboard = () => {
    const leaderboardDataSource = useCallback(loadLeaderboardData, []);
    const [lbColumns] = useState(leaderboardColumns);

    document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
    document.body.style.backgroundAttachment = "fixed";
      
    return (
    <div style={{width:"1100px"}}>
        <div style={{fontSize:"18pt",fontWeight:"bold", color:"white"}}>Highest SLebrity Word Bounties - all time</div>
        <ReactDataGrid
            width = "950px"
            style={gridStyle}
            idProperty="id"
            columns={lbColumns}
            dataSource={leaderboardDataSource}
            theme={"default-dark"}
            rowStyle={rowStyle}
            showCellBorders = "horizontal"
        />
    </div>
  )
}

export default SLebrityLeaderboard;
