import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class ErrorInvalidTransaction extends React.Component {

//   constructor(props) {
//     super(props);
//   }

  componentWillMount(){
    document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
    document.body.style.backgroundAttachment = "fixed";

  }
  
  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"yellow"}}>
          <h1>WHoops!</h1>
          <p>&nbsp;</p>
          <p>&nbsp;</p>

          <img alt="" src="https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/shakespeare-in-pain.jpg?alt=media&token=1feb8bb3-48e2-4f53-a5d8-f36e1aa5f32d"/>
          <h2 style={{color:"yellow"}}>You have an invalid transaction key for this word</h2>
      </div>
    );
  }
}

export default ErrorInvalidTransaction;