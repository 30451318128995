import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './SearchResults.css';
import {UserContext} from './UserContext';
import {addBounty} from '../services/word-bounty/WordBountyService';
import infoIcon from '../assets/information-icon.png';
import Modal from 'react-bootstrap/Modal';
import HcButton from '../components/handcash/HcButton'
import NumericInput from './Common/NumericInput';
import { postAnalyticsPage } from '../services/user/UserService';
import {encodeURL, decodeURL} from "../mods/slictionary-util-mod";

const {getBSVexchangeRate} = require('../mods/slictionary-bsv-mod');
const config = require("../config/config");

var MoneyButton = require('@moneybutton/react-money-button').default;

var payment;
var exchangeRate = 0;
var modalStyleCannotAddToBounty = {display : ""};
var setBountyMessage = "";
var amountG = 0;

class SearchResults extends React.Component  {
    constructor(props) {
        super(props);
        postAnalyticsPage(localStorage.getItem("senderPaymail"),
        window.location.pathname,
        window.navigator.userAgent,
        window.navigator.userAgentData);

        this.addWordOnClick = this.addWordOnClick.bind(this);
        this.myOnPaymentCallback = this.myOnPaymentCallback.bind(this);
        this.sendBountyOnClick = this.sendBountyOnClick.bind(this);
        this.validateBounty = this.validateBounty.bind(this);
        this.getCanAddToWordBounty = this.getCanAddToWordBounty.bind(this);
        this.modalOnHide = this.modalOnHide.bind(this);
        this.numericInputOnChange = this.numericInputOnChange.bind(this);
        this.hcPostProcess = this.hcPostProcess.bind(this);
        this.hcPreProcess = this.hcPreProcess.bind(this);

        let arr = this.props.location.pathname.split("/");
        let word = decodeURL(arr[2]);
        this.state = {
            word: word,
            txid: arr[3],
            order:0,
            imgUrl:"",
            pronunciation:"",
            definition:"",
            bountyData: [],
            paymail:localStorage.getItem("senderPaymail"),
            email:localStorage.getItem("senderPaymail"), //default value
            limitToggle: false,
            canAddToBounty: true,
            amount: 0
        };
    }

    async componentWillMount(){
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
        document.body.style.backgroundAttachment = "fixed";
            let canAdd =  await this.getCanAddToWordBounty(this.word);
        this.setState({"canAddToBounty" : canAdd});
        this.setState({"limitToggle" : true});
    }

    async componentDidMount(word) {
        sessionStorage.setItem("lastTxId",this.state.txid);
        exchangeRate = await getBSVexchangeRate();
    }

    hcPreProcess = (e) => {
        e.persist();
        amountG = Number(document.getElementById("numEnterBounty").value);
        e.amount = amountG;
    }

    hcPostProcess = async (e) => {
        try {
            
            await addBounty(
                amountG, //amount
                Number(exchangeRate), //exchangeRate
                "", //txid
                "", //moneyButtonId
                this.state.paymail, //senderPaymail
                this.state.word
            );
            
            this.props.history.push({pathname:"/confirmation/wordbounty-thank-you/" + encodeURL(this.state.word)});

        } catch (error) {
            console.log(error);
        }
    }

    numericInputOnChange = (event) => {
        amountG = Number(event.target.value);
    }
    
    addWordOnClick = event =>{
        this.props.history.push("/");
        this.props.history.push({
          pathname:"createword/" + encodeURL(this.state.word) + "/@/" + this.state.txid
        });
        console.log("about to upload file");
    }

    async sendBountyOnClick(event){
        let amount = 0.01; //the default bounty is 1 cent.

        await addBounty(
            amount, //amount
            Number(exchangeRate), //exchangeRate
            "", //txid
            "", //moneyButtonId
            this.state.paymail, //senderPaymail
            this.state.word, //word,
        );

        this.props.history.push({pathname:"/confirmation/wordbounty-thank-you/" + encodeURL(this.state.word)});
    }

    async myOnPaymentCallback(payment){
      let amount = 0;
      amount = parseFloat(payment.amount)
      
      await addBounty(
        amount, //amount
        Number(exchangeRate), //exchangeRate
        "", //txid
        "", //moneyButtonId
        this.state.paymail, //senderPaymail
        this.state.word,
      );
      
      this.props.history.push({pathname:"/confirmation/wordbounty-thank-you/" + this.state.word});
    }

    getCanAddToWordBounty = async(word) => {
        // let returnValue = false;
        // let url = `${config.url.wordBountyService}/bounty/active/${this.state.word}/word`;
        // let result = await axios.get(url);
        // let bounty = result.data.bounty
        // if (Number(bounty) >= config.app.wordBaronPriceLimit){
        //     returnValue = false;
        // }

        //return returnValue;
        return true; //turning it off. July 15, 2022
    };

    modalOnHide = () => {
        this.setState({limitToggle : false});
    }
    
    validateBounty =(evt) => {
        var theEvent = evt || window.event;
      
        // Handle paste
        var key;
        if (theEvent.type === 'paste') {
            key = theEvent.clipboardData.getData('text/plain');
        } else {
        // Handle key press
            key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
      }

    render() { 
        setBountyMessage = 
            (<span>If you set a word bounty of <span style={{color: "green"}}>$2</span> or more, <br/> the definitions will be converted to NFTs</span>)

            var mbElement = localStorage.getItem("hcAuthToken") === null ? (
            <div id="setBounty">
                <MoneyButton
                        to="selflearningdictionary@MoneyButton.com"
                        type="tip"
                        amount="0.0096"
                        editable = "true"
                        currency="USD"
                        onPayment={this.myOnPaymentCallback.bind(payment)}
                        label="for Bounty"
                        buttonId=""
                        buttonData="{app: SLictionary}"
                        //opReturn="SLic"
                        disabled={!this.state.canAddToBounty}
                />
            </div>
        ) : (<>
            <div id="connectButtonWrapper" style={{position: "relative", left: "-10px"}}>
            <div className="labelText">
            Enter a Bounty Amount
            </div>
            <div>
            <NumericInput 
                id="numEnterBounty"
                currencySymbol="$"
                currencySymbolColor="black"
                min="0.01"
                minMessage="The minimum bounty amount is $0.01"
            />

            </div>
            <div style={{height:"15px"}}>&nbsp;</div>
            </div>
            <HcButton 
                caption={"SPONSOR"}
                captionProcessing={"PROCESSING..."} 
                captionCompleted={"PROCESSING..."}
                paymentDescription={"SLictionary WB"}
                checkBalance={true}
                amount={amountG}
                currency="USD"
                height="50px"
                left= "-13px"
                top= "-10px"
                borderRadius="30px"
                width="200px"
                paddingLeft="10px"
                postProcess={this.hcPostProcess}
                preProcess={this.hcPreProcess}
            />
            </>   
            )
    
      return (
        <div className="content" style={{color:"white"}}>
        <Modal show={false} onHide={this.modalOnHide} > 
            {/* turned off modal window. July 15, 2022 */}
            <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
            <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>
                    Word Bounty Alert
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                <div id="divCannotAddToBounty" style={modalStyleCannotAddToBounty}>
                    <div style={{color:"white",backgroundColor:"#202020"}}>
                    <table>
                        <tr style={{verticalAlign : "textTop"}}>
                            <td style={{verticalAlign : "textTop", padding: "10px"}}>
                                <img src={infoIcon} style={{verticalAlign : "textTop"}} alt=""/>
                            </td>
                            <td>
                                <span>An active word bounty exists for this word and the bounty limit of ${config.app.wordBaronPriceLimit} has been reached.</span>
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>Additional sponsors cannot be added.</td>
                        </tr>
                        <tr><td colspan="2">&nbsp;</td></tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>You may use your search fee to add a definition for the word.</td>
                        </tr>
                    </table>
                    </div>
                    <span>&nbsp;</span>
                    <div style={{textAlign: "center"}}>
                        <Button variant="outline-light" onClick={this.modalOnHide}>OK</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
          <Container style={{width:1000}}>
          <Row>
          <Col>
            <h3 className="justify-content-md-center">
            <div className="luckyYou text-center">Lucky You!</div>

            <div className="text-center"><span style={{color:"Turquoise",fontSize:"20pt"}}>{this.state.word}</span>
            &nbsp;is not in SLictionary. You can either...</div>
            </h3>
          </Col>
          </Row>
            <Row>
              <Col className="col-12">
              <div className="bountyPanel" style={{width:"40%"}}>
                <div className="bountyPanelInner"><h3 className="blackText heading" style={{fontWeight:"bold"}}>DEFINE IT & MAKE MONEY!</h3>
                <div style={{fontSize:"18pt", fontWeight: "bold",color:"green"}}>FREE</div>
                <div>&nbsp;</div>
                  <i style={{display: "block", cursor:"pointer"}} 
                      id="addWord" 
                      className = "fas fa-plus-circle fa-5x" 
                      title="Define it" 
                      aria-hidden="true"
                      onClick={this.addWordOnClick}></i>
                </div>
                <div>&nbsp;</div>
                <div style={{textAlign: "left",whiteSpace:"normal",color:"black",margin:"20px"}}>
                    Your search fee has been applied to our Word$mith page. 
                    Write a definition for “<b>{this.state.word}</b>” and make money when others vote 
                    on it.
                </div>
              </div>
              <div className="bountyPanel">
                <div className="bountyPanelInner"><h3 class="blackText heading" style={{fontWeight:"bold"}}>SET WORD BOUNTY</h3></div>
                <div 
                    style={{position:"relative",marginLeft:"10px", color:"blue", 
                            fontWeight:"bold", fontsize:"36pt", textAlign: "left", paddingLeft: "50px"}}>
                    {setBountyMessage}
                    </div>
                  <table style={{width:"100%"}}>
                    <tr>
                      <td className="blackText" style={{marginRight:"10px",textAlign:"right"}}>
                      <span ><p className="labelText" style={{fontWeight: "bold"}}>&nbsp;&nbsp;Set bounty at 1&cent;&nbsp;&nbsp;<span 
                          style={{fontSize:"18pt", fontWeight: "bold",color:"green"}}>FREE</span></p><p className="labelText" style={{textAlign: "left", paddingLeft: "50px"}}>(using your search fee)</p>
                      </span>
                      </td>
                      <td style={{verticalAlign: "middle"}}><i style={{color:"black"}} class="fa fa-arrow-right" aria-hidden="true"></i></td>
                      <td style={{textAlign:"left"}}>
                        <Button 
                            variant={this.state.canAddToBounty ? "primary" : "secondary"} 
                            disabled={!this.state.canAddToBounty}
                            style={{size:"10px",borderRadius:"30px"}} 
                            onClick={this.sendBountyOnClick}>SET 1&cent; BOUNTY
                        </Button>
                      </td>
                    </tr>
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                    <tr><td></td><td style={{color:"black",fontWeight:"bold"}}>OR</td><td></td></tr>
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                    <tr>
                      <td>
                        <span className="blackText" 
                            style={{position:"relative",marginLeft:"10px", color:"black", fontWeight:"bold"}}
                            >Sponsor the word&nbsp; </span>
                      </td>
                      <td>
                        <i style={{color:"black"}} class="fa fa-arrow-right" aria-hidden="true"></i>
                      </td>
                      <td>
                        {mbElement}
                      </td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <div style={{textAlign: "left",whiteSpace:"normal",color:"black"
                                , margin:"30px",marginTop:"0px"}}>
                                This word will go onto the 
                                Word Bounty list where 
                                Word$miths will define it for you. Higher bounties attract more WordSmiths and 
                                better definitions.
                            </div>
                            <div>&nbsp;</div>
                            <div style={{marginBottom:"10px", marginLeft:"-55px", marginTop:"-30px"}}>
                            </div>
                        </td>
                    </tr>
                </table>
                </div>
              </Col>
            </Row>
          </Container>  
        </div>
        
      );
    }
}

SearchResults.contextType = UserContext;

export default SearchResults;