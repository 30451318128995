import React,{useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import { getUUID } from '../mods/slictionary-util-mod';
import {postDiscountCode} from '../services/user/UserService';
import {encodeURL} from '../mods/slictionary-util-mod';
import { postAnalyticsPage } from "../services/user/UserService";

function Util(props){
    document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
    document.body.style.backgroundAttachment = "fixed";

    var seekURL = "";
    var beABaronURL = "";
    var smithingDiscountURL = "";
    var smithingURL = "";

    useEffect( () => {
        postAnalyticsPage(localStorage.getItem("senderPaymail"),
        window.location.pathname,
        window.navigator.userAgent,
        window.navigator.userAgentData);

        document.getElementById("copySeek").style.display = "inline";
        document.getElementById("copyBeABaron").style.display = "inline";
    }, []);

    const getUrlsOnClick = (e) => {
        switch(document.getElementById("server").value){
            case "PROD":
                seekURL = "https://www.slictionary.com/seek";
                beABaronURL = "https://www.slictionary.com/BeABaron/@/";
                smithingDiscountURL = "https://www.slictionary.com/";
                smithingURL = "https://www.slictionary.com/";
                break;
            case "TEST":
                seekURL = "https://slictionary-test-2b1f1.web.app/seek";
                beABaronURL = "https://slictionary-test-2b1f1.web.app/BeABaron/@/";
                smithingDiscountURL = "https://slictionary-test-2b1f1.web.app/";
                smithingURL = "https://slictionary-test-2b1f1.web.app/";
                break;
            case "DEV":
                seekURL = "http://localhost:3000/seek";
                beABaronURL = "http://localhost:3000/BeABaron/@/";
                smithingDiscountURL = "http://localhost:3000/";
                smithingURL = "http://localhost:3000/";
        }

        let encodedWord = encodeURL(document.getElementById("txtWord").value);

        seekURL += "/" + encodedWord;
        document.getElementById("seek").innerHTML = seekURL;//.substring(0,25) + "...";;
        
        beABaronURL += "?w=" + encodedWord + "&b=" + document.getElementById("txtBounty").value;
        document.getElementById("beABaron").innerHTML = beABaronURL;//.substring(0,25) + "...";;

        let discountCode = document.getElementById("txtDiscountCode").value + "-" + getUUID().substring(0,10);

        if(document.getElementById("txtDiscountCode").value.trim().length > 0){
            postDiscountCode(discountCode);
        }

        smithingDiscountURL += "CreateWord/" + encodedWord + "/@/@/?sd="
            + discountCode;

        smithingURL += "CreateWord/" + encodedWord + "/@/@/";

        document.getElementById("smithingURL").innerHTML = smithingURL;//.substring(0,25) + "...";
        document.getElementById("smithingDiscountURL").innerHTML = smithingDiscountURL;//.substring(0,25) + "...";;
        
        document.getElementById("copySeek").style.display = "inline";
        document.getElementById("copyBeABaron").style.display = "inline";
    }

    const copyToClipboard = (e) => {
        switch(e.target.id){
            case "copySeek":
                navigator.clipboard.writeText(seekURL);
                break;
            case "copyBeABaron":
                navigator.clipboard.writeText(beABaronURL);
                break;
            case "copySmithingDiscount":
                navigator.clipboard.writeText(smithingDiscountURL);
                break;
            case "copySmithing":
                navigator.clipboard.writeText(smithingURL);
                break;
    
        }
    }

    return (

        <>
        <table style={{width: "500px", tableLayout: "fixed"}}>
            <tbody>
            <tr>
                <td style={{width: "35%"}}>
                    <label>Server:</label>
                </td>
                <td colSpan="2" style={{textAlign: "right"}}>
                        <select id="server">
                            <option id="prod">PROD</option>
                            <option id="test">TEST</option>
                            <option id="dev">DEV</option>
                        </select>
                </td>
            </tr>
            <tr>
                <td style={{width: "35%"}}>
                    <label>Word:</label>
                </td>
                <td colSpan="2" style={{textAlign: "right"}}>
                    <input id="txtWord" type="text"/>
                </td>
            </tr>
            <tr>
                <td style={{width: "35%"}}>
                    <label>Bounty:</label>
                </td>
                <td colSpan="2" style={{textAlign: "right"}}>
                    <input id="txtBounty" type="text"/>
                </td>
            </tr>
            <tr>
                <td style={{width: "35%"}}>
                    <label>Discount Code:</label>
                </td>
                <td colSpan="2" style={{textAlign: "right"}}>
                    <input id="txtDiscountCode" type="text"/>
                </td>
            </tr>
            <tr>
                <td colSpan="3" style={{textAlign: "right"}}>
                    <Button id="submit" onClick={getUrlsOnClick}>Get URLs</Button>
                </td>
            </tr>
            <tr><td colSpan="3">&nbsp;</td></tr>
            <tr>
                <td style={{width: "25%"}}>
                    <label>Seek URL:</label>
                </td>
                <td>
                    <span id="seek" style={{wordWrap: "break-word"}}></span>
                </td>
                <td style={{textAlign: "right"}}>
                    <Button id="copySeek" style={{display: "inline", verticalAlign: "top"}} onClick={copyToClipboard}>Copy</Button>
                </td>
            </tr>
            <tr>
                <td style={{width: "25%"}}>
                    <label>BeABaron URL:</label>
                </td>
                <td>
                    <span id="beABaron" style={{wordWrap: "break-word"}}>{beABaronURL}</span>
                </td>
                <td style={{textAlign: "right"}}>
                    <Button id="copyBeABaron" style={{display: "inline", verticalAlign: "top"}} onClick={copyToClipboard}>Copy</Button>
                </td>
            </tr>
            <tr>
                <td style={{width: "25%"}}>
                    <label>Smithing:</label>
                </td>
                <td>
                    <span id="smithingURL" style={{wordWrap: "break-word"}}>{smithingURL}</span>
                </td>
                <td style={{textAlign: "right"}}>
                    <Button id="copySmithing" style={{display: "inline", verticalAlign: "top"}} onClick={copyToClipboard}>Copy</Button>
                </td>
            </tr>
            <tr>
                <td style={{width: "25%"}}>
                    <label>Smithing Discount:</label>
                </td>
                <td>
                    <span id="smithingDiscountURL" style={{wordWrap: "break-word"}}>{smithingDiscountURL}</span>
                </td>
                <td style={{textAlign: "right"}}>
                    <Button id="copySmithingDiscount" style={{display: "inline", verticalAlign: "top"}} onClick={copyToClipboard}>Copy</Button>
                </td>
            </tr>
            </tbody>
        </table>

      </>

    )
}

export default Util;