/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Login from './components/Login/Login';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import RefineSearch from './components/RefineSearch';
import { SearchContext } from './SearchContext';
import CreateWord from './components/CreateWord';
import Mission from './components/Mission';
import WhyPay from './components/WhyPay';
import HowItWorks from './components/HowItWorks';
import WBTermsOfService from './components/WBTermsOfService';
import Contact from './components/Contact';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import WordDetail from './components/WordDetail';
import SearchResults from './components/SearchResults';
import BeAbaron from './components/word-bounty/BeAbaron';
import mySLictionary from './components/mySLictionary'; //don't lazy load this one. It messes up the CSS for some reason.
import VoiceRecorder from './components/VoiceRecorder';
import ErrorDisplayMessage from './error-pages/error-display-message';
import ErrorInvalidTransaction from './error-pages/error-invalid-transaction';
import ErrorInvalidNavigation from './error-pages/error-invalid-navigation';
import WordBountyThankYou from './components/word-bounty/WordBountyThankYou';
import logo from './assets/slictionarylogo.png';
import releaseNotesV1_2 from './release-notes/releaseNotesV1_2';
import SeekForExternal from './SeekForExternal';
import Util from './dashboard/Util.js';
import SLebrity from './components/word-bounty/SLebrity';
import Definitions from './components/Definitions';
import Support from './components/support/Support';
import Definitions2 from './components/Definitions2';
import DefinitionViewer from './components/DefinitionViewer';

const WordBounty = lazy(() => import('./components/word-bounty/WordBounty'));
const WordStats = lazy(() => import('./components/WordStats'));
const Leaderboard = lazy(() => import('./components/leaderboard/Leaderboard'));
const SLebrityTOS = lazy(() => import('./components/word-bounty/SLebrityTOS'));
const config = require("./config/config");

const element = (
  
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
      <Container id="mainContainer" style={{paddingRight:"0px",maxWidth:"100%",maxHeight:"100%"}}>
  
        <Row id="topNav" className="row justify-content-start " 
              style={
                  {backgroundColor: "black",
                   opacity:"1.00", 
                   width: "102%",
                  }
                  }
        >
  
            <Col className="col-2" style={{opacity: "1.0", paddingTop: "6px"}}>
              <Link to="/">
                  <img src={logo}/>
             </Link>
            </Col>
  
            <Col className="col-10" style={{textAlign:"right", fontSize:"14pt", opacity: "1"}}>
                <Link to="/myslictionary" id="myslictionary" 
                      className={localStorage["senderPaymail"] === undefined ? "disabled-link" : ""}
                      style={{color:localStorage["senderPaymail"] === undefined ? "gray" : "#a4c2f4"}}
                      > 
                      MySLICTIONARY
                </Link>

                <span style={{color:"gray", fontWeight:"bold"}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>

                <Link to="/WordStats" id="leaderboard">
                  {config.app.verbiage.leaderboard.toUpperCase()}
                </Link>    

                <span style={{color:"gray", fontWeight:"bold"}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>

                <Link to="/Support" id="support">
                  {config.app.verbiage.support.toUpperCase()}
                </Link>    
    
                <span style={{color:"gray", fontWeight:"bold"}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
  
                {<Login
                        linkDisplay="inline"
                />}        

           <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Link to="/wordbounty" id="wordbounty">
              <Button variant="light" 
                  style={{MozBorderRadius: "20px",
                          WebkitBorderRadius: "20px",
                          borderRadius: "20px",
                          fontWeight: "bold"}}
              >Word Bounty</Button> 
              </Link>
            </Col>
        </Row>
        <Row><Col>&nbsp;</Col></Row>
        <Row><Col>&nbsp;</Col></Row>
        <Row className="row ">
            <Col className="col-12"  style={{zIndex: "0"}}>
              <div id="parentDiv" style={{zIndex: "1",justifyContent: "center", display: "flex"}}> 
                  {/* This is the Login Splash screen to mask the latency coming from the Handcash redirect. */}
                  <div id="divSplashScreen" 
                      style={{display: "none",
                          width: "750px",height: "450px", background: "black", zIndex:"100",
                          position:"fixed", textAlign:"center", border:"2px solid white",
                          }}><br/><br/><br/><br/><br/><br/>
                      <span style={{fontSize: "36pt", color:"white"}}>Logging in...</span>
                  </div>
  
                  <SearchContext.Provider> 
                      <Route exact path='/' component={App} />
                      <Route path='/refineSearch/:word' component={RefineSearch} />
                      <Route path='/createword/:word/:defId/:txid' component={CreateWord} />
                      <Route path='/mission/' component={Mission} />
                      <Route path='/whypay/' component={WhyPay} />
                      <Route path='/contact' component={Contact} />
                      <Route path='/howitworks/' component={HowItWorks} />
                      <Route path='/wbtermsofservice' component={WBTermsOfService} />
                      <Route path='/searchresults/:word/:txid' component={SearchResults} />
                      <Route path='/worddetail/:word/:defId' component={WordDetail} />
                      <Route path='/definitions/:word/:txid' component={Definitions} />
                      <Route path='/wordbounty/' component={WordBounty} />
                      <Route path='/mySLictionary/' component={mySLictionary} />
                      <Route path='/WordStats/' component={WordStats} />
                      <Route path='/VoiceRecorder/' component={VoiceRecorder} />
                      <Route path='/error-pages/error-display-message/' component={ErrorDisplayMessage} />
                      <Route path='/error-pages/error-invalid-transaction/' component={ErrorInvalidTransaction} />
                      <Route path='/error-pages/error-invalid-navigation/' component={ErrorInvalidNavigation} />
                      <Route path='/confirmation/wordbounty-thank-you/' component={WordBountyThankYou} />
                      <Route path='/dashboard/' component={Leaderboard} />
                      <Route path='/BeAbaron/:word' component={BeAbaron} />
                      <Route path='/releaseNotesV1_2' component={releaseNotesV1_2} />
                      <Route path='/seek/:word' component={SeekForExternal} />
                      <Route path='/admin/util' component={Util} />
                      <Route path='/SLebrity' component={SLebrity} />
                      <Route path='/SLebrityTOS' component={SLebrityTOS} />
                      <Route path='/Support' component={Support} />
                      <Route path='/viewDefinition/:definitionId' component={DefinitionViewer} />
                      {/* <Route path='/definitions2/:word/:txid' component={Definitions2} /> */}
                  </SearchContext.Provider>
              </div>
            </Col>
      </Row>
      <Row id="footer">
          <Col className="col-4"></Col>
                <Col className="col-4" style={{textAlign:"center"}}> 
                <Link to="/Mission" style={{fontSize:14,color:"gray"}}>{config.app.verbiage.about}</Link>
                    &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                    <Link to="/WhyPay" style={{fontSize:14,color:"gray",whiteSpace:"nowrap"}}>{config.app.verbiage.ourPhilosophy}</Link>
                    &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                  <Link id="wordCount" to="/WordStats" style={{fontSize:14,color:"#a4c2f4",whiteSpace:"nowrap"}}>{sessionStorage.getItem("wordcount") + " " + config.app.verbiage.words}</Link>
                  &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                  
                    <Link id="contact" to="/Contact" style={{fontSize:14,color:"gray",whiteSpace:"nowrap"}} >{config.app.verbiage.contactUs}</Link>
                    &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                    <Link to="/HowItWorks" style={{fontSize:14,color:"gray",whiteSpace:"nowrap"}}>{config.app.verbiage.howItWorks}</Link>
                </Col>
                <Col className="col-4"></Col>
        </Row>
      </Container>
      </Suspense>
    </Router>
);

ReactDOM.render(element,document.getElementById('root'));
serviceWorker.unregister();
