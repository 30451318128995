import React,{useState} from "react";  
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './HcButton.css';
import { getHCRedirctionLoginUrl } from "../../mods/slictionary-handcash-mod";
import { getBalance, postPayment } from "../../services/handcash/handcashService";
import {HandcashInsufficientFunds} from "../../mods/slictionary-error-mod";

const config = require("../../config/config");

var errorMessage;
var caption;
var amount;
var buttonId;

const HcButton = (props) => {  
    buttonId = props.id !== undefined ? props.id : "connectButton";
    switch(props.caption){
        case "SEEK_PAGE":
            caption = (<>
                1&cent; to SEEK
                </>)
            break;
        case "ADDWORD_PAGE":
            caption = (<>
                &lt; 1&cent; to Add Word
                </>)
            break;
        // case "SUBMIT BID","NOMINATE":
        //     caption = props.caption;
        //     break;
        default:
            // caption = (<>
            //     &lt; 1&cent; to Add Word
            //     </>)
            caption = props.caption;

    }

    const [toggle, setToggle] = useState(false);
    const [showInsufficentFundsModal, setShowInsufficentFundsModal] = useState(false);
    const [showLoginDiv, setShowLoginDiv] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const closeModal = () => {
        setToggle(false);
    }

    const hcOnClick = async(e) => {
        e.persist();
        await props.preProcess(e);
        let targetValue = e.target === undefined ? "" : e.target.value;
        if(targetValue !== "failed"){
            amount = e.amount !== undefined ? e.amount : props.amount;
            e.amount = undefined;
            var eleButton = document.getElementById(buttonId);
            eleButton.innerHTML = props.captionProcessing;

            try{
                if(props.checkBalance) {
                    let balance = await getBalance(localStorage.getItem("hcAuthToken"));
                    if(balance.spendableFiatBalance <= amount){
                        eleButton.innerHTML = caption;
                        throw new HandcashInsufficientFunds();//`
                            // Insufficient funds. Either you don't have enough bsv in your wallet
                            // or your Handcash daily spend limit is too low.`);
                    }
                } 

                const paymentResult = await postPayment(
                    localStorage.getItem("hcAuthToken"),
                    props.paymentDescription,
                    config.handcash.paymentDestination,
                    props.currency,
                    amount
                );
                e.paymentResult = paymentResult;
                eleButton.innerHTML = props.captionCompleted;
                e.transactionId = paymentResult.transactionId;
                await props.postProcess(e);

            } catch (error) {
                setErrorMessage(error.message);
                if(error.name === "HandcashInsufficientFunds"){
                    setShowInsufficentFundsModal(true);
                    setToggle(false);
                } else {
                    setShowInsufficentFundsModal(false);
                    setToggle(true);
                    eleButton = document.getElementById(buttonId);
                    eleButton.innerHTML = "Log in";
                }

                
                //setToggle(true);
            }

        }
    }

    const handleCloseInsufficientFundsModal = (e) => {
        setShowInsufficentFundsModal(false);
    }

    const handcashOnClick = (e) => {
        localStorage.removeItem("hcAuthToken");
        const redirectionLoginUrl =  getHCRedirctionLoginUrl();
        window.location.assign(redirectionLoginUrl);
    }

    return (
        <>

        <Button 
            id={buttonId} 
            style={{
                borderRadius: props.borderRadius, 
                width: props.width, 
                height: props.height, 
                paddingLeft: props.paddingLeft, 
                left: props.left, 
                position: "relative",
                zIndex: 99,
                top: props.top,
                borderColor: "#38CB7C",
                backgroundColor: "#38CB7C"
                }
            } 
            onClick={hcOnClick}>
            {caption}
        </Button>

        {(
            <>
            <Modal show={toggle} onHide={closeModal} backdrop="static">
            <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
            <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Handcash Authorization Error</Modal.Title>
            </Modal.Header> 
            <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
            <div id="modalLogin" style={{color:"white",backgroundColor:"#202020", display:{showLoginDiv}}}>
                <p style={{color: "white", fontSize: "14pt"}}>
                {/* {errorMessage}     */}
                    Your Handcash Authorization Token has expired.<br/><br/>
                    Click the button to login.
                </p>
                <div id="divHandcash" className="text-center">
                    <div>&nbsp;</div>
                    <div id="connectButton"
                        url="https://app.handcash.io/#/authorizeApp?appId=<your-app-id>"
                        onClick={handcashOnClick}>
                            Connect with HandCash
                    </div>
                </div>
                <br/>
            </div>                
            </Modal.Body>
        </Modal>

        <Modal id="modalInsufficientFunds" show={showInsufficentFundsModal} onHide={handleCloseInsufficientFundsModal} backdrop="static" >
            <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Insufficient Funds</Modal.Title>
            </Modal.Header> 
            <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                <div id="modalInsufficientFunds" style={{color:"white",backgroundColor:"#202020", width:"290px"}}>
                    <p style={{color: "white", fontSize: "14pt"}}>
                        Insufficient funds. Either you don't have enough bsv in your wallet
                        or your Handcash daily spend limit is too low.
                    </p>
                    <br/>
                    <div className="text-center">
                    <Button id="modalClose"
                        variant="outline-light" 
                        onClick={handleCloseInsufficientFundsModal}>OK</Button>
                    </div>
                </div>                
            </Modal.Body>
        </Modal>
        </>
      )}
      </>

    )
}

export default HcButton;  