import React,{useState, useCallback, useEffect} from "react";  
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import imgSupport from "../../assets/broken-pencil-clipart-39-3949714261.png";
import {postSupportTicket} from "../../services/user/UserService"; 
import { postAnalyticsPage } from "../../services/user/UserService";
import { Link } from "@material-ui/core";
const config = require("../../config/config");
var ticketId = "";

function Support (props) {
    postAnalyticsPage(localStorage.getItem("senderPaymail"),
                        window.location.pathname,
                        window.navigator.userAgent,
                        window.navigator.userAgentData);

    const [modalToggle, setModalToggle] = useState(false);
    const [modalError, setModalError] = useState(false);

    document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
    document.body.style.backgroundAttachment = "fixed";
    
    const submitTicket = async(e) => {
        try{
            let issueType = document.getElementById("txtIssueType").value.trim();
            let paymail = localStorage.getItem("senderPaymail");
            let message = document.getElementById("txtDescription").value.trim();
            let email = document.getElementById("txtEmail").value.trim();
            
            if(issueType.length === 0 || message.length === 0 || email.length === 0){
                throw new Error("Missing Required Fields");
            }
            ticketId = await postSupportTicket(issueType, paymail, message, email);
            setModalToggle(true);
        } catch (error) {
            setModalError(true);
        }
    }

    const btnOKonClick = (e) => {
        props.history.push(
            {
                pathname: "/"
            }
        )
    }

    const btnOKerrorOnClick = (e) => {
        setModalError(false);
    }

    const closeModal = (e) => {
        setModalToggle(false);
    }

    const closeModalError = (e) => {
        setModalError(false);
    }

    const mergePaymails = (e) => {
        let moneybuttonPaymail = "";
        let handcashPaymail = "";
        if(localStorage.getItem("senderPaymail") !== undefined && localStorage.getItem("senderPaymail") !== null){
            if(localStorage.getItem("senderPaymail").indexOf("moneybutton.com") !== -1){
                moneybuttonPaymail = localStorage.getItem("senderPaymail"); 
            } else {
                handcashPaymail = localStorage.getItem("senderPaymail");
            }
        }
        document.getElementById("txtIssueType").value = "Paymail merge";
        document.getElementById("txtDescription").value = 
            `Please merge my Moneybutton and Handcash paymails so I can see all my definitions and NFTs.\n\nMoneybutton paymail = ${moneybuttonPaymail}\nHandcash paymail = ${handcashPaymail}`;
        e.preventDefault();
    }

    return (

      <div id="parentContainer" className="" style={{color:"white"}}>
          <h2 className="howItWorksTitle text-center" style={{color: "lightgoldenrodyellow"}}>
          Welcome to SLictionary Support</h2>

          <div  style={{ 
                padding:'5px',
                borderRadius:'15px',
                // border:'0.5px solid black',
                width:'800px',
                background:'transparent',
                color:'white'}}>
            <p className="text-center">
                <img src={imgSupport}
                    style={{
                        width: "100px",
                        height: "auto",
                        borderRadius: "50px"
                    }}
                />
            </p>
            <h3 className="slebrity-h3" style={{textAlign: "center", fontStyle: "italic"}}>
                Having trouble with something?
            </h3>
            <p style={{paddingBottom: "25px"}}>
                <span>All fields required</span>
            </p>
            <p style={{paddingBottom: "25px"}}>
                <input id="txtEmail" type="text" className="form-control" placeholder="Email"
                    style={{width: "300px", height: "35px",borderRadius: "15px",
                    paddingLeft: "10px"}}/>
                <br/>
                <span>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                <input id="txtIssueType" type="text" className="form-control" placeholder="Issue Type" 
                                    style={{width: "300px", height: "35px",borderRadius: "15px",
                                    paddingLeft: "10px"}}/>
                                </td>
                                <td style={{marginRight: "10px"}}>&nbsp;&nbsp;&nbsp;Quick Link:&nbsp;&nbsp;&nbsp; 
                                    <a href="." id="lnkMerge"
                                        style={{color: "lightgoldenrodyellow",fontSize: "14pt"}} 
                                        onClick={mergePaymails}>Merge Moneybutton and Handcash</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>
            </p>
            <p>
                <textarea id="txtDescription" className="form-control" 
                    dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                    cols="15" rows="7"
                    placeholder="Describe your issue in detail" 
                />
            </p>
            <p className="text-center" style={{paddingTop: "25px"}}>
                <Button variant="primary" onClick={submitTicket} style={{borderRadius: "50px", width: "150px"}}>SUBMIT TICKET</Button>
            </p>
      </div>
          <Modal show={modalToggle} backdrop="static" onHide={closeModal} >
            <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
            <Modal.Title id="modalTitle" style={{color:"white",backgroundColor:"#202020"}}>Thank You!</Modal.Title>
            </Modal.Header> 
            <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                <div>
                    Your issue has been submitted. <br/>We will get back to you as soon as we can.
                    <br/><br/>
                    Your ticket ID is {ticketId}
                    <br/><br/>
                </div>
                <div className="text-center"    >
                    <Button id="btnOK" onClick={btnOKonClick}>OK</Button>
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={modalError} backdrop="static" onHide={closeModalError} >
            <Modal.Header closeButton style={{color:"black",backgroundColor:"yellow"}}>
               <Modal.Title id="modalTitle" style={{color:"black",backgroundColor:"yellow"}}>Missing Field(s)!</Modal.Title>
            </Modal.Header> 
            <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                <div>
                    You are missing a required field.
                    <br/><br/>
                    All fields are required.
                    <br/>
                </div>
                <div className="text-center"    >
                    <Button id="btnOKerror" variant="outline-light" onClick={btnOKerrorOnClick}>OK</Button>
                </div>
            </Modal.Body>
        </Modal>

      </div>
      
    );
  }


export default Support;