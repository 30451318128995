import axios from 'axios';
const config = require('../../config/config');
var moment = require('moment');

const postPaymentLog = async(txId, amount, toPaymail, fromPaymail, paymentType, word) => {
    let snapshot = await axios.post(`${config.url.bsvService}/payment/log`,
        {
            createDatetime: moment().format(),
            txId: txId,
            satAmount: amount,
            opReturn: "SLICDEF",
            toAddress: "",
            paymentType: paymentType,
            fromPaymail: fromPaymail,
            toPaymail: toPaymail,
            word: word
        }
    );
    
    return snapshot.data;  
}

export {postPaymentLog}