import React from 'react';
import { postAnalyticsPage } from '../../services/user/UserService';
class BaseComponent extends React.Component {

    constructor(props) {
        super(props);
        postAnalyticsPage(localStorage.getItem("senderPaymail"),
        window.location.pathname,
        window.navigator.userAgent,
        window.navigator.userAgentData);

        sessionStorage.setItem("previousUrl",this.props.location.pathname);
        this.defaultProp = {
          userID: ""
        };
      }
  
    componentWillMount(){
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
        document.body.style.backgroundAttachment = "fixed";
    
    }

    async componentDidMount(word) {
        //document.body.style.backgroundImage = "url('')";
        
        // let wb = document.getElementById("word-bounty");
        // wb.style.display = "inline";

    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log(error, info);  
      }
  }
  
  export default BaseComponent;