const { uuid } = require('uuidv4');

function sortByProperty(property){  
    return function(a,b){  
       if(a[property] < b[property])  
          return 1;  
       else if(a[property] > b[property])  
          return -1;  
   
       return 0;  
    }  
 }

function sortByMultiple(arr){
 
    arr.sort(
        function sortF(ob1,ob2){
        if (ob1.bounty < ob2.bounty) {
            return 1;
        } else if (ob1.bounty > ob2.bounty) { 
            return -1;
        }
    
        // Else go to the 2nd item
        if (ob1.word < ob2.word) { 
            return -1;
        } else if (ob1.word > ob2.word) {
            return 1
        } else { // nothing to split them
            return 0;
        }
    }
    );
    return arr;
}

function getUUID(){
    let guid = uuid();
    return guid.replace(/-/g,"");
}
 
/** Checks for existence o f a value of a property in an array of JSON objects */
const propertyValueExists = (arrayToSearch, property, textToFind) => {
    let arr = arrayToSearch.map(x => x[property] === textToFind)
        .filter(
          function check(arg){
            return arg;
        });
    
    let result = arr.length > 0 ? true : false;
    return result;
}

const capitalizeEachWord = (phrase) => {
    let arr = phrase.split(" ");
    let capitalizedPhrase = "";
    for(let i = 0; i < arr.length; i++){
        let word = arr[i].replace(" ","");
        if(word.length > 0){
            capitalizedPhrase += word[0].toUpperCase() + word.substr(1) + " ";
        }
    }

    return capitalizedPhrase.trim();
}

const encodeURL = (text) => {
    if(text === undefined | text === ""){
        return text;
    }  
    text = text
        .replaceAll("%","%25") //must do % first for an obvious reason
        .replaceAll(" ","%20")
        .replaceAll('"',"%22")
        .replaceAll("#","%23")
        .replaceAll("$","%24")
        .replaceAll("&","%26")
        .replaceAll("'","%27")
        .replaceAll("@","%40")
        .replaceAll("+","%2B")
        .replaceAll("=","%3D")
        .replaceAll("[","%5B")
        .replace(/\\/g, "%5C")
        .replaceAll("]","%5D")
        .replaceAll("^","%5E")
        .replaceAll("{","%7B")
        .replaceAll("}","%7D")
        ;

    return text;
}

const decodeURL = (text) => {
    
    text = text
        .replace(/%20/g,' ')
        .replace(/%22/g,'"')
        .replace(/%23/g,'#')
        .replace(/%24/g,'$')
        .replace(/%26/g,'&')
        .replace(/%27/g,"'")
        .replace(/%28/g,"(")
        .replace(/%29/g,")")
        .replace(/%40/g,"@")
        .replace(/%2B/g,'+')
        .replace(/%3D/g,'=')
        .replace(/%5B/g,'[')
        .replace(/%5C/g, "\\")
        .replace(/%5D/g,']')
        .replace(/%5E/g,"^")
        .replace(/%7B/g,'{')
        .replace(/%7D/g,'}')
        .replace(/%25/g,"%")
        ;

    return text;
}

const getFormattedPaymail = (paymail) => {
    let arr = paymail.split("@");
    let returnVal = "";
    if(arr[1] === "handcash.io"){
        returnVal = "$" + arr[0];
    } else {
        returnVal = paymail;
    }

    return returnVal;
}


export {sortByMultiple, getUUID, propertyValueExists, capitalizeEachWord,
        encodeURL, decodeURL, getFormattedPaymail};