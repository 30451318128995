import React from 'react';
import DefinitionPanel from './DefinitionPanel';
import{useState, useEffect} from "react";
import { decodeURL } from '../mods/slictionary-util-mod';

const config = require("../config/config");

const DefinitionViewer = (props) => {
    const [item, setItem] = useState([]);
    let arr = window.location.pathname.split("/");
    const word = "conference";
    const definitionId = decodeURL(arr[2]); 

    
    useEffect(() => {
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";

        fetch(`${config.url.wordService}/definition/${definitionId}`)
        .then(data => data.json())
        .then(results => {
            setItem(results)
        })
    }, []);

    return(
        <DefinitionPanel item={item} word={word} />
    )
}

export default DefinitionViewer;