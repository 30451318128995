/* eslint-disable no-unused-vars */
import React,{useState, useCallback, useEffect} from "react";  
import { Link } from 'react-router-dom';
import '@inovua/reactdatagrid-community/base.css';
import '../../components/Common/default-dark.css'
import ReactDataGrid from '@inovua/reactdatagrid-community';
import greenCheckMark from '../../assets/green-checkmark.jpg';
import imgAuctioneer from '../../assets/angry-auctioneer.png';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './SLebrity.css';
import Countdown from "react-countdown";
import HcButton from "../handcash/HcButton";
import { getBalance, postPayment } from "../../services/handcash/handcashService";
import NumericInput from "../Common/NumericInput";
import dateFormat, { masks } from "dateformat";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { getLastestAuction, addBid, putSLebrityAmount, postSLebrity, putSlebrityMaxBid} from "../../services/word-bounty/WordBountyService";
import {postPaymentLog} from "../../services/bsv/BsvService";
import warningIcon from "../../assets/warning-icon.png";
import SLicTooltip from "./SLicTooltip";
import twitterStats from "../../assets/Twitter stats red circle with line.jpg";
import { postAnalyticsPage } from "../../services/user/UserService";
import {SLebrityBid, getFinalBid} from "./SLebrityBid";
import { reimburseBidder } from "./SLebrityUtil";
import SLebrityLeaderboard from "./SLebrityLeaderboard";
import { HandcashInsufficientFunds, HandcashInvalidMaxBid } from "../../mods/slictionary-error-mod";

const config = require("../../config/config");
const axios = require("axios");
const bidIncreaseFactor = 1 + config.app.SLebrity.percentageIncrease/100;

const MoneyButton = require('@moneybutton/react-money-button').default;

//var amountG = 0;
var slebrityAuction;
var userBidData = {
    bid: 0,
    maxBid: 0
}
var paymailA = "", bidA = 0, maxA = 0
var paymailB = "", bidB = 0, maxB = 0
var FinalBid = {};
var auctionIsCompleted = false; 
var minimumFractionDigits = 2;
var minimumFractionDigitsNomination = 2;
var futureSlebrityDataSource;
//localStorage.getItem("senderPaymail") === null ? false : true;

const FUTURE_SLEBRITY_URL = `${config.url.wordBountyService}/auction/nominations`;

const gridStyle = {
    minHeight: 400,
    border: '1px solid black',
    boxShadow: '0 0 8px 2px rgba(121, 134,203, 0.5)',
    backGroundColor: 'black',
    color: 'white'
}

const rowStyle = ({ data }) => {
    return {
      background:'black'
    }
}

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
    autoHide: false,
    alwaysShowTrack: true
});

const getHeader = (column) => {
    return (cellProps) => {
        return <div style={{ display: 'inline-block', fontSize: "14pt"}}>
            {column}
        </div>
    }
}

const futureSlebrityColumns = [
    { name: 'slebrityAmount', header: getHeader('Amount'), 
        headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }},
        defaultWidth: 150, 
        cellProps: { style: { color: '#98CBFF'}},
        render: ({ value }) => `${Number(value).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits})}` },
    { name: 'slebrityName', header: getHeader('SLebrity'), 
        headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }},
        defaultWidth: 300}, 
    { name: 'slebrityWord', header: getHeader('Word they should define'), 
        headerProps: {style: { color: 'white', fontWeight: 'bold',background:'black',border:'1px black solid' }},
        defaultWidth: 335}
];

const loadfutureSlebrityData = async() => {
    try{
        const response = await fetch(
            FUTURE_SLEBRITY_URL
        );
        return await response.json();
    } catch (err) {
        console.log(err);
    }
}

const SLebrity = (props) => {  

    const [dataSource, setDataSource] = useState([]);

    document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
    document.body.style.backgroundAttachment = "fixed";

    const [modalToggle, setModalToggle] = useState(false);
    const [modalValidation, setModalValidation] = useState(false);
    const [nomModalValidation, setNomModalValidation] = useState(false)
    const [modalValidationTitle, setModalValidationTitle] = useState("Missing Fields");
    const [modalValidationMessageType, setModalValidationMessageType] = useState(0);
    const [modalValidationMessage, setModalValidationMessage] = useState("");
    const [modalBidAcceptance, setModalBidAcceptance] = useState(false);
    const [modalReview, setModalReview] = useState(false);
    const [countdown, setCountdown] = useState();
    const [selected, setSelected] = useState({ 1: true, 2: true })
    const [canVote, setCanVote] = useState({display: "none"});
    const [word, setWord] = useState();
    const [showVoteModal, setShowVoteModal] = useState(false);
    const [slebrityName, setSlebrityName] = useState("");
    const [slebrityDocId, setSlebrityDocId] = useState("");
    const [tabName, setTabName] = useState("AUCTION");
    const [auctionDocId, setAuctionDocId] = useState("");
    const [amountG, setAmountG] = useState(0);
    const [bio, setBio] = useState("");
    const [currentHighBidJson, setCurrentHighBidJson] = useState({});
    const [showInsufficentFundsModal, setShowInsufficentFundsModal] = useState(false);


    var isMuggleMode = (localStorage.getItem("senderPaymail") === undefined 
        || localStorage.getItem("senderPaymail") === null); 

    var styleNominate = {
        display: isMuggleMode ? "none" : "inline"
    }

    useEffect(() => {
        postAnalyticsPage(localStorage.getItem("senderPaymail"),
            window.location.pathname,
            window.navigator.userAgent,
            window.navigator.userAgentData
        );
        
        const fetchData = async()  =>{
                if(futureSlebrityDataSource === undefined){
                    futureSlebrityDataSource = loadfutureSlebrityData();
                    setDataSource(futureSlebrityDataSource);
                }
            
                setCanVote({});

                if(slebrityAuction === undefined){
                    slebrityAuction = await getLastestAuction();
                }

                paymailA = slebrityAuction.bids[0].bidderPaymail;
                bidA = slebrityAuction.bids[0].bid;
                maxA = Number(slebrityAuction.bids[0].maxBid);

                setBio(slebrityAuction.auction.bio);                
                setAuctionDocId(slebrityAuction.bids[0].docId);
                let endDatetimeMilli = new Date(slebrityAuction.auction.endDatetime).getTime();
                auctionIsCompleted =  endDatetimeMilli - new Date().getTime() <= 0;
                setCountdown(<Countdown date={endDatetimeMilli} />);
                
                let tabNameTemp;
                tabNameTemp = config.app.SLebrity.showAuction ? tabName : "NOMINATION";
                switch(tabNameTemp){
                    case "AUCTION":
                        // Comment this out for AUCTION COMING SOON
                        if(!isMuggleMode){
                            if (localStorage.getItem("senderPaymail").indexOf("moneybutton.com") === -1){ //not moneybutton
                                if(auctionIsCompleted){
                                    setAuctionIsCompletedAuctionControls();
                                } else {
                                    setDetailsAuctionControls();
                                }
                            }
                        }
                        break;
                    case "NOMINATION":
                        document.getElementById("txtNomAmount").value = "$0.01";
                        break;
                }
                
                document.getElementById("divMain").style.display = "";
        }
        fetchData();
    },[tabName]);

    const hcPreProcess = async(e) => {
        e.persist();
        
        let eleSubmitButton = document.getElementById("btnSubmitBid");

        paymailB = localStorage.getItem("senderPaymail");
        bidB = userBidData.bid;
        maxB = userBidData.maxBid;

        let BidA = new SLebrityBid(bidA, maxA, paymailA);
        let BidB = new SLebrityBid(bidB, maxB, paymailB);
        FinalBid = getFinalBid(BidA, BidB);

        if(eleSubmitButton.innerHTML !== "PROCESSING..."){
            eleSubmitButton.innerHTML = "PROCESSING...";
            try {
                let balance = await getBalance(localStorage.getItem("hcAuthToken"));
                if(balance.spendableFiatBalance <= FinalBid.max){
                    throw new HandcashInsufficientFunds();
                }
                if(FinalBid.paymail === slebrityAuction.bids[0].bidderPaymail && localStorage.getItem("senderPaymail") !== slebrityAuction.bids[0].bidderPaymail){
                    // The new bid is not big enough
                    // Add a new bid record for the updated amount of the current bid holder.

                    const result = await addBid(
                        FinalBid.bid,//bidB,
                        FinalBid.paymail,
                        FinalBid.max,
                        bidB,
                        slebrityAuction.bids[0].word,
                        slebrityAuction.auction.wordBountyAuctionId
                    );

                    //document.getElementById("highBid").innerHTML = "$" + FinalBid.bid.toFixed(2).toLocaleString("en-US");
                    document.getElementById("highBid").innerHTML = "$" + FinalBid.bid.toFixed(2).toLocaleString("en-US");

                    setModalValidationTitle("Outbid Warning");

                    document.getElementById("bid").value = "";
                    document.getElementById("numEnterMaxBid").value = "";

                    setModalValidationMessageType(2);
                    setModalReview(false);
                    setModalValidation(true);

                    e.target.value = "failed";
                    e.preventDefault();

                } else {
                    let maxBid = Number(document.getElementById("numEnterMaxBid").value.replaceAll(",","").replaceAll("$",""));
                    e.amount = FinalBid.max > 0 ? FinalBid.max : FinalBid.bid;
                    e.target.value = "";
                }
            } catch (error) {
                if(error.name === "HandcashInsufficientFunds"){
                    //document.getElementById("btnUpdateMaxBid").innerHTML = "Update Max Bid";
                    //restore original max bid
                    //document.getElementById("numEnterMaxBid").value = slebrityAuction.bids[0].maxBid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
                    setModalReview(false);
                    setShowInsufficentFundsModal(true);
                } else {
                    setModalValidationTitle("Handcash Error");
                    setModalValidationMessage(`Handcash error: ${error.response.data.message}`);
                    setModalValidationMessageType(99);
                    setModalReview(false);
                    setModalValidation(true);
                }
                e.target.value = "failed";
            }
        } else {
            e.preventDefault();
        }   
    }

    const hcPostProcess = async(e) => {
        e.persist();
        const result = await addBid(
            FinalBid.bid,
            FinalBid.paymail,
            FinalBid.max,
            bidB,
            slebrityAuction.bids[0].word,
            slebrityAuction.auction.wordBountyAuctionId
        );

        slebrityAuction.auction = result.auction;
        slebrityAuction.bids = result.bids;
        document.getElementById("highBid").innerHTML = "$" + Number(result.bids[0].bid).toFixed(2).toLocaleString("en-US");
        document.getElementById("bidder").innerHTML = getFormattedPaymail(localStorage.getItem("senderPaymail"));
        document.getElementById("bid").value = "";
        if((result.bids[0].maxBid) > 0 && result.bids[0].bid !== result.bids[0].maxBid){
            document.getElementById("numEnterMaxBid").value = "$" + Number(result.bids[0].maxBid).toFixed(2).toLocaleString("en-US");;
        }
        //Reimburse previous bid
        let reimbursementAmount = maxA > 0 ? maxA : bidA;
        let BidA = new SLebrityBid(bidA, maxA, paymailA);
        const reimburse = reimburseBidder(paymailA, reimbursementAmount, slebrityAuction.bids[0].word);
        
        //show the Update Max Bid button
        document.getElementById("btnUpdateMaxBid").style.display = "inline";

        setModalBidAcceptance(true);
        setModalReview(false);

        postPaymentLog(e.transactionId, userBidData.bid, "SLictionary@handcash.io", localStorage.getItem("senderPaymail"), "slebrity-bid", slebrityAuction.bids[0].word)
            .then(doc => {
                
            })
            .catch(err => {

            });

    }

    const hcPreProcessUpVote = async(e) => {
        e.persist();
        let slebrityAmount = Number(document.getElementById("numUpVote").value.replaceAll("$","").replaceAll(",",""));
        e.amount = 0.0149 + slebrityAmount;
    }
    
    const hcPostProcessUpVote = async(e) => {
        e.persist();
        
        let amount = Number(document.getElementById("numUpVote").value.replaceAll("$","").replaceAll(",",""));
        let txtWordToDefine = document.getElementById("txtWordToDefine").value;
        let slebrityName = document.getElementById("txtNominationName").value;

        if(amount <=0){
            e.preventDefault();
            setModalValidationMessageType(6);
            setModalValidationTitle("Invalid Amount");
            setModalValidation(true);
             
        } else {
            await putSLebrityAmount(slebrityDocId, amount, localStorage.getItem("senderPaymail", txtWordToDefine, slebrityName));
            let newDataSource = loadfutureSlebrityData();
            setDataSource(newDataSource);
            setShowVoteModal(false);
        }
        let slebrityAmount = Number(document.getElementById("txtNomAmount").value.replaceAll("$","").replaceAll(",",""));
        slebrityAmount += 0.0149;
        postPaymentLog("", slebrityAmount, "SLictionary@handcash.io", localStorage.getItem("senderPaymail"), "slebrity-upvote", txtWordToDefine);
    }

    const hcPreProcessNominate = async(e) => {
        e.persist();
        let slebrityAmount = Number(document.getElementById("txtNomAmount").value.replaceAll("$","").replaceAll(",",""));
        e.amount = 0.0149 + slebrityAmount;
        let slebrityWord = document.getElementById("txtWordToDefine").value;
        let slebrityName = document.getElementById("txtNominationName").value;
        
        if(slebrityAmount === 0 || slebrityName === "" || slebrityWord === ""){
            setModalValidationMessageType(5);
            setModalValidationTitle("Missing Fields");
            setNomModalValidation(true);
            e.preventDefault();
        } else {
            await postSLebrity(slebrityName, slebrityWord, slebrityAmount, localStorage.getItem("senderPaymail"));
            let newDataSource = loadfutureSlebrityData();
            setDataSource(newDataSource);
            document.getElementById("txtNomAmount").value = "$0.01";
            document.getElementById("txtWordToDefine").value = "";
            document.getElementById("txtNominationName").value = "";
        }
    }

    const handleCloseInsufficientFundsModal = (e) => {
        setShowInsufficentFundsModal(false);
        setModalReview(false);
    }
    
    const hcPostProcessNominate = async(e) => {
        e.persist();
        let slebrityAmount = Number(document.getElementById("txtNomAmount").value.replaceAll("$","").replaceAll(",",""));
        let txtWordToDefine = document.getElementById("txtWordToDefine").value;
        slebrityAmount += 0.0149;
        postPaymentLog("", slebrityAmount, "SLictionary@handcash.io", localStorage.getItem("senderPaymail"), "slebrity-nomination", txtWordToDefine);

    } 

    const myOnPaymentCallback = async(payment) => {
        let slebrityWord = document.getElementById("txtWordToDefine").value;
        let slebrityName = document.getElementById("txtNominationName").value;

        await postSLebrity(slebrityName, slebrityWord, slebrityAmount, localStorage.getItem("senderPaymail"));
        let newDataSource = loadfutureSlebrityData();
        setDataSource(newDataSource);
        document.getElementById("txtNomAmount").value = "";
        document.getElementById("txtWordToDefine").value = "";
        document.getElementById("txtNominationName").value = "";

        let slebrityAmount = Number(document.getElementById("txtNomAmount").value.replaceAll("$","").replaceAll(",",""));
        let txtWordToDefine = document.getElementById("txtWordToDefine").value;
        slebrityAmount += 0.0149;
        postPaymentLog("", slebrityAmount, "SLictionary@handcash.io", localStorage.getItem("senderPaymail"), "slebrity-nomination", txtWordToDefine);
    }

    const closeModal = () => {
        setShowVoteModal(false);
    }

    const handleCloseValidationModal = (e) => {
        setModalValidation(false);
    }

    const handleCloseNomValidationModal = (e) => {
        setNomModalValidation(false);
    }

    const handleCloseBidAcceptanceModal = (e) => {
        setModalBidAcceptance(false);
    }

    const modalReviewOnClick = (e) => {
        setModalReview(true);
    }

    const modalReviewCloseOnClick = (e) => {
        setModalReview(false);
    }

    const reviewBid = (e) => {

        if(auctionIsCompleted){
            window.location.assign("/SLebrity");
        }

        userBidData.bid = Number(document.getElementById("bid").value.replaceAll(",","").replaceAll("$",""));
        userBidData.maxBid = Number(document.getElementById("numEnterMaxBid").value.replaceAll(",","").replaceAll("$",""));

        if(userBidData.bid === 0 && userBidData.maxBid === 0){
            setModalValidationMessageType(0);
            setModalValidationTitle("Missing Bid");
            setModalValidation(true);
            e.preventDefault();
        } else if(userBidData.bid > userBidData.maxBid &&
            document.getElementById("numEnterMaxBid").value.trim().length > 0){
            setModalValidationMessageType(4);
            setModalValidationTitle("Invalid Max Bid");
            setModalValidation(true);
            e.preventDefault();
        } else {
            setModalReview(true);
        }

    }

    const setBid = () => {
        let eleBid = document.getElementById("bid");
        let highBid = Number(document.getElementById("highBid").innerHTML.replaceAll(",","").replaceAll("$",""));
        
        userBidData.bid = Number(eleBid.value.replaceAll(",","").replaceAll("$",""));

        if(highBid === 0 && eleBid.value.length === 0){
            userBidData.bid = 0.01; //if initial bid is 0 then change it to 0.01
        } else {
            if(userBidData.bid <= slebrityAuction.bids[0].bid){
                userBidData.bid = slebrityAuction.bids[0].bid * bidIncreaseFactor; 
            } else {
                userBidData.bid = userBidData.bid * bidIncreaseFactor; 
            }
        }

        if(userBidData.bid < 1.00){
            userBidData.bid = roundUpTwoDecimals(userBidData.bid);
        }

        eleBid.value = userBidData.bid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});   
    }

    const setNomAmount = () => {
        let eleNomAmount = document.getElementById("txtNomAmount");
        let amount = Number(eleNomAmount.value.replaceAll("$","").replaceAll(",",""));
        let newAmount = amount + 0.01; //usd
        eleNomAmount.value = newAmount.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
    }

    const getFormattedPaymail = (paymail) => {
        let returnPaymail;
        if(paymail.length > 0){
            let arr = paymail.split("@");
            if(arr.length === 1){
                returnPaymail = paymail.replaceAll("$","") + "@handcash.io";
            } else {
                if(arr[1].toLowerCase() === "handcash.io") {
                    returnPaymail = "$" + arr[0];
                } else {
                    returnPaymail = paymail;
                }
            }
        } else {
            returnPaymail = "";
        }

        return returnPaymail;
    }

    const updateHighBid = async() => {
        slebrityAuction = await getLastestAuction();
        let eleHighBid = document.getElementById("highBid");
        let eleBidder = document.getElementById("bidder");
        let currentHighBid = Number(eleHighBid.innerText.replace("$",""));
        let currentBidder = eleBidder.innerText;
        let eleUpdateMaxBidButton = document.getElementById("btnUpdateMaxBid");

        if(currentHighBid !== Number(slebrityAuction.bids[0].bid.toFixed(2))){
            eleUpdateMaxBidButton.style.display = "none";
            eleHighBid.style.color = "orange";
            eleBidder.style.color = "orange";

            eleHighBid.innerText = slebrityAuction.bids[0].bid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
            eleBidder.innerText = getFormattedPaymail(slebrityAuction.bids[0].bidderPaymail);
            await new Promise(r => setTimeout(r, 1000));
            eleHighBid.style.color = "lightgreen";
            eleBidder.style.color = "lightgreen";
            
        }
    }

    const bidOnFocus = async(e) => {
        let result = await updateHighBid();
    }

    const bidOnKeyDown = (event) => {
        var hasDecimalPoint = document.getElementById(event.target.id).value.indexOf(".") !== -1;
        if ((event.key !== "0"
            && event.key !== "1"
            && event.key !== "2"
            && event.key !== "3"
            && event.key !== "4"
            && event.key !== "5"
            && event.key !== "6"
            && event.key !== "7"
            && event.key !== "8"
            && event.key !== "9"
            && event.key !== "."
            && event.key !== "Backspace"
            && event.key !== "Delete"
            && event.key !== "ArrowLeft"
            && event.key !== "ArrowRight"
        )
            ||
        (hasDecimalPoint && event.key === ".") 
        )
        {
            event.preventDefault();
            
            return false;
        } else {
            return true;
        }
    }

    const maxBidOnFocus = async(e) => {
        document.getElementById("imgGreenCheckMark").style.display = "none";
        let result = await updateHighBid();
    }

    const roundUpTwoDecimals = (x) => {
        let s;
        let returnVal;

        if(typeof(x) === "number"){
            s = x.toString().split('.');
        } else if(typeof(x) === "string") {
            s = x.split('.');
        } else {
            throw new Error("Invalid argument for roundUpTwoDecimals");
        }

        let firstDecimalString = "";
        let secondDecimalString = "";
        let firstPart = "";
        
        if(s[1].length >= 3){
            firstPart = s[0];
            firstDecimalString = s[1].substring(0,1)
            secondDecimalString = s[1].substring(1,2)
            
            let totalNumber = firstPart + "." + firstDecimalString + secondDecimalString + "9";
            return typeof(x) === "number" ? Number(Number(totalNumber).toFixed(2)) : Number(totalNumber).toFixed(2);             
        } else {
            return x;
        }

    }

    const slebritySubmitOnMouseOver = async(e) => {
        let result = await updateHighBid();
    }

    const bidOnBlur = (e) => {
        if(document.getElementById("bid").value.length > 0){
            let bid = Number(document.getElementById("bid").value.replaceAll(",","").replaceAll("$",""));
            document.getElementById("bid").value = bid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
        }
    }

    const upVoteOnBlur = (e) => {
        let amount = Number(document.getElementById("numUpVote").value.replaceAll(",","").replaceAll("$",""));
        if(amount <= 0){
            alert("The Up Vote value must be greater than 0");
            e.preventDefault();
            document.getElementById("numUpVote").value = "";
        } else {
            document.getElementById("numUpVote").value = amount.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
        }
    }

    const maxBidOnBlur = (e) => {
        if(document.getElementById("numEnterMaxBid").value.length > 0){
            let maxBid = Number(document.getElementById("numEnterMaxBid").value.replaceAll(",","").replaceAll("$",""));
            document.getElementById("numEnterMaxBid").value = maxBid.toLocaleString("en-US",{style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
        }
    }

    const nominationOnBlur = (e) => {
        let nominationAmount = Number(document.getElementById("txtNomAmount").value.replaceAll(",","").replaceAll("$",""));
        document.getElementById("txtNomAmount").value = nominationAmount.toLocaleString("en-US",{style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigitsNomination, maximumFractionDigits: minimumFractionDigitsNomination});
    }

    const setDetailsAuctionControls = (e) => {
        if(slebrityAuction.bids[0].bidderPaymail === localStorage.getItem("senderPaymail")){
            document.getElementById("numEnterMaxBid").value = slebrityAuction.bids[0].maxBid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
            document.getElementById("btnUpdateMaxBid").style.display = "inline";
        }
        document.getElementById("auction-word").innerHTML = slebrityAuction.auction.word;
        document.getElementById("auction-celebrity-name").innerHTML = slebrityAuction.auction.celebrityName;

        if(!auctionIsCompleted){
            document.getElementById("bidder").innerHTML = getFormattedPaymail(slebrityAuction.bids[0].bidderPaymail);
            document.getElementById("highBid").innerHTML = slebrityAuction.bids[0].bid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
            document.getElementById("biddingEnds").innerHTML = dateFormat(slebrityAuction.auction.endDatetime,"mmmm dS, yyyy h:MM:ss TT");
        } else {
            document.getElementById("completedWinningBid").innerHTML = slebrityAuction.bids[0].bid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
            document.getElementById("completedWinningBidder").innerHTML = getFormattedPaymail(slebrityAuction.bids[0].bidderPaymail);
        }
    }

    const setAuctionIsCompletedAuctionControls = (e) => {
        document.getElementById("auction-word").innerHTML = slebrityAuction.auction.word;
        document.getElementById("auction-celebrity-name").innerHTML = slebrityAuction.auction.celebrityName;

        document.getElementById("completedWinningBid").innerHTML = slebrityAuction.bids[0].bid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
        document.getElementById("completedWinningBidder").innerHTML = getFormattedPaymail(slebrityAuction.bids[0].bidderPaymail);
    }

    const updateMaxBidOnClick = async(e) => {
        try{
            let caption = document.getElementById("btnUpdateMaxBid").innerHTML; 
            if(caption === "Processing..."){
                e.preventDefault();
            } else {

                //Set caption to Processing to prevent unintended submissions
                document.getElementById("btnUpdateMaxBid").innerHTML = "Processing..."; 

                let newMaxBid = Number(document.getElementById("numEnterMaxBid").value.replaceAll(",","").replaceAll("$",""));

                //new maxBid must be greater than the current maxBid
                if(newMaxBid <= slebrityAuction.bids[0].maxBid){
                    throw new HandcashInvalidMaxBid();
                }

                let balance = await getBalance(localStorage.getItem("hcAuthToken"));
                if(balance.spendableFiatBalance <= newMaxBid){
                    //eleButton.innerHTML = caption;
                    throw new HandcashInsufficientFunds();//`
                }
                //The user has already paid for a max bid. If they increase the bid, they 
                //have to pay the difference
                //let diffMaxBid = newMaxBid - slebrityAuction.bids[0].maxBid;

                slebrityAuction = await getLastestAuction();
                //newMaxBid cannot be less than or equal to current maxBid
                if(newMaxBid <= slebrityAuction.bids[0].maxBid){
                    document.getElementById("numEnterMaxBid").value = slebrityAuction.bids[0].maxBid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
                    document.getElementById("btnUpdateMaxBid").innerHTML = "Update Max Bid";
                    throw new Error("Max bid must be higher than existing max bid.");
                }
                //if there's an existing maxBid, reimburse it.
                if(slebrityAuction.bids[0].maxBid > 0){
                    const reimburseMaxBid = reimburseBidder(slebrityAuction.bids[0].bidderPaymail, slebrityAuction.bids[0].maxBid, slebrityAuction.bids[0].word);
                } else {
                //if there isn't an existing maxBid, reimburse the bid.
                    const reimburseBid = reimburseBidder(slebrityAuction.bids[0].bidderPaymail, slebrityAuction.bids[0].bid, slebrityAuction.bids[0].word);
                }

                //charge for the new maxBid
                const paymentResult = await postPayment(
                    localStorage.getItem("hcAuthToken"),
                    "SLebrity update max bid",
                    config.handcash.paymentDestination,
                    "USD",
                    newMaxBid
                );

                await putSlebrityMaxBid(slebrityAuction.bids[0].docId, newMaxBid);

                document.getElementById("imgGreenCheckMark").style.display = "none";


                document.getElementById("btnUpdateMaxBid").innerHTML = "Update Max Bid";
            
                document.getElementById("imgGreenCheckMark").style.display = "inline";
                await new Promise(r => setTimeout(r, 2000));
                document.getElementById("imgGreenCheckMark").style.display = "none";

            }
        } catch (error) {
            if(error.name === "HandcashInsufficientFunds"){
                document.getElementById("btnUpdateMaxBid").innerHTML = "Update Max Bid";
                //restore original max bid
                document.getElementById("numEnterMaxBid").value = slebrityAuction.bids[0].maxBid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});
                setModalReview(false);
                setShowInsufficentFundsModal(true);
            } else if(error.name === "HandcashInvalidMaxBid"){
                document.getElementById("btnUpdateMaxBid").innerHTML = "Update Max Bid";
                document.getElementById("numEnterMaxBid").value = slebrityAuction.bids[0].maxBid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits});

            }
        }
    }

    const getValidationMessage = (validationMessageType) => {
        var validationErrorMessage;
        switch(validationMessageType){
            case 0:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                <br/>You are missing a value for your bid.
                                <br/>Either the bid or the max bid must be a number greater than 0.<br/><br/>
                                
                                Enter a value for your bid and resubmit the form.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    </>
                )
                break;
            case 1:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                Your max bid must be larger than your bid<br/><br/>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
            case 2:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                You have been outbid by another user.<br/><br/> 
                                You have not been charged for the transaction<br/><br/>
                                Increase the size of your bid to become the highest bidder.
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
            case 3:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                You already have the high bid.<br/>
                                Check back later to see its status. 
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
            case 4:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                    Max bid must be greater than the bid.
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
            case 5:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                    You must enter a SLebrity Name
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;

            case 6:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                    The Up Vote Amount must be <br/>greater than $0
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
            case 7:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    {/* <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/> */}
                                </td>   
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                    Wo WOAH Kimosabe!  Not yet. 
                                    <br/><br/>
                                    First auction will start HERE on the Ides of March (the 15th).
                                    Press the Nominations tab above to vote your pennies who you’d like that to be.  We will 
                                    go after the celebrity at the top of the list with the most votes first and work our way 
                                    down the list each month.  
                                    <br/><br/>
                                    Celebrities who don’t onboard will stay ranked here until 
                                    he/she agrees.  A winning celebrity name will likely also turn into a Word Bounty— if the 
                                    SLebrity agrees to the terms.                                
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
            case 8:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                    You must log in to bid on SLebrity 
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
    
            case 99:
                validationErrorMessage = (
                    <>
                    <div>
                        <table>
                            <tr>
                                <td>
                                    <img class="center" src={warningIcon} style={{width:"100px"}} alt=""/>
                                </td>
                                <td style={{fontSize: "14pt", padding: "2px"}}>
                                    <br/>
                                    {modalValidationMessage}
                                </td>
                            </tr>
                        </table>
                    </div>
                    </>
                )
                break;
    
        }

        return validationErrorMessage;
    }

    const getAuctionHeadingJSX = () => {
        return (
            <Container className="text-center" style={{width: "800px"}}>
                <Row  style={{padding: "10px"}}>
                    <Col>
                        <h2 id="auction-word" className="slebrity-h1" style={{color: "white"}}></h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className="slebrity-h3" style={{margin: "-10px"}}>as defined by</h3>
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row>
                    <Col style={{verticalAlign: "top"}}>
                        <h2 id="auction-celebrity-name" className="slebrity-h2" style={{display: "inline", whiteSpace: "nowrap"}}></h2>&nbsp;
                        <SLicTooltip verticalAlign="top" width="25px" bio={bio}/>
                    </Col>
                </Row>
            </Container>
        )
    }

    const getAuctionComingSoonJSX = () => {
        return (
            <>
            <div style={{ 
                padding:'5px',
                borderRadius:'15px',
               width:'800px',
                background:'transparent',
                color:'white'}}>
                <br/>
                <p className="text-center" style={{fontSize: "30pt",color: "lightgoldenrodyellow"}}>
                First SLebrity Coming February 23, 2023. 
                </p>
                <br/>
                <p className="text-center" style={{fontSize: "20pt"}}>
                Click the “SLebrity Nomination” tab to get your SLebrity listed first!
                </p>
            </div>
            </>
        )
    }

    const getNoMoneybuttonMessageJSX = () => {
        return (
            <>
            <div  className="text-center">
                <br/>
                <p>
                    This auction don't take kindly to you<br/>Moneybutton folk
                </p>
                <br/>
                <img src={imgAuctioneer} style={{height: "35%", width: "35%"}}></img>
                <br/>
                <br/>
                <p>
                    Log back in with a Handcash wallet <br/>and then we'll do business
                </p>
            </div>
            </>
        )
    }

    const getNotLoggedInMessageJSX = () => {
        return (
            <>
            <div  className="text-center">
                <br/>
                <p>
                    This auction only serves<br/>Handcash folk
                </p>
                <br/>
                <img src={imgAuctioneer} style={{height: "35%", width: "35%"}}></img>
                <br/>
                <br/>
                <p>
                    Log into your Handcash wallet <br/>and then we'll do business
                </p>
            </div>
            </>
        )
    }
    
    const getAuctionDetailsJSX = () => {
        return (
            <>
            {getAuctionHeadingJSX()}
            <Container>
            <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col><span>Bidding ends:</span></Col>
                        <Col xs={5}><span id="biddingEnds"></span></Col><Col>{countdown}</Col><Col>&nbsp;</Col>
                </Row>    
            </Container>
            <Container><Row><Col>&nbsp;</Col></Row></Container>
            <Container>
                <Row>
                    <Col xs={3}><span>Current high bid:</span></Col><Col xs={2}><span id="highBid" style={{color: "lightgreen", fontWeight: "bold", fontSize: "16pt"}}></span></Col>
                    <Col xs={1}><span>Bidder:</span></Col><Col><span id="bidder"></span></Col>
                </Row>
                </Container>
                <br/>
                <Container style={{width:750}}>
                <Row>    
                    <Col xs={3}><span>Your bid:</span></Col>
                    <Col >

                    <InputGroup className="mb-3"  style={{width: "250px"}}>
                        <Form.Control 
                            id="bid"
                            style={{zIndex: "1"}}
                            placeholder=""
                            aria-label=""
                            aria-describedby="bid"
                            onBlur={bidOnBlur}
                            onFocus={bidOnFocus}
                            onKeyDown={bidOnKeyDown}
                        />
                        <Button variant="primary" 
                                style={{position: "relative", top: "-10px", left: "-13px"}}
                                onClick={setBid}
                                onMouseOver={slebritySubmitOnMouseOver}
                        >
                        +{config.app.SLebrity.percentageIncrease}%
                        </Button>
                    </InputGroup>
                    </Col>
                </Row>
                <Row>    
                    <Col xs={3}><span>Your max bid:</span></Col>
                    <Col >
                        <table><tbody><tr>
                        <td><NumericInput 
                            id="numEnterMaxBid"
                            currencySymbol=""
                            currencySymbolColor="white"
                            min="0"
                            minMessage=""
                            onBlur={maxBidOnBlur}
                            onfocus={maxBidOnFocus}
                        /></td>
                        <td>
                            <Button id="btnUpdateMaxBid" variant="primary" onClick={updateMaxBidOnClick} onMouseOver={slebritySubmitOnMouseOver}
                                style={{display: "none"}}>Update Max Bid</Button>
                        </td>
                        <td>
                            <img id="imgGreenCheckMark" src={greenCheckMark} style={{width: "37px", height: "auto", backgroundColor: "black", display: "none"}}/>
                        </td>

                        </tr></tbody></table>
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;
                    </Col>
                </Row>
                <Row className="slebrity-h1">
                    <Col>
                        <Button className="slebrity-submit" variant="primary" onClick={reviewBid} onMouseOver={slebritySubmitOnMouseOver}>REVIEW BID</Button>
                    </Col>
                </Row>
                   <Modal show={modalToggle} onHide={closeModal} >
                    <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                    <Modal.Title id="modalTitle" style={{color:"white",backgroundColor:"#202020"}}>SLebrity Alert</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                        <div></div>
                    </Modal.Body>
                </Modal>
                <Modal show={modalValidation} backdrop="static" onHide={handleCloseValidationModal} >
                        <Modal.Header closeButton style={{color:"black",backgroundColor:"yellow"}}>
                            <Modal.Title style={{color:"black",backgroundColor:"yellow"}}>{modalValidationTitle}</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                            <div id="modalValidation" style={{color:"white",backgroundColor:"#202020"}}>
                                <div>
                                    {getValidationMessage(modalValidationMessageType)}
                                    <div className="text-center">
                                        <Button 
                                            id="txtClose"
                                            variant="outline-light" 
                                            onClick={handleCloseValidationModal}>
                                                OK
                                            </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={modalReview} backdrop="static" onHide={modalReviewCloseOnClick} style={{border: "2px"}}>
                        <Modal.Header closeButton style={{color:"black",backgroundColor:"black"}}>
                            <Modal.Title style={{color:"white",backgroundColor:"black"}}>Review Your Bid</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                            <div id="modalValidation" style={{color:"white",backgroundColor:"#202020"}}>
                            <p>
                                Please review your bid. Funds are paid at the time the bid is placed. If you do not win the auction, 
                                your funds will be returned. 
                            </p>
                            <Container style={{display: "inline"}}>
                                <Row  style={{padding: "10px"}}>
                                    <Col>
                                        <h2 id="auction-bid-review" className="slebrity-h3" style={{color: "white"}}>Your Bid:</h2>
                                    </Col>
                                    <Col>
                                        <h2 className="slebrity-h3" style={{color: "white"}}>{userBidData.bid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits})}</h2>
                                    </Col>
                                </Row>
                                <Row style={{padding: "10px"}}>
                                    <Col>
                                        <h2 className="slebrity-h3" style={{color: "white"}}>Your Max Bid:</h2>
                                    </Col>
                                    <Col>
                                    <h2 id="auction-bid-max-bid-review" className="slebrity-h3" style={{color: "white"}}>{userBidData.maxBid.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: minimumFractionDigits})}</h2>
                                    </Col>
                                </Row>
                                <Row><Col>&nbsp;</Col></Row>
                                <Row className="text-center">
                                    <Col colSpan="2">
                                        <HcButton
                                            id="btnSubmitBid" 
                                            caption={"SUBMIT BID"}
                                            captionProcessing={"PROCESSING..."} 
                                            captionCompleted={"PROCESSING..."}
                                            paymentDescription={"SLictionary SLebrity Bid"}
                                            checkBalance={true}
                                            amount={amountG}
                                            currency="USD"
                                            height="50px"
                                            left= "-13px"
                                            top= "-10px"
                                            borderRadius="30px"
                                            width="175px"
                                            paddingLeft="10px"
                                            postProcess={hcPostProcess}
                                            preProcess={hcPreProcess}
                                        />
                                        &nbsp;
                                        <Button id="auction-bid-cancel" onClick={modalReviewCloseOnClick}>CANCEL</Button>
                                    </Col>
                                </Row>
                            </Container>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={modalBidAcceptance} backdrop="static" onHide={handleCloseBidAcceptanceModal} >
                        <Modal.Header closeButton style={{color:"black",backgroundColor:"black"}}>
                            <Modal.Title style={{color:"white",backgroundColor:"black"}}>Bid Confirmation</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                            <div style={{color:"white",backgroundColor:"#202020", alignItems: "center"}}>
                                <div style={{alignItems: "center"}}>
                                    <p>
                                        CONGRATULATIONS! Your bid has been accepted. You are now the high bidder.
                                    </p>
                                    <div className="text-center">
                                        <Button
                                            id="txtClose"
                                            variant="outline-light" 
                                            onClick={handleCloseBidAcceptanceModal}>
                                                OK
                                            </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
        </Container>

        <Modal id="modalInsufficientFunds" show={showInsufficentFundsModal} onHide={handleCloseInsufficientFundsModal} backdrop="static" >
            <Modal.Header closeButton style={{color:"black",backgroundColor:"yellow"}}>
                <Modal.Title style={{color:"black",backgroundColor:"yellow"}}>Insufficient Funds</Modal.Title>
            </Modal.Header> 
            <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                <div id="modalInsufficientFunds" style={{color:"white",backgroundColor:"#202020"}}>
                    <p style={{color: "white", fontSize: "14pt"}}>
                        Either you don't have enough bsv in your wallet
                        or your Handcash daily spend limit is too low.
                    </p>
                    <br/>
                    <div>
                    <a
                    href="https://docs.handcash.io/docs/spendable-balance"
                    target="_blank"
                    rel="noreferrer"
                    >Click for more information</a>     
                        {/* <Link onClick={openHandcashWindow}>Click for more information</Link> */}
                    </div>
                    <div className="text-center">
                    <Button id="modalClose"
                        variant="outline-light" 
                        onClick={handleCloseInsufficientFundsModal}>OK</Button>
                    </div>
                </div>                
            </Modal.Body>
        </Modal>
        </>
        )
    }

    var mbElementNominate = localStorage.getItem("hcAuthToken") === null ? (
        <div id="moneybutton">
            <MoneyButton
            to="selflearningdictionary@moneybutton.com"
            type="tip"
            amount="0.0090"
            editable = "true"
            currency="USD"
            //onPayment={myOnPaymentCallback(payment)}
            label="to Nominate"
            //buttonId={word}
            buttonData="{app: SLictionary}"
            //opReturn="SLic"
            />
        </div>
    ) 
    : 
    (
        <>
        <HcButton 
            id="hcNominate"
            caption="NOMINATE"
            captionProcessing="PROCESSING..." 
            captionCompleted="NOMINATE"
            paymentDescription={"SLebrity Nominate"}
            checkBalance={true}
            amount={amountG}
            currency="USD"
            height="50px"
            top= "-10px"
            borderRadius="30px"
            width="270px"
            paddingLeft="10px"
            postProcess={hcPostProcessNominate}
            preProcess={hcPreProcessNominate}
        />
        </>    
    )

    var mbElementUpVote = localStorage.getItem("hcAuthToken") === null ? (
        <div id="moneybutton">
            <MoneyButton
            to="selflearningdictionary@moneybutton.com"
            amount="0.0090"
            currency="USD"
            //onPayment= {myOnPaymentCallback}
            label="to Vote"
            //buttonId={word}
            buttonData="{app: SLictionary}"
            //opReturn="SLic"
            />
        </div>
    ) : 
    (
        <>
        <HcButton 
            id="hcUpVote"
            caption={"UP VOTE!"}
            captionProcessing={"PROCESSING..."} 
            captionCompleted={"PROCESSING..."}
            paymentDescription={"SLebrity Up Vote"}
            checkBalance={true}
            amount={0.0096}
            currency="USD"
            height="50px"
            top= "-10px"
            borderRadius="30px"
            width="190px"
            paddingLeft="10px"
            postProcess={hcPostProcessUpVote}
            preProcess={hcPreProcessUpVote}
        />
        </>    
    )

    const getAuctionIsCompletedJSX = () => {
        return(
            <>
            {getAuctionHeadingJSX()}
            <div>&nbsp;</div>
            <Container style={{alignContent: "right", width: "400px"}}>
                <Row >
                    <Col xs lg="5" >Status:</Col><Col id="completedStatus" xs lg="5" style={{color:"lightgreen"}}>Completed</Col>
                </Row>
                <Row >
                    <Col xs lg="5">Winning bid:</Col><Col id="completedWinningBid" xs lg="5">$100,000</Col>
                </Row>
                <Row >
                    <Col xs lg="5">Winning bidder:</Col><Col id="completedWinningBidder" xs lg="5">$johndifelice</Col>
                </Row>
            </Container>
            </>
        )
    }

    const getMainJSX = (tabName) => {
        let jsx;
        let tabNameTemp;
        tabNameTemp = config.app.SLebrity.showAuction ? tabName : "NOMINATION";
        switch(tabNameTemp) {
            case "AUCTION":
                if(!isMuggleMode){
                    if(auctionIsCompleted){
                        jsx = getAuctionIsCompletedJSX();
                    } else {
                        if(localStorage.getItem("senderPaymail").indexOf("moneybutton.com") === -1){ //not moneybutton
                            jsx = getAuctionDetailsJSX();
                        } else { 
                            jsx = getNoMoneybuttonMessageJSX();
                        }
                    }
                } else {
                    jsx = getNotLoggedInMessageJSX();
                }   
                break;
            case "NOMINATION":
                jsx = getFutureSlebrityJSX();
                break;
            case "LEADERBOARD":
                jsx = getLeaderboardJSX();
                break;
        }

        return jsx;
    }

    const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
        if(isMuggleMode){
            alert("You must log in to Upvote a SLebrity")
        } else {
            setSelected(selectedMap);
            setSlebrityName(data[0].slebrityName);
            setSlebrityDocId(data[0].slebrityDocId)        
            setWord(data[0].slebrityWord);
            setShowVoteModal(true);
        }
      }, [])

    const nominationOnClick = (e) => {
        setTabName("NOMINATION");
    }

    const auctionOnClick = (e) => {
        setTabName("AUCTION");
    }

    const leaderboardOnClick = (e) => {
        setTabName("LEADERBOARD");
    }

    const tosOnClick = (e) => {
        props.history.push("/SLebrityTOS");
        e.preventDefault();
    }

    const getFutureSlebrityJSX = () => {
        return(
            <Container>
                <Row>
                    <Col className="slebrity-h2" style={{color: "#38CB7C"}}>Nominate your favorite SLebrity with BitCoin!</Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row>
                    <Col>
                    <div className="slebrity-h3" style={{color: "lightgray", textAlign: "left"}}>
                    Upvote a celebrity below by clicking the name, or nominate a new in the section below the list
                    </div>
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row>
                    <Col>
                    <div className="slebrity-h3 text-center" style={{color: "gray", fontSize: "12pt"}}>
                    <a style={{color: "lightgoldenrodyellow", fontWeight: "bold", fontSize:"10pt", fontFamily: "Arial"}} onClick={tosOnClick} href="" >{config.app.verbiage.SLebrityPage.termsOfService}</a>
                    </div>
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row>
                <ReactDataGrid
                        idProperty="word"
                        multiSelect
                        selected={selected}
                        onSelectionChange={onSelectionChange}
                        style={gridStyle}
                        columns={futureSlebrityColumns}
                        dataSource={dataSource}
                        defaultLimit={10}
                        theme={"default-dark"}
                        showCellBorders = "horizontal"
                        showZebraRows={false}
                        scrollProps={scrollProps}
                        rowStyle={rowStyle}
                    />
                  
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row style={styleNominate}>
                    <Col className="slebrity-h3" style={{color: "white", textAlign: "left"}}>
                        Nominate a SLebrity:
                    </Col>
                </Row>
                <Row style={styleNominate}>
                    <Col>
                        <Container>
                            <Row>
                            <Col>
                                <InputGroup className="mb-3"  style={{width: "250px"}}>
                                    <Form.Control 
                                        id="txtNomAmount"
                                        style={{zIndex: "1"}}
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby="txtNomAmount"
                                        onBlur={nominationOnBlur}
                                    />
                                    <Button variant="primary" 
                                            style={{position: "relative", top: "-10px", left: "-13px"}}
                                            onClick={setNomAmount}
                                    >
                                    + $0.01
                                    </Button>
                                </InputGroup>

                                </Col>
                                <Col><input id="txtNominationName" type="text" placeholder="SLebrity name" /></Col>
                                <Col><input id="txtWordToDefine" type="text" placeholder="Word to define" /></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row style={styleNominate}>
                    <Col>
                        <div className="text-center">{mbElementNominate}</div>
                    </Col>
                </Row>
                <Modal show={nomModalValidation} backdrop="static" onHide={handleCloseNomValidationModal} >
                        <Modal.Header closeButton style={{color:"black",backgroundColor:"yellow"}}>
                            <Modal.Title style={{color:"black",backgroundColor:"yellow"}}>{modalValidationTitle}</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                            <div id="modalValidation" style={{color:"white",backgroundColor:"#202020"}}>
                                <div>
                                    <div>
                                        You are missing a field required to nominate a SLebrity. 
                                        <br/><br/>
                                        All fields are required. The amount must also be greater than zero.
                                    </div>
                                    <div className="text-center">
                                        <Button 
                                            id="txtClose"
                                            variant="outline-light" 
                                            onClick={handleCloseNomValidationModal}>
                                                OK
                                            </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
            </Container>
        )
    }
    const getVoteModalJSX = (show, hideCallback, title, mbElement) => {
        return(
        <Modal show={show} onHide={hideCallback} >
        <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
        <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>{title}</Modal.Title>
        </Modal.Header> 
        <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
            <div id="modalVote" style={{color:"white",backgroundColor:"#202020"}}>
                <Container style={{padding: "10px"}}>
                    <Row>
                        <Col style={{fontSize: "11pt"}}>
                        <p>By upvoting a SLebrity with BSV, you increase his or her rank and increase the likelihood of
                        an auction dedicated to him or her.</p><br/>
                        <p>All funds accumulated by a SLebrity in this way will fund part of the resulting word bounty.</p>
                        </Col>
                    </Row>
                    <Row><Col>&nbsp;</Col></Row>
                    <Row>
                        <Col className="text-center slebrity-h2"style={{fontSize: "30pt"}}>
                            {slebrityName}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center slebrity-h2"style={{fontSize: "20pt"}}>
                            {`"${word}"`}
                        </Col>
                    </Row>
                    <Row><Col>&nbsp;</Col></Row>
                    <Row>
                        <Col className="text-center">
                            <NumericInput
                                id="numUpVote"
                                currencySymbol=""
                                currencySymbolColor="white"
                                onBlur={upVoteOnBlur}
                                placeholder="Amount"
                            />
                        </Col>    
                    </Row>
                    <Row><Col>&nbsp;</Col></Row>
                    <Row style={canVote}>
                        <Col className="text-center">  
                        {mbElement}
                        </Col>
                    </Row>
            </Container>

            </div>
        </Modal.Body>
        </Modal>
        )
    }

    const getLeaderboardJSX = () => {
        return (
            <SLebrityLeaderboard />
        );
    }
    
    return (  
        // <div>Nominate your favorite SLebrity with BitCoin!</div> 
        <div id="divMain" style={{display: "none"}}>
            <div className="text-center">
                {config.app.SLebrity.showAuction ? (<Button id="btnAuction" variant={tabName === "AUCTION" ? "light" : "outline-light"} className="tabButton" onClick={auctionOnClick}>SLebrity Auction</Button>) : (<div></div>)}
                {config.app.SLebrity.showNominations ? (<Button id="btnNomination" variant={tabName === "NOMINATION" ? "light" : "outline-light"} className="tabButton" onClick={nominationOnClick}>SLebrity Nominations</Button>) : (<div></div>)}
                {config.app.SLebrity.showLeaderBoard ? (<Button id="btnLeaderboard" variant={tabName === "LEADERBOARD" ? "light" : "outline-light"} className="tabButton" onClick={leaderboardOnClick}>Leaderboard</Button>) : (<div></div>)}
            </div>
            {getMainJSX(tabName)}
            {getVoteModalJSX(showVoteModal, closeModal, "SLebrity Up Vote", mbElementUpVote )}
        </div>

    )
}

export default SLebrity;