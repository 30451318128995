import axios from 'axios';
const config = require('../../config/config');

const getCurrentProfile = async(authToken) => {
    let snapshot = await axios.get(`${config.url.handcashService}/currentProfile/${authToken}`);
    console.log(snapshot.data);
    return snapshot.data;    
}

const getBalance = async(authToken) => {
    let snapshot = await axios.get(`${config.url.handcashService}/balance/${authToken}`);
    
    return snapshot.data;    
}

const postPayment = async(authToken, paymentDescription, paymentDestination, currency, amount) => {
    try{
        let snapshot = await axios.post(`${config.url.handcashService}/payment`,
            {
                "authToken": authToken, 
                "paymentDescription": paymentDescription, 
                "paymentDestination": paymentDestination,
                "currency": currency,
                "amount": amount
            });

        return snapshot.data;
    } catch (error) {
        return false;
    }
}


export 
    { getCurrentProfile, getBalance, postPayment }