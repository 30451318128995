import React from 'react';
import{useState, useEffect, useRef} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import audioImage from '../assets/audio-blue.png';
import LikeBulb from "./LikeBulb";
import "../components/Common/SimpleSlider.css"
import { decodeURL } from '../mods/slictionary-util-mod';

const config = require("../config/config");
const audioId = "audio_play_0";

const createAudioPlayer = () => {
    try{
        var audioPlayer = document.createElement("AUDIO");
        let parentContainer = document.getElementById("parentContainer")
        audioPlayer.id = "audio_play_0";
        audioPlayer.setAttribute("controls", "controls");
        audioPlayer.setAttribute("preload","none");
        audioPlayer.style.display="none";
        let source= document.createElement('source');
        if(audioPlayer.canPlayType('audio/mpeg;')){
        source.type = "audio/mp3";
        } else {
        source.type = "audio/mp4";
        }
        source.src= "";
        audioPlayer.appendChild(source);
        parentContainer.appendChild(audioPlayer);      
    } catch (error) {
        alert(error.toString());
        console.log(error.toString());
    }
}

const onAudioClick = (e) => {
    if(!document.getElementById("audio_play_0")){
        createAudioPlayer();
    }
    let audioPlayer = document.getElementById("audio_play_0");
    audioPlayer.src = e.target.id;
    audioPlayer.play();
}

const DefinitionPanel = (props) => {


    return(
        <Container id="parentContainer" className="text-center" key={props.item.definitionId}>
            <Row>
                <Col>
                <div className="text-center">
                    <div className="audioPanel">
                        
                        <img 
                            id={props.item.audioUrl}  
                            alt=""
                            className="audioImage liveimage"
                            src={audioImage}
                            onClick={onAudioClick}
                            style={{width:"30px",display:"none", marginTop:"-12px", display: props.item.audioUrl.length === 0 ? "none" : ""}} 
                        />
                    </div>
                </div>
                    <div>
                        <img    
                            className="imagePanel"
                            src={props.item.imageUrl}
                            // onDoubleClick=""
                            alt=""
                        />
                        <LikeBulb 
                            componentId={props.item.imageId}
                            author={props.item.author}
                            className="fa fa-lightbulb fa-1x column"
                            title="Vote"  
                            word={props.word}
                            arrBulbs={document.getElementsByClassName('fa fa-lightbulb fa-1x column')}
                            visible={props.item.imageUrl.length === 0 ? false : true}
                        />
                    </div>
                    <div>&nbsp;</div>
                    <div className="definitionPanel">
                        <div>
                            <span className="" id="sp0" >
                                <span id="partsOfSpeech"><em>{props.item.prevArrow}</em></span>
                                <p>
                                    <span style={{fontStyle: "italic"}}>[noun]</span><br/>
                                    {props.item.definitionText}
                                </p>
                                <LikeBulb 
                                    componentId={props.item.definitionTextId}
                                    author={props.item.author}
                                    className="fa fa-lightbulb fa-1x column"
                                    title="Vote"  
                                    word={props.word}
                                    arrBulbs={document.getElementsByClassName('fa fa-lightbulb fa-1x column')}
                                    visible={true}
                                />
                                <div>&nbsp;</div>
                                <div style={{fontSize:"12pt", fontStyle: "italic"}}>
                                    Used in a sentence:
                                </div>
                                <p>
                                    {props.item.useage}
                                    {/* {isUsageLikeBulbVisible ? UsageLikeBulb.getJSX(this.bulbClick) : (<></>)} */}
                                </p>
                                <div>
                                    <div>&nbsp;</div>
                                    <i>Etymology:</i>
                                </div>
                                <div 
                                    style={{fontSize:"12pt",color:"black"}}>
                                        <i>{props.item.etymology === undefined ? "" : props.item.etymology}</i>
                                        {/* {isEtymologyLikeBulbVisible ? EtymologyLikeBulb.getJSX(this.bulbClick) : (<></>)} */}
                                </div>
                                {<div>&nbsp;</div>}
                                <div>
                                    <span id={"ws-" + props.item.definitionId} className="author" style={{position:"relative",left:"100px"}}>-{props.item.authorPaymail}</span>
                                </div>
                                <div>
                                    <span hidden id={"wbId-" + props.item.definitionId}>{props.item.wordBountyId !== undefined ? props.item.wordBountyId : "None"}</span>
                                </div>
                                <div>
                                    <span hidden id={"key-" + props.item.definitionId}>{props.item.definitionId}</span>
                                </div>
                                <div>
                                    <span hidden id={"hidWordSmith-" + props.item.definitionId}>{props.item.authorPaymail}</span>
                                </div>
                            </span>
                        </div>
                </div>
            </Col>
            </Row>
        </Container>
)
}

export default DefinitionPanel;