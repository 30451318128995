import React from 'react';
import BaseComponent from '../components/Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './WhyPay.css';

class Contact extends BaseComponent {

//   constructor(props) {
//     super(props);
//   }
  
  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"yellow"}}>
          <h2 style={{color:"yellow"}}>Contact Us</h2>
          <h3 className="h3-philosophy">email: SLictionaryLLC at gmail dot com</h3>

          <h3 className="h3-philosophy">HandCash: $SLictionary</h3>

          <h3 className="h3-philosophy">Twitter: @SLictionary.com</h3>

          <h3 className="h3-philosophy">Twetch & Twetch DM: @13328 or “SLictionary”</h3>
      </div>
    );
  }
}

export default Contact;