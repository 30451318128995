import React,{useState, useEffect, Suspense} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getWalletInfo, getUser } from '../../mods/slictionary-user-mod';
import './Login.css';
import { postAnalyticsPage } from "../../services/user/UserService";
import { getCurrentProfile } from "../../services/handcash/handcashService";

const {HandCashConnect} = require('@handcash/handcash-connect');
const config = require("../../config/config");
const axios = require("axios");

var MoneyButton = require('@moneybutton/react-money-button').default;
var hcHandle, hcPaymail, mbPaymail;
var authToken, currentProfile, user;

function Login(props){
    postAnalyticsPage(localStorage.getItem("senderPaymail"),window.location.pathname);

    useEffect( () => {
        async function fetchData() {
            let search = window.location.search;
            if(search !== "?login=yes" 
                && search.indexOf("?w=") === -1 
                && search.indexOf("?wb=") === -1
                && search.indexOf("?sd=") === -1
            ){
                if (search.length > 0 && search.indexOf("&appId") <= 0){
                    search = search + "&appId=asdfasdfasdfasdfasdf";
                }
                authToken = search.substring(search.indexOf("=")+1,search.indexOf("&"));            
                if(authToken.length > 0 && localStorage.getItem("hcAuthToken") === null) {
                    document.getElementById("divSplashScreen").style.display="inline-block";
                    currentProfile = await getCurrentProfile(authToken);
                    hcPaymail = currentProfile.publicProfile.paymail
                    user = await getUser(hcPaymail);
                    hcHandle = currentProfile.publicProfile.handle;

                    if(user.userId === ""){
                        let url = `${config.url.userService}/create/${hcPaymail}`;
                        let result = await axios.post(url);
                        user = result.data;
                        setHandCashState();
                    } else {
                        await setHandCashState();
                    }
                }
            } else {
                if(
                    search.indexOf("?w=") === -1 
                    && search.indexOf("?wb=") === -1
                    && search.indexOf("?sd=") === -1
                ){
                    setToggle(true);
                }
            }
        } fetchData();
    }, []);

    
    const [toggle, setToggle] = useState(false);
    const [displayMapper, setDisplayMapper] = useState("none");
    const [modalLogin, setModalLogin] = useState(true);
    const [modalMoneyButton, setModalMoneyButton] = useState("none");
    const [modalLinkPaymails, setModalLinkPaymails] = useState("none");

    const setHandCashState = async() => {
        document.getElementById("divSplashScreen").style.display="none";
        localStorage.setItem("hcAuthToken", authToken);
        localStorage.setItem("handcashProfile", currentProfile.publicProfile);
        localStorage.setItem("hcHandle", currentProfile.publicProfile.handle.toUpperCase());
        localStorage.setItem("senderPaymail",currentProfile.publicProfile.paymail);
        localStorage.setItem("user", JSON.stringify(user));

        localStorage.setItem("walletAddress", user.slicWallet.walletAddress);
        localStorage.setItem("privateKey", user.slicWallet.privateKey);
        sessionStorage.setItem("refreshedTestIt", true);
        window.location.reload(true);
        window.location.assign("/");
    }

    let styleColor = localStorage.getItem("hcAuthToken") === null ? "rgb(71, 114, 246)" : "#38CB7C";
    var styleLogout = {"display": "inline", color: styleColor};
    
    const login = (e) => {
        localStorage.removeItem("muggleEmail");
        setToggle(!toggle);
        e.preventDefault();
    };

    const onMapMoneyButton = async(payment) => {
        mbPaymail = payment.senderPaymail;
        setModalLinkPaymails("inline");
        setModalMoneyButton("none");
        setModalLogin("none");
    }

    const onClickYes = (e) => {
        setDisplayMapper("none");
        setModalMoneyButton("inline");
    }

    // const onClickNo = async(e) => {
    //     //create a new user with the Handcash paymail. 
    //     //The user chose not to link the MB account.
    //     let url = `${config.url.userService}/create/${hcPaymail}`;
    //     let result = await axios.post(url);
    //     user = result.data;
    //     setHandCashState();
    // }

    const logout = (e) => {
        if(localStorage["senderPaymail"] !== undefined){
            localStorage.removeItem("senderPaymail");
            localStorage.removeItem("walletAddress");
            localStorage.removeItem("privateKey");
            localStorage.removeItem("hcAuthToken");
            localStorage.removeItem("handcashProfile");
            localStorage.removeItem("hcHandle");
            localStorage.removeItem("senderPaymailDisplay");
            localStorage.removeItem("user");

        }
        sessionStorage.removeItem("wordsByUser");
        sessionStorage.removeItem("refreshedTestIt");
        document.getElementById("aLogout").style.display = "none";
        document.getElementById("aLogin").style.display = "inline";
        e.preventDefault();
        window.location.assign("/");
    };

    const closeModal = () => {
        setToggle(false);
        setModalMoneyButton("none");
        setModalLinkPaymails("none");
        setDisplayMapper("none");
        setModalLogin("inline");
    }

    const handcashOnClick = (e) => {
        const handCashConnect = new HandCashConnect({
            appId: config.handcash.appId,
            appSecret: config.handcash.appSecret,
        });
        setToggle(false);
        const redirectionLoginUrl =  handCashConnect.getRedirectionUrl();
        window.location.assign(redirectionLoginUrl);
    }

    const submitOnClick = async(e) => {
        let url = `${config.url.userService}/create/${mbPaymail}/${hcPaymail}`;
        let result = await axios.post(url);
        user = result.data;
        setHandCashState();
    }

    const onPayment = async(payment) => {
        try{
            var user = await getUser(payment.senderPaymail);

            let isMoneyButtonWallet = false;
            user.vendorWallets.forEach(wallet => {
                if(wallet.walletType === "moneybutton"){
                    isMoneyButtonWallet = true;
                }
            })

            if(!isMoneyButtonWallet){
                document.getElementById("divLoginAsMoneyButton").style.display = "none";
                document.getElementById("divCantLoginAsMoneyButton").style.display = "inline";
                throw new Error("you can't log in as moneybutton");
            }

            localStorage.setItem("senderPaymail",payment.senderPaymail);
            let senderPaymailDisplay = payment.senderPaymail.toUpperCase();
            localStorage.setItem("senderPaymailDisplay",senderPaymailDisplay);
            let results = await getWalletInfo(payment.senderPaymail);
            localStorage.setItem('walletAddress', results.walletAddress);
            localStorage.setItem('privateKey', results.privateKey);
            setToggle(false);

            document.getElementById("aLogin").style.display = "none";
            document.getElementById("aLogout").style.display = "inline";
            sessionStorage.setItem("refreshedTestIt", true);
            window.location.reload(true);
        } catch (error) {
            console.log(error.toString());
        }
    }

    const onMoneyButtonLinkError = (error) => {
        console.log(error);
        alert(`A MoneyButton error has occurred and you will not be able to link your MoneyButton account at this time. Start the Login procedure over, and when asked if you want to link your MoneyButton account, click "NO". Contact us and we will link your wallets.`);
    }

    const onMoneyButtonLoginError = (error) => {
        alert(`A MoneyButton error has occurred and you will not be able to use MoneyButton to log in. Click the "Connect with HandCash" button instead`);    
    }

    return (

        <>
        <a id="aLogin" href="" onClick={(e) => login(e)} 
            style={
                localStorage["senderPaymail"] === undefined && props.linkDisplay !== "none"
                ? {"display": "inline"}
                : {"display": "none"}}
        >
            {config.app.verbiage.login.toUpperCase()}
        </a>
        <a id="aLogout" href="" onClick={(e) => logout(e)} className={localStorage.getItem("hcAuthToken") === null ? "blueLogout" : "greenLogout"}
            style={
                localStorage["senderPaymail"] === undefined
                ? {"display": "none"}
                : styleLogout}
        >
            {localStorage.getItem("hcAuthToken") === null
            ? `${config.app.verbiage.logout.toUpperCase()}: ${localStorage.getItem("senderPaymailDisplay")}`
            : `${config.app.verbiage.logout.toUpperCase()}: $${localStorage.getItem("hcHandle")}`}
        </a>

        {(

                <Modal show={toggle} backdrop="static" onHide={closeModal}>
                    <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                        <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>{config.app.verbiage.login}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modalLogin" style={{color:"white",backgroundColor:"#202020",display: modalLogin}}>
                        <div style={{textAlign: "center"}}>
                            <div id="divHandcash" style={{display: "inline", textAlign: "center"}}>
                                <div>&nbsp;</div>
                                <div id="connectButton" style={{left: "8px", position: "relative"}}
                                    url="https://app.handcash.io/#/authorizeApp?appId=<your-app-id>"
                                    onClick={handcashOnClick}>
                                        Connect with HandCash
                                </div>
                            </div>
                            <div>&nbsp;</div>
                            <div id="divLoginAsMoneyButton" style={{display: "inline"}}>
                                <p>&nbsp;</p>
                                <p style={{textAlign: "left", color: "white", fontWeight: "bold"}}>
                                NOTE: If you want to merge your Handcash and Moneybutton accounts, open a Support ticket request. 
                                    <p>&nbsp;</p>
                                </p>
                            <div id="divCantLoginAsMoneyButton" style={{display: "none"}}>
                            <div style={{color: "#FF2400", textAlign: "left"}}>
                                    We did not find a record for your MoneyButton paymail. You must sign in using a <span style={{color:"#38CB7C"}}>HandCash</span> account.
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </Modal.Body>
                </Modal>
      )}
      </>
    )
}

export default Login;