/* eslint-disable no-unused-vars */
import React,{useState, useEffect} from "react";  
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './BeAbaron.css'
import monopolyImage1 from '../../assets/monopoly card - 1.PNG';
import monopolyImage2 from '../../assets/monopoly card - 2.PNG';
import {addBounty} from '../../services/word-bounty/WordBountyService';
import HcButton from "../handcash/HcButton";
import NumericInput from "../Common/NumericInput";
import {encodeURL, decodeURL} from "../../mods/slictionary-util-mod";
import { postAnalyticsPage } from "../../services/user/UserService";

const config = require("../../config/config");
const axios = require("axios");
//const NumericInput = require('react-numeric-input');
const {getBSVexchangeRate} = require('../../mods/slictionary-bsv-mod');

var MoneyButton = require('@moneybutton/react-money-button').default;
var amountG = 0;

const BeAbaron = (props) => {  

    document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
    document.body.style.backgroundAttachment = "fixed";


    var [handle, setHandle] = useState("");
    var [word, setWord] = useState("");
    var [wordBaron, setWordBaron] = useState("");
    var [insufficentFunds, setInsufficentFunds] = useState(false);
    var [exchangeRate, setExchangeRate] = useState(0);
    var [bounty, setBounty] = useState("");

    useEffect(() => {
        postAnalyticsPage(localStorage.getItem("senderPaymail"),
        window.location.pathname,
        window.navigator.userAgent,
        window.navigator.userAgentData);

        const fetchData = async()  =>{
            let exchangeRateTemp = await getBSVexchangeRate();
            setExchangeRate(exchangeRateTemp);
            if(window.location.search !== ""){
                //add value for WORD from query string
                let arr = window.location.search.replace("?","").split("&");
                let word = decodeURL(arr[0].split("=")[1]); 
                setWord(word);
                document.getElementById("txtSearch").value = word;

                //add value for BOUNTY from query string
                if(document.getElementById("numEnterBounty") !== null){
                    //not logged in
                    setBounty(arr[1].split("=")[1]);
                    document.getElementById("numEnterBounty").value = arr[1].split("=")[1];
                }
            }
            
        }
        fetchData();
    }, []);

    const [modalToggle, setModalToggle] = useState(false);

    const closeModal = () => {
        setModalToggle(false);
        setInsufficentFunds(false);
    }

    // const walletProviderHandcashOnClick = (e) => {
    // }

    // const walletProviderMBOnClick = (e) => {
    // }

    const onPayment = async(payment) => {
        try{
        } catch (error) {
            console.log(error.toString());
        }
    }

    const onBlurSearch = async(e) => {
        const ele = document.getElementById("txtSearch");
        let wordBaron;
        if(ele.value.trim().length > 0){
            setWord(ele.value.trim());
            let result = await axios.get(`${config.url.wordBountyService}/wordBaron/${ele.value.trim()}/pending`);
            wordBaron = result.data;
            setWordBaron(wordBaron);
            if(wordBaron.isActiveBounty || wordBaron.definitionCount > 0){
                setModalToggle(true);
            }
        }
    }

    const myOnPaymentCallback = async(payment) => {
        addBounty(
          amountG, //amount
          Number(exchangeRate), //exchangeRate
          "", //txid
          "", //moneyButtonId
          localStorage.getItem("senderPaymail"), //senderPaymail
          word
        );
        
        this.props.history.push({pathname:"/confirmation/wordbounty-thank-you/" + encodeURL(word)});
    }
    
    const hcPreProcess = (e) => {
        e.persist();
        try {
            let bounty = document.getElementById("numEnterBounty").value;
            if(bounty === ""){
                throw new Error("You must enter a value for BOUNTY");
            }
            let search = document.getElementById("txtSearch").value.trim();
            amountG = Number(document.getElementById("numEnterBounty").value);
            e.amount = amountG;
            
            if(search === ""){
                throw new Error("You must enter a value for WORD");
            } else {
                e.target.value = "success";
                e.amount = amountG;
                
            }
        } catch (error) {
            alert(error.message);            
            e.target.value = "failed";
        }   
    }

    const hcPostProcess = async(e) => {
        e.persist();
        await addBounty(
            Number(amountG), //amount
            Number(exchangeRate), //exchangeRate
            "", //txid
            "", //moneyButtonId
            localStorage.getItem("senderPaymail"), //senderPaymail
            word
        );

        props.history.push({pathname:"/confirmation/wordbounty-thank-you/" + encodeURL(word)});

    }

    const numericInputOnChange = (event) => {
        amountG = Number(event.target.value);
    }

    var mbElement;
    if (localStorage.getItem("hcAuthToken") === null){
            if(localStorage.getItem("senderPaymail") === undefined || localStorage.getItem("senderPaymail") === null) {
                mbElement =(
                <div>
                    <div>&nbsp;</div>
                    <div style={{color:"red"}}>Please log into a wallet provider. Use the Login link at the top.</div>
                </div>
                // not logged in
                )
            } else {
                mbElement = (
                    <div id="moneybutton" style={{position: "relative", left: "55px"}}>
                        <MoneyButton
                        to="selflearningdictionary@moneybutton.com"
                        editable = "true"
                        amount="0.0090"
                        currency="USD"
                        onPayment= {myOnPaymentCallback}
                        label="to Submit"
                        buttonId={word}
                        buttonData="{app: SLictionary}"
                        //opReturn="SLic"
                        disabled={false}
                        />
                    </div>
    )
            }
        
    } else {
        mbElement = (
            <>
            
            <NumericInput 
                id="numEnterBounty"
                currencySymbol="$"
                currencySymbolColor="white"
                min="2.00"
                minMessage="To be a word baron, the bounty amount must be greater than or equal to $2"
            />
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <HcButton 
                caption={"SUBMIT"}
                captionProcessing={"PROCESSING..."} 
                captionCompleted={"PROCESSING..."}
                paymentDescription={"SLictionary Baron"}
                checkBalance={true}
                amount={amountG}
                currency="USD"
                height="50px"
                left= "-13px"
                top= "-10px"
                borderRadius="30px"
                width="200px"
                paddingLeft="10px"
                postProcess={hcPostProcess}
                preProcess={hcPreProcess}
            />
            </>    
    )
    }

    return (
        
        <div>
    
        <Container style={{width:1000}}>
    
                <Row>
                <Col>
                  <div className="word-bounty text-center" style={{fontSize: "36pt"}}>BECOME A BARON</div>
                  <div>&nbsp;</div>
                  <div className="word-bounty text-center subtitle">Buy your early ownership stake in the world’s most futuristic dictionary</div>
                </Col>
                </Row>
                <Row>
                    <Col>
                        <div>&nbsp;</div><div>&nbsp;</div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <img src={monopolyImage1} style={{width: "75%"}}/>
                    </Col>
                    <Col className="text-center subtitle controlLabel">
                    Pick the word you want to own
                    <div id="divSearch">
                    <input type="text" id="txtSearch"  
                        // onKeyUp={this.myFunction} 
                        style={{borderWidth:"2px", borderColor:"white"}}
                        rows="1" cols="150"
                        autoComplete="off" 
                        placeholder={config.app.verbiage.lookUpWord}
                        onBlur={onBlurSearch}
                    >
                    </input>
                    </div>
                    <div>&nbsp;</div>
                    Enter a Bounty Amount
                    
                    {mbElement}
                    </Col>
                    <Col>
                    <img src={monopolyImage2} style={{width: "75%"}}/>
                    </Col>
                </Row>
                <Modal show={modalToggle} onHide={closeModal} >
                    <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                    <Modal.Title id="modalTitle" style={{color:"white",backgroundColor:"#202020"}}>Word Bounty Alert</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                        <div style={{color:"white",backgroundColor:"#202020"}}>
                            The word <span style={{fontSize: "14pt", fontWeight: "bold"}}>{word.toUpperCase()}</span>:
                        </div>
                        <div>&nbsp;</div>
                        <div style={{display: wordBaron.isActiveBounty ? "none" : ""}}>
                            <ul><li>It is <i>not part of an active bounty</i>, so you may create a new one.</li></ul>
                        </div>
                        <div style={{display: wordBaron.isActiveBounty && wordBaron.voteStart === "" ? "" : "none"}}> 
                           <ul><li>It is part of an active bounty that has <i>not yet started</i>. You may contribute to this bounty.</li></ul>
                        </div>
                        <div style={{display: wordBaron.isActiveBounty && wordBaron.voteStart !== "" ? "" : "none"}}>
                            <ul><li>It is part of an active bounty <i>that has already started</i>, so you will have to wait until {wordBaron.contestEnd} to start a new one.</li></ul>
                        </div>
                        <div>
                            <ul><li>{wordBaron.wordBountyCount} {wordBaron.wordBountyCount > 1 || wordBaron.wordBountyCount === 0 ? "bounties have" : "bounty has"} been completed for this word.</li></ul>
                        </div>
                        <div style={{display: wordBaron.definitionCount > 0 ? "" : "none"}}>
                            <ul><li>{wordBaron.definitionCount} {wordBaron.definitionCount === 1 ? "definition already exists" : "definitions already exist"} for this word. You will not own {wordBaron.definitionCount === 1 ? "this existing definition" : "these existing definitions"} if you become its new WordBaron.</li></ul>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <Button id="txtContinue" variant="outline-light" onClick={closeModal}>Continue</Button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={insufficentFunds} onHide={closeModal} >
                    <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                    <Modal.Title id="modalTitle" style={{color:"white",backgroundColor:"#202020"}}>Word Bounty Alert</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                        <div style={{color:"white",backgroundColor:"#202020", fontSize: "16pt"}}>
                            INSUFFICIENT FUNDS
                        </div>
                        <div>&nbsp;</div>
                        <div style={{display: wordBaron.isActiveBounty ? "none" : ""}}>
                            You do not have enough funds in your Handcash wallet to cover the transaction.
                        </div>
                        <div >
                            <Button id="txtOK" variant="outline-light" onClick={closeModal}>OK</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                </Container>
                </div>
               
            );
    
}  

export default BeAbaron;  