import React from "react";

var inputLeft;
var inputHeight = "";

function NumericInput(props){
    inputLeft = props.left !== undefined ? props.left : "-20px";
    inputHeight = props.height !== undefined ? props.height : "";
    var spanStyle = {fontSize:"18pt", paddingRight: "10px", 
        position:"relative", left:"-15px", color: props.currencySymbolColor};

    window.addEventListener('keydown', function (event) {
        if(document.activeElement.id === props.id){
            var hasDecimalPoint = document.getElementById(props.id).value.indexOf(".") !== -1;
            if ((event.key !== "0"
                && event.key !== "1"
                && event.key !== "2"
                && event.key !== "3"
                && event.key !== "4"
                && event.key !== "5"
                && event.key !== "6"
                && event.key !== "7"
                && event.key !== "8"
                && event.key !== "9"
                && event.key !== "."
                && event.key !== "Backspace"
                && event.key !== "Delete"
                && event.key !== "ArrowLeft"
                && event.key !== "ArrowRight"
            )
                ||
            (hasDecimalPoint && event.key === ".") 
            )
            {
                event.preventDefault();
                
                return false;
            }
        }
    });

    const onBlur = (e) => {
        if(props.onBlur !== undefined){
            props.onBlur(e);
        }
    }

    return (
        <>
            <div>
                <span style={spanStyle}>{props.currencySymbol}</span>
                <input type="text"
                    id={props.id}  
                    style={
                            {
                                borderRadius: "30px", position: "relative", 
                                left: {inputLeft},
                                height: "37px",
                                verticalAlign: "top",
                                textAlign: "right", 
                                paddingRight: "15px", 
                                width: "125px"
                            }
                        }
                    onBlur={onBlur} 
                    placeholder={props.placeholder !== undefined ? props.placeholder : ""}
                    //value="$0.01"
                    />
                    
            </div>
      </>
    )
}

export default NumericInput;