/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import BaseComponent from '../components/Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './releaseNotes.css';
import imgPleaseLogin from '../assets/PleaseLogin.png';
import imgChooseWalletProvider from '../assets/ChooseWalletProvider.PNG';
import imgWalletQuestion from '../assets/walletQuestion.PNG';
import imgLoginMoneyButton from '../assets/LoginMoneyButton.PNG';
import imgLinkWallets from '../assets/linkWallets.PNG';
import imgBecomeAbaron from '../assets/BecomeAbaron.PNG';
import imgWordBountyAlert from '../assets/wordBountyAlert.PNG';
import imgWordBounty from '../assets/WordBounty.PNG';
import imgWordBountyVote from '../assets/WordBountyVote.PNG';
import imgBecomeAbaronDollarSign from '../assets/BecomeAbaronDollarSign.PNG';
import imgNominate from '../assets/slebrity-nominate.png';
import imgAuction from '../assets/slebrity-auction.png';
import imgDiscount from '../assets/discount-code.png';
import imgSupportTicket from '../assets/support-tickets.png'

class releaseNotesV1_2 extends BaseComponent {

  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"yellow"}}>
        <h1 className="release-h1">What's New?</h1>
        <h2 className="release-h2">SLictionary version 1.2</h2>
        <p className="release-p" style={{color: "lightgoldenrodyellow", fontSize:"16pt"}}>You are using the latest version of SLictionary. New features include:</p>

        <h3 className="release-h3" style={{color:"lightgoldenrodyellow"}}>SLEBRITY!</h3>
        <p className="release-p">
            Each month, SLictionary will post a celebrity (hereinafter called the "SLebrity") 
            along with a word significant to that SLebrity, which he or she has pre-agreed to 
            define into a SLicDef NFT issued on the BitCoin blockchain. This definition will be 
            a unique “1 of 1” NFT and is auctioned, before the SLebrity creates it, to the 
            highest bidder to own. For example, the winning bidder would own Michael Jordan’s
            definition of “basketball” or Katy Perry’s definition of “firework” when the process is 
            complete.
            <h3 className="slebrity-h3" style={{textAlign: "left", fontStyle: "italic"}}>
                Nominate!
            </h3>
            <div className="text-center">
                <img src={imgNominate} />
            </div>
            <br/>
            <h3 className="slebrity-h3" style={{textAlign: "left", fontStyle: "italic"}}>
                Win the Auction!
            </h3>
            <div className="text-center">
                <img src={imgAuction} />
            </div>

        </p>
        <h3 className="release-h3">Discount Coupons</h3>
        <p className="release-p">
            SLictionary now provides discount codes for free word definition submissions, even 
            for users who do not have bsv wallets.
        </p>
        <p className="release-p text-center">
            <img src={imgDiscount} />
        </p>
        <h3 className="release-h3">Support Tickets</h3>
        <p className="release-p">
            Having an issue with SLictionary? We now provide the ability to submit tech support tickets.
        </p>
        <p className="release-p text-center">
            <img src={imgSupportTicket} />
        </p>


      </div>
    );
  }
}

export default releaseNotesV1_2;