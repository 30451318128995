import React from 'react';
import BaseComponent from './Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';

class WhyPay extends BaseComponent {

//   constructor(props) {
//     super(props);
//   }

  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"white"}}>
          <h2 style={{color:"yellow"}}>Our Philosophy</h2>
          <div>
          <h3 className="h3-philosophy">SLictionary = Self-Learning Dictionary</h3>

        
          <p className="p-philosophy">A crowd-sourced dictionary which algorithmically improves with time and usage, via ranking useful efficient definitions with MONEY.</p>

          <p  className="p-philosophy">SLictionary will seed with one single word: "SLictionary"; after that, it will be open to users to submit definitions for words, including separate meanings, pronunciations, pictures, and other parts to standard word definitions.</p>

          <p  className="p-philosophy">SLictionary provides users a way to MAKE money, since each lookup will cost a small micro-transaction (a penny at first) to the searcher, but a majority of that fee will be allocated to the part of the definition a user finds most helpful.  The majority of the searching-fee paid will go to a SLictionary "WordSmith" who provided the best definition property.  That could be a traditional definition, it could be a picture, or it could be a pronunciation in a certain accent (Brisbaner-English?). </p>

          <h3 className="h3-philosophy">EXAMPLE</h3>
          <p  className="p-philosophy">A crossword puzzle user looks up "money" and finds two definitions:</p>

          <p className="quote p-philosophy">a) "A commodity which is traded more than any other commodity, by people in economic exchanges, such that it becomes the defacto unit of exchange for the vast majority of trading"-- John Pitts</p>
            <p className="quote p-philosophy">b)  "A coin or token used in an exchange, especially in making change for differences in trading values"-- John DiFelice</p>
            <p className="quote p-philosophy">c)  "It's a gas.   Grab that cash with both hands and make a stash
          New car, caviar, four star daydream, think I'll buy me a football team" -- Roger Waters</p>
        
          <h3 className="h3-philosophy">VOTES</h3>
          <h3 className="h3-philosophy">Payments to Wordsmiths <span style={{color:"yellow"}}>&</span> algorithmic efficiency improvement for learners</h3>
          <p className="p-philosophy">The user finds definition "a" really helped her with her crossword puzzle which needed "exchange", so she places her mouse cursor over a light-bulb icon on the upper right of definition-a, and thus VOTES for it.  By voting, she allocates about 70% of the penny she paid for her search, to the owner of defintion-a, John Pitts.  30% goes to SLictionary to fund further improvements to the dictionary and new versions and platforms.</p>

          Problem?
          <p className="p-philosophy">We challenge you to go to ANY dictionary website and find a CLEAN interface allowing the word-searcher to find definitions efficiently.  The advertisement-based experience is conjested, confusing, and terribly inefficient-- it's AWFUL.  For what?  How much are the online dictionaries making in ads?  A penny or two?  We can give the user a MUCH improved website for definitions, AND at the same time naturally follow the changes of the English language in real time.  For less than a dollar per year for avid dictionary users, we can give that user back MANY dollars in lost time.  Did you ever have a click-bait ad ruin an hour of your life?  Is your time valuable?
          </p>
          <h3 className="h3-philosophy">SELF-LEARNING</h3>
          <p className="p-philosophy">Merriam-Webster, who once made more money than you can imagine in the 1980s at their peak, has to hire scores of fussy high-browed individuals to review words for approval in the next editions which come out every few YEARS.  SLictionary is new every time a WordSmith enters a new definition, a new word, or a new PROPERTY of a word.   SLictionary LEARNS which definitions and parts of definitions work best, and reward the WordSmiths who smithed them-- with MONEY.  Thus, SLictionary outsources it's work-force, and pays them REAL TIME as their labor bears fruit.</p>

          <p className="p-philosophy">Best of all, we all get to watch SLictionary grow with time and use.  Like the first days of Wikipedia, it will have nothing in it.  This isn't a chicken-and-egg bug, it's an OPPORTUNITY for the BSV community to make money.
          We aren't going to be the first community-fed content site (Wikipedia, the internet itself, Medium, etc...), but we wish to be the first community-sourced reference who fixes the problems of those sites-- the "LIKE" economy.  We will solve it with BitCoin Satoshi Vision-- first using microtransactions.
          SLictionary's Wordsmith concept rewards those who come to the site early and devote the time and effort to write great helpful efficient definitions.</p>

          <h3 className="h3-philosophy">VISUAL & AUDIO- CENTRIC:</h3>
          <p className="p-philosophy">Do you know why traditional dictionaries never used lots of pictures?  Hint: Encyclopedia’s use them much more, why?  It’s because pictures are traditionally expensive in printing and dictionaries are traditionally just ONE book thus must skimp on information.  We don't skimp-- unlike traditional dictionaries there's no reason.  Our online competitors are sticking to skeuomorphic versions of dictionaries, abiding by rules which no longer apply when using a 4-dimensional substrate like the MetaNet.</p>

          <p className="p-philosophy">Videos are even MORE expensive and not even possible in a paper-bound dicitonary (or encylopedia).  But humans actually LEARN best that way.  Ask any memorization-competition winner about "Method of Loci" which is VISUAL.  A child learns words by SEEING and HEARING. Visual and audial mastery.  This is SLictionary's default setting, and we think as it learns it will STAY visually-focused. </p>

          <h3 className="h3-philosophy">“NO!”</h3>
          <p className="p-philosophy">"NO" is a head-shake, an unpleasant face, a negative body language, a palmy hand extended.  That’s how a child learns what NO means.   CAR, BOOK, HAPPY, MOON.  SLictionary will violate all of Merriam-Webster’s conventions which every online dictionary has copied without thinking.</p>

          <p className="p-philosophy">skyoo-uh-mawrf!</p>
          <p className="p-philosophy">This is how we learn how to say skeuomorph??</p>
          <p className="p-philosophy">We will eliminate the traditional pronunciation key, backwards "e"s and all, because it’s not required in a dictionary which employs audio.  Why read a pronunciation?  Hear it, in YOUR favorite accent.  Over time you could imagine a favorite celebrity doing voice-overs for pronounciations.  Like Burger King used to say, we want you to "Have it YOUR way".  That means we favor the definitions people love, and we use BitCoin to know what they love and make voting serious.</p>

          <p className="p-philosophy">SEE what a CAR or MOON looks like.  Why should a 3 year old boy READ what an "automobile" is? He can simply see the word, see the picture and say to himself "oh, it's just a CAR!"</p>

          <h3 className="h3-philosophy">SHARP?</h3>
          <p className="p-philosophy">Is it a video of the Muppet’s Swedish Chef using knife that causes the lightbulb above your head to go off?  Or is it a picture of Stu Ungar playing cards with aces in the hole?
          Or maybe, a picture of Richard Feynman (quick-witted)?</p>

          <p className="p-philosophy">We don’t proclaim to know what people will find the most useful, we’ll only provide the algos necessary to help people figure it out themselves.  Not with likes or other value-less voting like thumbs-up/down, but with MONEY. 
          We believe the age of the written word has peaked.  Handwriting is no longer taught in schools, the Germans even eliminated spelling.  We think written words are inferior to spoken words and 3 and 4 dimensional visuals.  The current form of dictionaries does not reflect this.</p>

          <p className="p-philosophy">We believe SLictionary will eventually LEAD with pictures and wordy definitions will be outranked.  We won’t force this, we’ll let the words decide. </p>

          <p className="p-philosophy">SLictionary will be GREAT for kids, but parents will secretly like it the best too:</p>

          <h3 className="h3-philosophy">DEMOGRAPHICS</h3>
          <p className="p-philosophy">will be an important differentiating factor:</p>
          <p className="p-philosophy">Consider "CAR"?  Most adults won't be looking in SLictionary for that word, but kids will!</p>
          <p className="p-philosophy">We predict the #1 "light-bulbed" definition for CAR will be a picture or video, and that it will be a word looked-up mostly by very young children-- preschoolers.  In a future version we’ll make a learning game to cause that to happen.</p>

          <p className="p-philosophy">Adults don’t look up CAR in the dictionary, but do you think SLictionary will have a low ranking for that word?  No, because SLictionary will ultimately become the #1 way for kids to learn and they won’t even need to be literate to make it work.   Adults will have different priorties.  A chemist might simply be searching for the chemical compound for sucrose when he looks up "SUGAR".  Once he sees the picture, he clicks the light-bulb to thank the Smith, and he's gone as fast as he came-- 1 penny split 2-ways later!</p>

          <p className="p-philosophy">SLictionary will learn the same way people do, with things like demographics and culture playing a role-- something Merriam-Webster couldn’t possibly fathom. </p>

          <p className="p-philosophy">Whereas "CAR" and "SNAKE" might be dominated by children,
          Adults will govern how SUPERCILIOUS, MISOGYNY and FIANCE appear in SLictionary.  Children will govern how MOON, YES, and ALLIGATOR appear.  This is how it SHOULD be, not a dictionary governened by 70 year old men making decisions for everyone in an old building in Massachusetts or England.</p>

          <p className="p-philosophy">SLictionary, like BitCoin, is decentralized.  In fact, it’s democracy where democracy works best-- deciding things which are popular and governed by the masses-- by default.</p>

          <h3 className="h3-philosophy">PHILOSOPHY</h3>
          <p className="p-philosophy">Language for thousands of years was governed by speech, humans conversing with each other across generations and across campfires.  Most stories which are old and in the first books were first hand-me-downs from generations past.  Do you think the first edition of "Noah's Ark" was that of the one we found written on papyrus?  That story was likely told for thousands of years before it was ever found written.  </p>
          <p className="p-philosophy">So why are dictionaries BOOKS and WORD-centric?  SLictionary aims to return language back to the people-- back to the majority which controls it.  While we think democracy is a poor government choice bordering on madness, and popularity can be a curse-- those two concepts ARE what make langauges happen.  Words are an unplanned TREND amongst groups of people.  While it might not be true US President Harding invented the world NORMALCY, we like the story because it reflects how words actually happen.   Words are born of necessity by SPEECH not men up high making scholarly decisions.  SLictionary is the words of the people-- voted-upon-- popularized, and pruned by history.  We aren't an official reference, SLictionarians are just a reflection of society itself.  It's natural, but commercial so that we can seperate the wheat from the chaff.  Speaking of chaff...</p>

          <h3 className="h3-philosophy">PRUNE</h3>
          <p className="p-philosophy">SLictionary (like Bitcoin miners are free to do) will prune bad content algorithmically.  Thus, if a WordSmith isn't providing value, then over a certain period his entries will die, making SLictionary more valuable as it consolidates.
          </p>
          <h3 className="h3-philosophy">SPAM</h3>
          <p className="p-philosophy">We welcome it!
          Let's say Kleen-ex Corporation would like to enter their definition for "tissue" which includes a shameless plug for their brand of tissues.  Furthermore, they pay a bunch of guttersnipes to search on SLictionary for the word tissue and VOTE on their definition until it rises up to the top.  That's not free advertising; it has a cost.  First of all, it will cost Kleen-ex money to post nonsense.  Second, will it get any votes from anyone else?
          The theory of the "long tail" will solve this problem.  As SLictionary gets a lot of use all around the globe, the usage will climb to the point where Kleen-ex would have to spend too much money to advertise their definition.  They would lose and drop down the rankings.  Sure, they could continue to pump up their definition artificially, but we have a solution for that too, which we aren't ready to reveal yet as it's for a later version.  We don't expect any spam in the beginning, as it wouldn't pay to spam a new product which has so few users out of the batting cage.
          </p>
          <h3 className="h3-philosophy">THE FUTURE</h3>
          <p className="p-philosophy">...of SL Corp is long and broad.
          We at SL Corp have many many versions and platforms we can grow after providing our original version 1.0 on the web.  We will move to mobile quickly, particularly as Apple learns Bitcoin will be good for them, and also move to become add-ons to things such as browsers and operating systems such that a "right-click" definition will have a SLictionary offering.  We are already working on an iOS/Swift back-end to our app, so that mobile and website alike can take advantage of world class cloud database which can serve word-searchers on whichever device they're using.
          </p>
          <h3 className="h3-philosophy">TIME & MONEY</h3>
          <p className="p-philosophy">The best part of SLictionary is that it pays the people according to their work, and it pays them for a LONG time if they smith a briliant definition.
          This of course has implications in OTHER fields, as well, and we recognize this, and SLictionary will be one of many products to come using a similar theme.
          </p>
          

          <h3 className="h3-philosophy">CRUSH THE JOKERS</h3>
          <p className="p-philosophy">Current dictionaries are a joke, they've become goofy "stock car" cartoons (go look up what a NASCAR looks like compared to a commercial car) of their former selves.  They aren't useful, and are tremendous time-wasters, and are governed by a stuffy ruling class of pompous self-important nature.
          </p>
          <p className="p-philosophy">LANGUAGE WAS INVENTED BY PEOPLE, COMMONERS, POPULARIZED AND SPREAD BY PEOPLE, USING SPOKEN LANGUAGE MOSTLY.  WE AT SLICTIONTARY AIM TO GIVE THE POWER OF WORDS BACK TO THE PEOPLE WHERE ITS ALWAYS BEEN ANYWAY.</p>


          <h3  className="h3-philosophy" style={{fontSize: "30pt",lineHeight:"75px"}}>LEARN, EARN, BURN!</h3>


          </div>
      </div>
    );
  }
}

export default WhyPay;