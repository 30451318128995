//import generatePushID from "../components/Common/PushId";

//var moment = require('moment');

const addErrorToLog = async(message, type) => {
    // createDateTime will be set within
    // let db = firebase.firestore();

    // db.collection("error_log")
    //     .doc(generatePushID())
    //     .set({
    //         createDateTime: moment().format(),
    //         errorMessage: message,
    //         type: type
    //     }).then(function() {
    //         // do nothing           
    //     }).catch(function(error) {
    //     console.log(error);
    //     });
}

export {addErrorToLog};