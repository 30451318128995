const config = require("../../config/config");
const bidIncreaseFactor = 1 + config.app.SLebrity.percentageIncrease/100;

var SLebrityBid = function(bid, max, paymail) {
    var self = this;
    this.bid = bid;
    this.max = max;
    this.paymail = paymail;
};

const getFinalBid = (BidA, BidB) => {
    var FinalBid;

    if(BidA.max === 0 && BidB.max === 0){FinalBid = runCase1(BidA, BidB)}
    if(BidA.max > 0 && BidB.max === 0){FinalBid = runCase2(BidA, BidB)}
    if(BidA.max === 0 && BidB.max > 0){FinalBid = runCase3(BidA, BidB)}
    if(BidA.max > 0 && BidB.max > 0){FinalBid = runCase4(BidA, BidB)}

    return FinalBid;
} 

const runCase1 = (BidA, BidB) => {
    var FinalBid = new SLebrityBid(0,0,"");

    if(BidB.bid >= bidIncreaseFactor * BidA.bid ){
        FinalBid.bid = BidB.bid;
        FinalBid.paymail = BidB.paymail;
        FinalBid.max = BidB.max;
    } else {
        FinalBid.bid = BidA.bid;
        FinalBid.paymail = BidA.paymail;
        FinalBid.max = BidA.max;
        //throw new Error("You have entered an insufficent bid.")
    }

    return FinalBid;
}

const runCase2 = (BidA, BidB) => {
    var FinalBid = new SLebrityBid(0,0,"");

    if(BidA.max >= BidB.bid){ 
        if(BidA.max > BidB.bid * bidIncreaseFactor){
            // if(BidB.bid < BidA.bid * bidIncreaseFactor ){
            //     FinalBid.bid = BidA.bid;
            // } else {
                //FinalBid.bid = BidA.bid * bidIncreaseFactor;
                FinalBid.bid = BidB.bid * bidIncreaseFactor;
            // }
            FinalBid.paymail = BidA.paymail;
            FinalBid.max = BidA.max; 
        } else { // the old maxBid is valid, but just barely above the new bid
            FinalBid.bid = BidA.max;
            FinalBid.paymail = BidA.paymail;
            FinalBid.max = BidA.max;
        }
    } else {  // newBidderBid is greater than old boss’s maxBid
        if(BidB.bid > bidIncreaseFactor * BidA.max){
            FinalBid.bid = BidB.bid;//BidA.bid * bidIncreaseFactor;
            FinalBid.paymail = BidB.paymail;
            // new bidder overbid manually, so we establish a maxBid for him
            FinalBid.max = BidB.bid;
        } else { // newBidderBid is <= bidIncreaseFactor * currentMaxBid
            FinalBid.bid = BidB.bid;
            FinalBid.paymail = BidB.paymail;
            FinalBid.max = BidB.max;
        }
    }

    return FinalBid;
}

const runCase3 = (BidA, BidB) => {
    var FinalBid = new SLebrityBid(0,0,"");

    if(BidB.max >= BidA.bid){ 
        if(BidB.max > BidA.bid * bidIncreaseFactor){
            FinalBid.bid = BidA.bid * bidIncreaseFactor;
            FinalBid.paymail = BidB.paymail;
            FinalBid.max = BidB.max;
        } else { 
            FinalBid.bid = BidB.max;
            FinalBid.paymail = BidB.paymail;
            FinalBid.max = BidB.max;
        }
    } else {  
        if(BidA.bid > bidIncreaseFactor * BidB.max){
            FinalBid.bid = BidB.bid * bidIncreaseFactor
            FinalBid.paymail = BidA.paymail
            FinalBid.max = BidA.max;
        } else { 
            FinalBid.bid = BidA.bid;
            FinalBid.paymail = BidA.paymail;
            FinalBid.max = BidA.max;
        }
    }

    return FinalBid;
}

const runCase4 = (BidA, BidB) => {
    var FinalBid = new SLebrityBid(0,0,"");

    if(BidB.max === BidA.max){
        FinalBid.bid = BidA.max;
        FinalBid.paymail = BidA.paymail;
        FinalBid.max = BidA.max;
    } else if(BidA.max > BidB.max) {
        if(BidA.max > BidB.max * bidIncreaseFactor){
            FinalBid.bid = BidB.max * bidIncreaseFactor;
            FinalBid.paymail = BidA.paymail;
            FinalBid.max = BidA.max;
        } else {
            FinalBid.bid = BidA.max;
            FinalBid.paymail = BidA.paymail;
            FinalBid.max = BidA.max;
        }
    } else {  
        if(BidA.max * bidIncreaseFactor <= BidB.max){
            FinalBid.bid = BidA.max * bidIncreaseFactor;
            FinalBid.max = BidB.max;
            FinalBid.paymail = BidB.paymail;
        } else {
            FinalBid.bid = BidB.max;
            FinalBid.paymail = BidB.paymail;
            FinalBid.max = BidB.max;
        }
    }

    return FinalBid;
}

function run() {
    var finalBid;

    // Test Case #1
    var BidA = new SLebrityBid(100, 0, "paymailA");
    var BidB = new SLebrityBid(119.99,0, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 1");
    console.log(finalBid);
    console.log("Expected output: bid=100, paymail = A");

    // Test Case #2 
    var BidA = new SLebrityBid(100, 0, "paymailA");
    var BidB = new SLebrityBid(120,0, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 2");
    console.log(finalBid);
    console.log("Expected output: bid=120, paymail = B");

    // Test Case #3
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(60,0, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 3");
    console.log(finalBid);
    console.log("Expected output: bid=72, paymail = A");

    // Test Case #4
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(80,0, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 4");
    console.log(finalBid);
    console.log("Expected output: bid=96, paymail = A");

    // Test Case #5
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(101,0, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 5");
    console.log(finalBid);
    console.log("Expected output: bid=101, paymail = B");

    // Test Case #6
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(0,100.01, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 6");
    console.log(finalBid);
    console.log("Expected output: bid=100.01, paymail = B");

    // Test Case #7
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(0, 200, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 7");
    console.log(finalBid);
    console.log("Expected output: bid=120, paymail = B");

    // Test Case #8
    var BidA = new SLebrityBid(100, 1000, "paymailA");
    var BidB = new SLebrityBid(0, 1000, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 8");
    console.log(finalBid);
    console.log("Expected output: bid=1000, paymail = A");

    // Test Case #9
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(0, 95, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 9");
    console.log(finalBid);
    console.log("Expected output: bid=100, paymail = A");

    // Test Case #10
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(0, 110, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 10");
    console.log(finalBid);
    console.log("Expected output: bid=110, paymail = B");

    // Test Case #11
    var BidA = new SLebrityBid(50, 1000, "paymailA");
    var BidB = new SLebrityBid(60, 2000, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 11");
    console.log(finalBid);
    console.log("Expected output: bid=1200, paymail = B");

    // Test Case #12
    var BidA = new SLebrityBid(50, 1000, "paymailA");
    var BidB = new SLebrityBid(51, 2000, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 12");
    console.log(finalBid);
    console.log("Expected output: bid=1200, paymail = B");

    // Test Case #13
    var BidA = new SLebrityBid(50, 1000, "paymailA");
    var BidB = new SLebrityBid(60, 500, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 13");
    console.log(finalBid);
    console.log("Expected output: bid=600, paymail = A");

    // Test Case #14
    var BidA = new SLebrityBid(50, 100, "paymailA");
    var BidB = new SLebrityBid(51, 0, "paymailB");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 14");
    console.log(finalBid);
    console.log("Expected output: bid=50, paymail = A");

    // Test Case #15
    var BidA = new SLebrityBid(0, 0, "paymailA");
    var BidB = new SLebrityBid(100, 1000, "paymailA");
    finalBid = getFinalBid(BidA, BidB);
    console.log("Test Case 15");
    console.log(finalBid);
    console.log("Expected output: bid=100, paymail = A");

}

export { SLebrityBid, getFinalBid }