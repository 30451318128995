import axios from 'axios';
const config = require('../../config/config');

const getImgAndAudioUrls = async(definitionId) => {
    let snapshot = await axios.get(`${config.url.wordService}/url/img/audio/${definitionId}`);
    
    return snapshot.data;  
}

const getTokens = async(paymail) => {
    let snapshot = await axios.get(`${config.url.tokenService}/${paymail}`);

    return snapshot.data;
}

const getComponentIds = async(definitionId) => {
    let snapshot = await axios.get(`${config.url.tokenService}/${definitionId}`);

    return snapshot.data;
}

const getTokenJSON = async(definitionId, toAddress, satAmount) => {
    let snapshot = await axios.get(`${config.url.tokenService}/${definitionId}/${toAddress}/${satAmount}`);

    return snapshot.data;
}

const getTokenDetails = async(tokenId, ticker) => {
    let snapshot = await axios.get(`${config.url.tokenService}/details/${tokenId}/${ticker}`);

    return snapshot.data;
}   

const transferToken = async(
    definitionId,
    word,
    issuerPrivateKey, 
    ownerPrivateKey, 
    transferToAddress, 
    issueTxId
    ) => {

    const tokenJson = await getTransferTokenJSON(
        word,
        definitionId,
        issuerPrivateKey, 
        ownerPrivateKey, 
        transferToAddress, 
        issueTxId
        );
    
    let results = await axios.post(`${config.url.tokenService}/stas/contract/issue/transfer`, tokenJson);
    console.log(results);
    // .then(function (res) {
    //     console.log(res);
    //   })
    // .catch(error => {
    //     throw(error);
    // });
}    

const redeemToken = async(
    definitionId,
    word,
    issuerPrivateKey, 
    ownerPrivateKey, 
    issueTxId
) => {
    try{
        const tokenJson = getRedeemTokenJSON(
            issuerPrivateKey, 
            ownerPrivateKey, 
            issueTxId,
            definitionId
        );
        
        let results = await axios.post(`${config.url.tokenService}/stas/contract/issue/redeem`, tokenJson)
        .then(function (res) {
            //console.log(res);
            })
        .catch(error => {
            console.error('There was an error!', error);
            throw error;
        });

        return results;
    } catch (error) {
        console.log(error);
        throw error;        
    }
}

const getTransferTokenJSON = async (
    word,
    definitionId,
    issuerPrivateKey, 
    ownerPrivateKey, 
    transferToAddress, 
    issueTxId) => {
    
        var json = {
        "word" : word,
        "definitionId" : definitionId,
        "isTestMode" : true,
        "issuerPrivateKey" : issuerPrivateKey,
        "ownerPrivateKey" : ownerPrivateKey,
        "transferToAddress" : transferToAddress,
        "issueTxId" : issueTxId    
    }

    return json;
}

const getRedeemTokenJSON = (
    issuerPrivateKey, 
    ownerPrivateKey,
    issueTxId,
    definitionId) => {
    
        var json = {
            "issuerPrivateKey" : issuerPrivateKey,
            "ownerPrivateKey" : ownerPrivateKey,
            "issueTxId" : issueTxId,
            "definitionId" : definitionId    
        }

    return json;
}

export {getImgAndAudioUrls, getTokenDetails, transferToken, redeemToken, getTokenJSON, getTokens, getComponentIds} 