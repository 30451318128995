import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getWordBountyCount} from '../../services/word-bounty/WordBountyService';
import '../SearchResults.css';
import {encodeURL, decodeURL} from '../../mods/slictionary-util-mod';

var bountyNumberString = "";
var bountyCount = 0;

class WordBountyThankYou extends React.Component  {

    constructor(props) {
      super(props);
      
        let arr = this.props.location.pathname.split("/");
        this.state = {
            word: decodeURL(arr[3]),
            order:0,
            imgUrl:"",
            pronunciation:"",
            definition:"",
            rows: [],
            defId: [],
            bountyData: [],
            paymail:localStorage.getItem("senderPaymail"),
            email:""
        };
        this.navigateToWordBounty = this.navigateToWordBounty.bind(this);
    }

    async navigateToWordBounty(event){
        this.props.history.push("/");
        this.props.history.push({pathname:"WordBounty"});
    }


    async componentWillMount(){
        sessionStorage.setItem("previousUrl","word-bount-thank-you");
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
        document.body.style.backgroundAttachment = "fixed";
    

        bountyCount = await getWordBountyCount();
                
        bountyNumberString = this.ordinal_suffix_of(bountyCount); 

        this.setState({"email":localStorage.getItem("senderPaymail")})
    }

    ordinal_suffix_of = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    render() { 

      return (
        <div className="content" style={{color:"white"}}>
          <Container style={{width:1000}}>
          <Row>
          <Col>
            <h3 className="justify-content-md-center">
            <div className="luckyYou text-center">Your Bounty Has Been Added!</div>
            <div className="text-center">Your word <span style={{color:"Turquoise",fontSize:"20pt"}}>{this.state.word}</span>
            &nbsp;is the...</div>
            <div className="text-center"><span className="luckyYou">{bountyNumberString}</span> bounty in SLictionary</div>
            {/* <div className="text-center" style={{color:"#FAFAD2"}}>click on the Wanted poster <img src=""/> in the upper right to see it</div> */}
            <div className="text-center">
                <button className="btn btn-lg" style={{size:"10px",borderRadius:"30px", color:"white",
                    backgroundColor:"blue"}} 
                    onClick={this.navigateToWordBounty}>Continue</button>
            </div>
            </h3>
          </Col>
          </Row>
          </Container>  
        </div>
      );
    }
  
}


export default WordBountyThankYou;