import WhatsNew from './assets/WhatsNew-Post-It-Note.png';
import React from 'react';
import 'bootstrap';
import './App.css';
import imgGavel from './assets/gavel.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {wordExists, addRevenueTransaction} from './services/word/WordService';
import { UserContext } from './components/UserContext';
import { getWalletInfo } from './mods/slictionary-user-mod';
import imgUAEflag from './assets/UAE-flag-circle.png';
import imgUSAflag from './assets/USA-flag-circle.png';
import HcButton from './components/handcash/HcButton';
import {getWordsLookAhead} from './services/word/WordService';
import audAuctioneer from './assets/auctioneer.mp3';
import { encodeURL, decodeURL } from './mods/slictionary-util-mod';
import { postAnalyticsPage } from './services/user/UserService';

const config = require("./config/config");
var txtSearch;
var moment = require('moment');
var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;

class App extends React.Component{
  
  constructor(props) {
    super(props);

    this.myOnPaymentCallback = this.myOnPaymentCallback.bind(this);
    this.onMoneyButtonLoad = this.onMoneyButtonLoad.bind(this);
    this.addWordOnClick = this.addWordOnClick.bind(this);
    this.listClick = this.listClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.hcPostProcess = this.hcPostProcess.bind(this);
    this.hcPreProcess = this.hcPreProcess.bind(this);
    this.hcLoginPreProcess = this.hcLoginPreProcess.bind(this);
    this.hcLoginPostProcess = this.hcLoginPostProcess.bind(this);
    this.whatsNewOnClick = this.whatsNewOnClick.bind(this);
    this.onClickBeABaron = this.onClickBeABaron.bind(this);
    this.loadFilteredWords = this.loadFilteredWords.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
      
    this.state = {
      words: [],
      senderPaymail: "",
      refresh: "",
      showLogin: false,
      toggle: {},
      wordsLookAhead: [],
      isFetching: false
    };

}    

componentDidMount = async() => {
    postAnalyticsPage(localStorage.getItem("senderPaymail"),
                        window.location.pathname,
                        window.navigator.userAgent,
                        window.navigator.userAgentData);

    document.body.style.backgroundImage = 
        config.app.lang === "msa" ? "" : "url('https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/Word%20Vortex.gif?alt=media&token=22365c9e-9ceb-4bbc-8320-1af2d436b5e3')";

    document.getElementById("txtSearch").focus();

    var query = [];
    var words = [];
    var wordCount = 0;
    
    //insert the querystring seek parameter in the txtSearch control.
    if(this.props.location.search.length > 0 && this.props.location.search.indexOf("?w=") !== -1){
        let word = this.props.location.search.replace("?","").split("=")[1];
        word = decodeURL(word);
        document.getElementById("txtSearch").value = word;//.replaceAll("%20"," ").replaceAll("%27", "'");
    }
    
    sessionStorage.setItem("previousUrl","");
    document.body.style.backgroundSize = "cover";     
    document.body.style.height = "100vh";
    document.body.style.padding = "0";
    document.body.style.margin = "0"; 
    
    if(sessionStorage.getItem("lookAheadTime") === null) {
        sessionStorage.setItem("lookAheadTime", moment().format());
    }

    var momLookAhead = moment(sessionStorage.getItem("lookAheadTime"));
    if(sessionStorage.getItem("lookAheadList") === null 
        || config.app.lookAheadTimeout <= momLookAhead.diff(moment().format())/-1000
    ){
        let limit = 0;
        query = await getWordsLookAhead(limit);
        words = query.words;
        wordCount = query.count;
        sessionStorage.setItem("lookAheadList", words);
        sessionStorage.setItem("wordCount",wordCount);
    } else {
        let lookAheadList = sessionStorage.getItem("lookAheadList");
        words = lookAheadList.split(",");
        wordCount = Number(sessionStorage.getItem("wordCount"));
    }
    document.getElementById("wordCount").innerText = wordCount;

    this.setState({wordsLookAhead: words});
    this.setState({isFetching: false});
}

txtSearch_addEventListener = (e) => {
    if(e.key === "Enter"){
        document.getElementById("connectButton").click();
    }
}

txtSearch_onClick = (e) => {
    switch(e.key){
        case "Enter":
            document.getElementById("connectButton").click();
            break;
        case "ArrowDown":
            // alert("arrowdown")
            // document.getElementById("myUL").focus();
            break;
    }
}

whatsNewOnClick = (e) => {
    this.props.history.push("releaseNotesV1_2");
    e.preventDefault();
}

addWordOnClick = event =>{
  this.props.history.push({
    pathname:"createword/@/@/@"
  });
}

onClickBeABaron = (e) => {
    e.preventDefault();
    this.props.history.push({
        pathname:"/BeABaron/@"
    });
}

onClickSlebrity = async(e) => {
    e.preventDefault();
    // var audio = document.getElementsByTagName("audio")[0];
    // audio.play();
    // await new Promise(r => setTimeout(r, 5000));
    this.props.history.push({
        pathname: "/SLebrity"
    });
}

onClickWordBounty = (e) => {
    e.preventDefault();
    this.props.history.push({
        pathname: "/WordBounty"
    });

}

hcPreProcess = async(e) => {
    e.persist();
    e.preventDefault();
    if(localStorage["senderPaymail"] === undefined){
        //e.preventDefault();
        e.target.value = "failed";
        window.location.assign("/?login=yes");
    } else {
        var word = document.getElementById('txtSearch').value.trim();

        if(word.length === 0){
            alert("You must enter a word to seek");
            e.target.value = "failed";
        } else {
            e.target.value = "success";
        }
    }
}

hcLoginPreProcess = async(e) => {
}

hcLoginPostProcess = async(e) => { 
}

hcPostProcess = async(e) => {
    e.persist();
    try{
        let paymentResult = e.paymentResult;
        var word = document.getElementById('txtSearch').value.trim();
        //word = word.replaceAll("\\\\","\\");
        this.setState({senderPaymail: localStorage.getItem("senderPaymail")});
        let wordEncoded = encodeURL(word);
        //word = wordEncoded;
        let bWordExists = await wordExists(wordEncoded);
            
        let usdAmount = paymentResult.satoshiAmount / 100000000 * paymentResult.fiatExchangeRate; 
        
        addRevenueTransaction(word, localStorage.getItem("senderPaymail"),paymentResult.transactionId, usdAmount, "seek");

        if (bWordExists){
            this.props.history.push({
            pathname:"definitions/" + wordEncoded + "/" 
                + paymentResult.transactionId
        });
        } else {
            this.props.history.push("searchresults/" 
            + wordEncoded 
            + "/" + paymentResult.transactionId);
        }
    } catch ( error ) {
        console.log(error);
    }
}

async myOnPaymentCallback(payment){
    this.setState({senderPaymail: payment.senderPaymail});
    localStorage.setItem("senderPaymail",payment.senderPaymail);
    if(localStorage["walletAddress"] === undefined){
        let results = await getWalletInfo(payment.senderPaymail);
        localStorage.setItem('senderPaymail',payment.senderPaymail);
        localStorage.setItem('walletAddress', results.walletAddress);
        localStorage.setItem('privateKey', results.privateKey);
    }
    
    var word = document.getElementById('txtSearch').value.trim();
    word = encodeURL(word);

    if(word.length === 0){
      alert("You must enter a word to seek");
      return false;
    }
    let bWordExists = await wordExists(word);

    addRevenueTransaction(word, localStorage.getItem("senderPaymail"),payment.txid, payment.amount, "seek");

    if (bWordExists){
        this.props.history.push({
          pathname:"definitions/" + word + "/" 
              + payment.txid
      });
    } else {
        this.props.history.push("searchresults/" 
        + word 
        + "/" + payment.txid);
    }

}

listClick = event =>{
    let txt = document.getElementById("txtSearch");
    let ul = document.getElementById("myUL");
    txt.value = event.target.innerText;
    ul.style.display = 'none';
}

onMoneyButtonLoad = () =>{
    var iframes = document.getElementsByTagName('iframe');
    iframes[0].className = "searchFrame"; 
}

loadFilteredWords = async(e) => {   
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("txtSearch");
    filter = input.value;
    ul = document.getElementById("myUL");

    if(input.value.length > 0){
        ul.style.display = "block";
    } else {
        ul.style.display = "none";
    }

    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        txtValue = li[i].textContent.toLowerCase();
        if (txtValue.indexOf(filter.toLowerCase()) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }

    var lis = document.getElementById("myUL").getElementsByTagName("li")
    var count = 0;
    let k = 0;
    for(k===0; k < lis.length; k++) {
        if(lis[k].style.display !== "none"){
            count += 1;
        }
    };
    
    if (count > 5){
        ul.style.height = "250px";
    }else if(count === 0){
        ul.style.display = "none";
    }else {
        ul.style.height = (count * 50).toString() + "px";
    }
}

onKeyPress = (e) => {
}

onKeyDown = (event) => {
}

render() {
    var mbElement;
    if (localStorage.getItem("hcAuthToken") === null){
        if(localStorage.getItem("senderPaymail") === undefined || localStorage.getItem("senderPaymail") === null) {
            mbElement = (
                <>
                    <HcButton 
                        caption={"SEEK_PAGE"}
                        captionProcessing={"SEEKING..."} 
                        captionCompleted={"SEEKING..."}
                        paymentDescription={"SLictionary Seek"}
                        checkBalance={true}
                        amount={0.01499}
                        currency="USD"
                        height="50px"
                        left= "-100px"
                        top= "-10px"
                        borderRadius="30px"
                        width="170px"
                        paddingLeft="10px"
                        postProcess={this.hcPostProcess}
                        preProcess={this.hcPreProcess}
                    />
                    </>    
                    )
        } else {
            mbElement = (
                <div id="btnSeek">
                    <MoneyButton
                        id="btnSeek"
                        to="selflearningdictionary@MoneyButton.com"
                        amount="0.0096"
                        currency="USD"
                        onPayment={this.myOnPaymentCallback.bind(payment)}
                        label={config.app.verbiage.moneyButtonSearch}
                        onLoad={this.onMoneyButtonLoad}
                        buttonId=""
                        buttonData="{app: SLictionary}"
                        //opReturn="SLic"
                    />
                </div>
            )
        }
    } else {
            mbElement = (
            <>
                <HcButton 
                    caption={"SEEK_PAGE"}
                    captionProcessing={"SEEKING..."} 
                    captionCompleted={"SEEKING..."}
                    paymentDescription={"SLictionary Seek"}
                    checkBalance={true}
                    amount={0.01499}
                    currency="USD"
                    height="50px"
                    left= "-100px"
                    top= "-10px"
                    borderRadius="30px"
                    width="170px"
                    paddingLeft="10px"
                    postProcess={this.hcPostProcess}
                    preProcess={this.hcPreProcess}
                />
                </>    
        )
    }  

    return !this.state.isFetching ?
    (
        <Container id="parentContainer">
            <Row className="row justify-content-start" style={{textAlign:"center", 
                    display: config.app.showWhatsNew ? "inline" : "none"}}>
                <Col className="col-12">
                    <div>&nbsp;</div>
                </Col>
            </Row>
            <Row>
                <Col>
                <div>&nbsp;</div>
                </Col>
            </Row>
            <Row className="row justify-content-start" style={{display: config.app.showWhatsNew ? "none" : "inline"}}>
                <Col className="col-12">&nbsp;</Col>
            </Row>
            <Row className="row justify-content-start">
                <Col className="col-12">&nbsp; </Col>
            </Row>
            <Row className="row align-items-center">
                <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
                <Col className="col-sm-9 col-lg-9 col-xs-11" style={{whiteSpace:"nowrap",color:"red", fontSize:"20pt"}}>
                </Col>
            </Row>
            <Row className="row align-items-center">
                <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
                <Col className="col-sm-9 col-lg-9 col-xs-11" style={{whiteSpace:"nowrap"}}>
                    <Container style={{width: "1100px"}}>
                        <Row>
                            <Col>
                                <i id="iAddWord" className="fa fa-plus-circle fa-3x" 
                                    onClick={this.addWordOnClick} title="Define a word & earn rent on it!"
                                    style={{fontSize: "2.4em"}}
                                />
                        
                                <a href={config.app.lang === "msa" ? "https://www.slictionary.com/" : "http://msa.slictionary.com"} title={config.app.lang === "msa" ? "to English site" : "to Modern Standard Arabic site"}>
                                    <img id="imgEnglishSite" src={config.app.lang === "msa" ? imgUSAflag : imgUAEflag} alt="English site"/> 
                                </a>    
                                <a id="btnWordBounty" href="" onClick={this.onClickWordBounty} 
                                    title="Earn $3 in BitCoin defining INTEREST at Word Bounty"><div className="text-center" style={{display: "inline",verticalAlign: "middle"}}>WB</div></a>
                                <a id="becomeabaron" href="/BeAbaron" onClick={this.onClickBeABaron} 
                                    title="Buy & Own a Word (NFT) in the dictionary & earn rent on it!"><div>$</div></a>
                                
                                {config.app.Seek.showAuction ?
                                (<a id="slebrity" href="/SLebrity" 
                                    onClick={this.onClickSlebrity} 
                                    title="Auctioning Peter Schiff's GOLD NFT!">
                                    <div><img src={imgGavel}/></div>
                                </a>) : (<div></div>)
                                }   
                                <input type="text" id="txtSearch" className="col-sm-7 col-lg-11 col-xs-12" 
                                    onKeyUp={this.loadFilteredWords}
                                    onKeyDown={this.txtSearch_onClick}
                                    rows="1" cols="100"
                                    autoComplete="off" 
                                    placeholder={config.app.verbiage.lookUpWord}
                                    style={{borderWidth:"1px", borderColor:"white"}}
                                >
                                </input>
                                
                                {mbElement}
                        
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Container>
                        
                </Col>    
            </Row>
            <Row >
                <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
                    <Col className="col-sm-9 col-lg-9 col-xs-11" id="rowLookAhead" style={{height: "1px"}}>
                    <ul id="myUL" style={{left: "-30px", border:"0px", top: "35px", position:"relative"}} 
                        onClick={this.listClick}>
                            {this.state.wordsLookAhead.map((word,i) => {
                            return <li key={i}>{word}</li>;
                        })}
                </ul>
                    </Col>
            </Row>
            <Row>
                <Col className="col-sm-3">&nbsp;</Col>
                <Col className="col-sm-9"></Col>
            </Row>
            <UserContext.Provider value={this.state.senderPaymail}>
            </UserContext.Provider>
            <audio>
                <source src={audAuctioneer}></source>
            </audio>
          </Container>

   
    ) : <div>
            {/* <br/><br/><br/><br/><br/><br/>
            <div style={{color: "white", fontSize:"20pt", backgroundColor: "black"}}>
                Loading...
            </div> */}
        </div>
  }

}

export default App;