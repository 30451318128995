import React,{useEffect} from "react";

function SeekForExternal(props){

    
    useEffect( () => {
        let word = props.location.pathname.split("/")[2];
        window.location.assign(`/?w=${word}`);
        
    }, []);

    return (

        <>
      </>

    )
}

export default SeekForExternal;