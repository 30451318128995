/* eslint-disable no-unused-vars */
import axios from 'axios';
//import firebase from 'firebase';

const config = require("../config/config");

const getUser = async(paymail) => {
    let url = `${config.url.userService}/${paymail}/paymail`;

    var user;
    const query = await axios.get(url)
        .then (resp => {
            user = resp.data;
            return true;
        });
    
    return user;
}

const getWalletInfo = async(paymail) => {
    //var db = firebase.firestore();
    var arrReturn = {};
    var size; 
    
    var user = await getUser(paymail);
                    
    arrReturn.walletAddress = user.slicWallet.walletAddress;
    arrReturn.privateKey = user.slicWallet.privateKey;

    return arrReturn;
}



const impersonate = (paymail, walletAddress, privateKey) => {
    localStorage.setItem('senderPaymail',paymail);
    localStorage.setItem('walletAddress', walletAddress);
    localStorage.setItem('privateKey', privateKey);
}

export { getWalletInfo, impersonate, getUser };