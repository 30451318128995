import React from 'react';
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'

class VoiceRecorder extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleAudioStop = this.handleAudioStop.bind(this);
        this.handleAudioUpload = this.handleAudioUpload.bind(this);
        this.handleRest = this.handleRest.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        
        this.state = {
            audioDetails : {
                url: null,
                blob: null,
                chunks: null,
                duration: {
                    h: null,
                    m: null,
                    s: null
                }
            }
        }
        
      }
    
    handleOnChange(value, text ){
        //console.log('in handleOnChange');
    }

    handleAudioStop(data){
        //console.log(data)
        this.setState({ audioDetails: data });
    }

    handleAudioUpload(file) {
        //console.log(file);
    }
    
    handleRest(){
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null
            }
        }
        this.setState({ audioDetails: reset });
    }

    async componentDidMount(word) {
  
    }

    render() { 

        return (
        <Recorder
        record={true}
        title={"Record your pronunciation"}
        audioURL={this.state.audioDetails.url}
        showUIAudio
        handleAudioStop={data => this.handleAudioStop(data)}
        handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
        handleAudioUpload={data => this.handleAudioUpload(data)}
        handleRest={() => this.handleRest()} />
        );
    }
}

export default VoiceRecorder;