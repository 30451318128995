/* eslint-disable no-unused-vars */
import React, { Component} from 'react';
import {getPronunciations, getPartsOfSpeech} from './Common/Version';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SimpleSlider from './Common/SimpleSlider';
import './Definitions.css';
import {addErrorToLog} from '../mods/slictionary-error-log-mod';
import { encodeURL, decodeURL } from '../mods/slictionary-util-mod';
import { postAnalyticsPage } from '../services/user/UserService';

const beTheFirstAudioUrl = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/audio%2FBe%20the%20First.m4a?alt=media&token=611721e1-3ac6-45cb-ae09-0526976068c1";
const beTheFirstAudioUrl2 = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2FNew%20Recording%2011.m4a?alt=media&token=fdef7d6f-6c03-470e-9012-e78bb8545c59";

const {addVote} = require('../services/vote/VoteService'); 

var _pronunciations;
var _partsOfSpeech = [];
var _firstAudio;
var _authors = [];
var _defId;
var _docRef;
var _hasError;
var audioId = "audio_play_0";
var _selectedAudioId;
var _selectedAudioAuthor;
var _arrVoteCountsById = [];
var _txId;
var _transactionIsExpired = false;

class Definitions extends Component {

    constructor(props) {
      super(props);

        this.simpleslider = React.createRef();

      postAnalyticsPage(localStorage.getItem("senderPaymail"),
      window.location.pathname,
      window.navigator.userAgent,
      window.navigator.userAgentData);

        this.bulbProcess = this.bulbClick.bind(this);

        let arr = this.props.location.pathname.split("/");
        let word = decodeURL(arr[2]); 
          
        this.state = {
          docRef:null,
          tmp:"",
          phonetic:"",
          word: word,
          txid: arr[3],
          defId: "",
          pronunciations: [],
          authors: [],
          hasError: false,
          hasAudioFiles: false,
          firstAudio: "",
          firstAuthor: "",
          selectedAudio: "",
          arrVoteCountsById: [],
          refreshed: false,
          sliderLoaded: false

        }

    }
    voteArrayCallback = async(childData) => {
        _arrVoteCountsById = childData.arrVoteCountsById;
        let doc = document.referrer;
        let i = 0;
        
        try{
            let wordLower = this.state.word; 
            //const doc = await getWordDoc(wordLower);
            const doc = childData.doc;
            //_arrVoteCountsById = await getVersionVoteCounts(wordLower, doc);

            this.createAudioPlayer();

            _pronunciations = await getPronunciations(wordLower,null,doc);
            _partsOfSpeech = await getPartsOfSpeech(wordLower,null,doc);
            
            if(this.getHasAudioFiles(_pronunciations)){
            let key = _arrVoteCountsById[0].key;
            if(_pronunciations[key].audioUrl === beTheFirstAudioUrl
                    || _pronunciations[key].audioUrl === beTheFirstAudioUrl2
                ){
                    _firstAudio = "";
                } else {
                    _firstAudio = _pronunciations[key].audioUrl;
                }
            _selectedAudioId = _pronunciations[key].id;
            _selectedAudioAuthor = _pronunciations[key].author;
            } else {
            _firstAudio = beTheFirstAudioUrl;
            }

            //let audioDisplay = setDisplay(null, _selectedAudioAuthor, localStorage.getItem("senderPaymail"), _transactionIsExpired);
            //default should be false since this display value is set in the SimpleSlider component.
            // let audioDisplay = "none";
            
            // let dd = document.getElementById("audioBulb");
            // if(dd !== null){
            //     dd.style.display = audioDisplay;
            // }
            if(_firstAudio === ""){
            let d = document.getElementById("audioControls");
            d.style.display = "none";
            }

            document.getElementById(audioId).src = _firstAudio;
            
            //this.cool();
            //let json = doc.data;
            let json = doc;
            _defId = json.definitions[0].id === "" ? "@" : json.definitions[0].id;
            _docRef = doc;

            let _word = this.state.word;
            if(this.isAbbreviation(_partsOfSpeech)){
                document.getElementById("title").innerHTML = _word.toUpperCase();
            } else {
                document.getElementById("title").innerHTML = _word;
            }

            let d = document.getElementsByClassName("hidden");
            d.display = "none";

            let btn = document.getElementById("IcanDoBetter");
            btn.style.display = "inline";

            let img = document.getElementById("img|audio_play");
            img.style.display = "inline";

        } catch (error){
            addErrorToLog("message: " + error.message + "\n stack: " + error.stack,"error")
        }
    }

    wordBaronCallback = (childData) => {
        let i = 0;
    }

    callbackFunction = (childData) => {
        _transactionIsExpired = childData.transactionIsExpired;
    
        let srcUrl = childData.audioUrl;
        _selectedAudioId = childData.audioId;
        _selectedAudioAuthor = childData.audioAuthor;
        let hasAudioFiles = this.getHasAudioFiles(_pronunciations);

        if(hasAudioFiles){
            if(childData.audioUrl === beTheFirstAudioUrl){
            srcUrl = "";
            }
        } else {
            srcUrl = beTheFirstAudioUrl;  
        }

        try {
            if(srcUrl.length === 0){
            let d = document.getElementById("audioControls");
            d.style.display = "none";
            }else {
                let d = document.getElementById("audioControls");
                d.style.display = "inline";
            }
        } catch(error){
            let d = document.getElementById("audioControls");
            d.style.display = "none";
        }

        document.getElementById(audioId).src = srcUrl;
    }
        
    shouldComponentUpdate(nextProps) {
        return false;
    }

    getHasAudioFiles(pronunciations){
        let count = 0;
        for(let i=0;i < pronunciations.length; i++){
            if(pronunciations[i].audioUrl === "" 
                || pronunciations[i].audioUrl === beTheFirstAudioUrl){
                // do nothing
            } else {
                count += 1;
            }              
        }

        return count !== 0;
    }

    async componentWillMount(){
        //document.body.style.backgroundImage = "url('')";
        document.body.style.backgroundImage = "linear-gradient(black, #313b44)";
        document.body.style.backgroundAttachment = "fixed";

    }

    async componentDidMount() {
        document.getElementById("title").style.display = "inline-block";
    }

    bulbClick = event => {
      var arrBulbs = document.getElementsByTagName('i');
      for(var i = 0; i < arrBulbs.length; i++){
          if (arrBulbs[i].className === "fa fa-lightbulb fa-1x column"){
              return;
          }
      }

      event.target.style.color = "yellow";
      event.target.className = "fa fa-lightbulb fa-1x column";

      let voter = localStorage("senderPaymail");
      addVote(_selectedAudioId, this.state.word, _selectedAudioAuthor, voter);
  }

    isAbbreviation = (partsOfSpeech) => {
        var isAbbreviation = false;
        for(let i=0; i < partsOfSpeech.length; i++){
            if(partsOfSpeech[i].value === "abbreviation"){
                isAbbreviation = true;
            }
        }
        return isAbbreviation;
    }

    onAudioClick = () => {
        document.getElementById(audioId).play();
    }

    addVersionOnClick = () =>{
      this.props.history.push("/");
      this.props.history.push({
        
        pathname:"createword/" + this.state.word + "/" + _defId + "/txid"
      });
    }

    addWordOnClick = () =>{
      this.props.history.push("/");
      this.props.history.push({
        pathname:"createword/"
      });
    }

    componentDidCatch(error, info) {    
      _hasError = true;
    }

    createAudioPlayer(){
        try{
            var x = document.createElement("AUDIO");
            let parent = document.getElementById("parentContainer")
            x.id = audioId;
            x.setAttribute("controls", "controls");
            x.setAttribute("preload","none");
            x.style.display="none";
            let source= document.createElement('source');
            if(x.canPlayType('audio/mpeg;')){
            source.type = "audio/mp3";
            } else {
            source.type = "audio/mp4";
            }
            //source.type= 'audio/mp4';
            source.src= "";
            x.appendChild(source);
            console.log(x);
            parent.appendChild(x);      
        } catch (error) {
            addErrorToLog(error,"error");
        }
        console.log(x);
    }

render () {
    //console.log("Start Definitions Render");
    const compICanDoBetter = (
        <button id="IcanDoBetter" className="btn btn-dark" 
            style={{width:"150px",zIndex:"1",color:"#81BEF7",display:"none"}} 
            onClick={this.addVersionOnClick}>
          I can do better
      </button>
    );
    
    const compTitle = (
        <span id="title" className="inline" style={{backgroundColor:"transparent", color:"white",fontFamily:"Cambria", fontSize:"30pt", display: "none"}}  />
    );
    
return (

<Container id="parentContainer"> 
    <Row className="row justify-content-start text-center">
        <Col className="col-11">
          {compTitle}
          <span id="audioControls">
              <span>
                      <img id={"img|audio_play"} className="liveimage" 
                          style={{width:"30px",display:"none", marginTop:"-12px"}} 
                          src="https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/audio-blue.png?alt=media&token=907e66b7-3bb5-4657-8f72-a984d6274049"
                          onClick={this.onAudioClick} />
              </span>
              <span>
                    <i id="audioBulb" name="audioBulb" style={{color:"white", backgroundColor: "transparent",display:"none"}} onClick={this.bulbClick} 
                        className="far fa-lightbulb fa-1x column" title="Vote"></i>
              </span>
          </span>
        </Col>
    </Row>
    <Row >
      <Col className="col-12">
        <div id="demo" className="carousel slide" data-ride="carousel"  >
            <div style={{marginTop:"15px"}}>
              {<SimpleSlider
                    parentCallback = {this.callbackFunction} 
                    voteArrayCallback = {this.voteArrayCallback}
                    wordBaronCallback = {this.wordBaronCallback}
                    //ref={simpleslider => (this.simpleslider = simpleslider)}  
                    //ref={this.simpleslider}
                    word={this.state.word}
                    transactionIsExpired={sessionStorage.getItem("transactionIsExpired")}
                />}
            </div>
        </div>
      </Col>
    </Row>
    <Row><Col>&nbsp;</Col></Row>
    <Row>
      <Col className="col-12 text-center">
        {compICanDoBetter}
      </Col>
    </Row>
    <Row><Col className="col-12">&nbsp;</Col></Row>
    <Row><Col className="col-12">&nbsp;</Col></Row>
    <Row><Col className="col-12">&nbsp;</Col></Row>
  </Container>
  
);
}
}

export default Definitions;