import { encodeURL, decodeURL } from "../../mods/slictionary-util-mod";

//import axios from 'axios';
const axios = require("axios");
const config = require('../../config/config');

const addDefinition = async(json) => {
    let url = `${config.url.wordService}/definition/add`;
    // const result = await axios({
    //     method: "post",
    //     url: url,
    //     data: JSON.stringify(json),
    //     {
    //         headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       }
        
        
    // });

    const result = await axios.post(`${url}`,
        // new URLSearchParams({    
        //     definition: JSON.stringify(json)
        // },
        (json),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        //);


    return result;
}

const wordExists = async(word) => {
    let snapshot = await axios.get(`${config.url.wordService}/exists/${word}`);
    
    return snapshot.data.wordExists ;    
}

const addRevenueTransaction = async(word, senderPaymail, txId, amount, type) => {
    word = encodeURL(word);
    let snapshot = await axios.post(`${config.url.wordService}/revenue/transaction/${senderPaymail}/${word}/${txId}/${amount}/false/${type}`);
}

const getWordsLookAhead = async() => {
    const snapshot = await axios.get(`${config.url.wordService}/lookahead`);

    return snapshot.data;
}

const getWordsLookAheadFirstLetter = async(letter) => {
    let snapshot = await axios.get(`${config.url.wordService}/lookahead/${letter}`);
    
    return snapshot.data;
}

const getWordDoc = async(word) => {
    try {
        let snapshot = await axios.get(`${config.url.wordService}/doc/${encodeURL(word)}`);

        return snapshot;
    } catch (error) {
        return {};
    }
}

const getWordCount = async() => {
    let snapshot = await axios.get(`${config.url.wordService}/count`);

    return snapshot.data.wordCount;
}

const getWordId = async(word) => {
    let snapshot = axios.get(`${config.url.wordService}/doc/id/${decodeURL(word)}`);

    return snapshot;
}

const getWordsByUser = (vendorWalletsPaymailString) => {
    let snapshot = axios.get(`${config.url.wordService}/${vendorWalletsPaymailString}`)
        .then(resp => {
            return resp.data;//.map(doc => doc.word);
        });

        return snapshot;
}

const updateSearchTransaction = async(txId) => {
    try {
        let snapshot = await axios.get(
            `${config.url.wordService}/revenue/search/${txId}`
        );
        return snapshot.data;

    } catch (error) {
        console.log(error);
        return false;
    }
}

export { addDefinition, wordExists, addRevenueTransaction, getWordsLookAhead, getWordDoc, 
         getWordId, getWordsByUser, updateSearchTransaction,
         getWordCount, getWordsLookAheadFirstLetter};