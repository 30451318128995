import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import './VoteCarousel.css'

const items = [
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2FSugar.jfif?alt=media&token=7afbaf40-1b3f-46ba-b5d3-96d1d27a69d1'
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2FSucrose.jpg?alt=media&token=e95bfc63-ff13-4ce4-a5d7-11c4f041774a'
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2Fsugar-daddy.png?alt=media&token=bf3a8f8c-ebbd-4071-9bb7-886274c55d8f'
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/audio%2FBe%20the%20First.m4a?alt=media&token=611721e1-3ac6-45cb-ae09-0526976068c1'
  }
];

const VoteCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}



Carousel.propTypes = {
  // the current active slide of the carousel
  activeIndex: 2
  // a function which should advance the carousel to the next slide (via activeIndex)
  //next: PropTypes.func.isRequired,
  // a function which should advance the carousel to the previous slide (via activeIndex)
  //previous: PropTypes.func.isRequired,
  // controls if the left and right arrow keys should control the carousel
 // keyboard: PropTypes.bool,
  /* If set to "hover", pauses the cycling of the carousel on mouseenter and resumes the cycling of the carousel on
   * mouseleave. If set to false, hovering over the carousel won't pause it. (default: "hover")
   */
  //pause: PropTypes.oneOf(['hover', false]),

  // Autoplays the carousel after the user manually cycles the first item. If "carousel", autoplays the carousel on load.
  // This is how bootstrap defines it... I would prefer a bool named autoplay or something...
  //ride: 'none'
  // the interval at which the carousel automatically cycles (default: 5000)
  // If set to false, carousel will not Autoplay (i.e. will not automatically cycle).
  // interval: PropTypes.oneOfType([
  //   PropTypes.number,
  //   PropTypes.string,
  //   PropTypes.bool,
  // ]),
  //children: PropTypes.array,
  // called when the mouse enters the Carousel
  //mouseEnter: PropTypes.func,
  // called when the mouse exits the Carousel
 // mouseLeave: PropTypes.func,

  // controls whether the slide animation on the Carousel works or not
  //slide: PropTypes.bool,

  //cssModule: PropTypes.object,
  // controls whether the touch gestures on the Carousel works or not (default: true)
  //enableTouch: PropTypes.bool,
};

// CarouselItem.propTypes = {
//   ...Transition.propTypes,
//   tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
//   in: PropTypes.bool,
//   cssModule: PropTypes.object,
//   children: PropTypes.node,
//   slide: PropTypes.bool,
// };

// //CarouselControl Properties
// CarouselControl.propTypes = {
//   direction: PropTypes.oneOf(['prev', 'next']).isRequired,
//   onClickHandler: PropTypes.func.isRequired,
//   cssModule: PropTypes.object,
//   directionText: PropTypes.string
// };

// //CarouselIndicators Properties
// CarouselIndicators.propTypes = {
//   items: PropTypes.array.isRequired,
//   activeIndex: PropTypes.number.isRequired,
//   cssModule: PropTypes.object,
//   onClickHandler: PropTypes.func.isRequired
// };

// //CarouselCaption Properties
// CarouselCaption.propTypes = {
//   captionHeader: PropTypes.node,
//   captionText: PropTypes.node.isRequired,
//   cssModule: PropTypes.object
// };
export default VoteCarousel;
